import React, { useRef, useState } from 'react'
import { t, Trans } from '@lingui/macro'
import { useForm, Controller } from 'react-hook-form'
import {
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
  signInWithEmailAndPassword
} from 'firebase/auth'

import { auth } from 'libs/firebase'
import { ACCESS_TOKEN_KEY, TEMP_PASSWORD, SIGN_UP_EMAIL } from 'config/constantKey'

import { Button, Input } from 'components'

type PasswordFormProps = {
  email: string
  onSuccess: () => void
}

type FormData = {
  password: string
  confirmPassword: string
}

const PasswordForm = ({ email, onSuccess }: PasswordFormProps) => {
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const { control, formState, handleSubmit, watch } = useForm<FormData>({
    mode: 'onChange'
  })
  const { errors } = formState
  const password = useRef({})
  password.current = watch('password', '')

  const handleCreateAccount = (values: FormData) => {
    const user = auth.currentUser
    if (user) {
      setIsLoading(true)
      const credential = EmailAuthProvider.credential(email, TEMP_PASSWORD)
      reauthenticateWithCredential(user, credential)
        .then(() => {
          updatePassword(user, values.password)
            .then(() => {
              signInWithEmailAndPassword(auth, email, values.password)
                .then(async userCredential => {
                  const token = await userCredential.user.getIdToken()
                  if (token) {
                    localStorage.setItem(ACCESS_TOKEN_KEY, token)
                    localStorage.removeItem(SIGN_UP_EMAIL)
                    onSuccess()
                  }
                })
                .catch(error => {
                  const errorMessage = error.message
                  setError(errorMessage)
                })
                .finally(() => setIsLoading(false))
            })
            .catch(error => {
              const errorMessage = error.message
              setError(errorMessage)
            })
            .finally(() => setIsLoading(false))
        })
        .catch(error => {
          const errorCode = error.code
          const errorMessage = error.message
          if (errorCode === 'auth/wrong-password') {
            return setError(t`Email already exists`)
          }
          return setError(errorMessage)
        })
        .finally(() => setIsLoading(false))
    }
  }

  return (
    <div className='mt-3'>
      <form onSubmit={handleSubmit(handleCreateAccount)}>
        <span>
          <Trans>Your Email Address</Trans>
        </span>
        <p className='font-medium'>{email}</p>
        <div className='my-4'>
          <div className='mb-3'>
            <Controller
              render={({ field }) => (
                <Input placeholder={t`Enter Password`} type='password' visibilityToggle {...field} errors={errors} />
              )}
              control={control}
              name='password'
              rules={{
                required: t`Password is required`,
                minLength: {
                  value: 8,
                  message: t`Password must have at least 8 characters`
                }
              }}
            />
          </div>
          <div>
            <Controller
              render={({ field }) => (
                <Input placeholder={t`Confirm Password`} type='password' visibilityToggle {...field} errors={errors} />
              )}
              control={control}
              name='confirmPassword'
              rules={{
                required: t`Confirm Password is required`,
                validate: value => value === password.current || t`The passwords do not match`
              }}
            />
          </div>
          {error && <div className='text-red-500 mt-2'>{error}</div>}
        </div>
        <Button className='w-full' type='submit' disabled={isLoading}>
          <Trans>Create Account</Trans>
        </Button>
      </form>
    </div>
  )
}

export default PasswordForm
