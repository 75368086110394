import { useState, useEffect, useRef } from 'react'
import { useInView } from 'react-intersection-observer'

import { useMangaDrop } from 'queries/mangaDrop'
import { OrderType } from 'types/order'
import { Product } from 'types/product'

import { SHOW_LIMIT } from 'config/constantKey'

export const useInfiniteMangaDrop = (filter?: { _search?: string; tag?: string }) => {
  const [cursor, setCursor] = useState<number>(0)
  const [isEnd, setEnd] = useState(false)
  const [hideRefElement, setHideRefElement] = useState(false)
  const [showDatas, setShowDatas] = useState<Product[]>([])
  const { data: datas, isLoading } = useMangaDrop({
    ...filter,
    mangadrop: OrderType.FixedPrice,
    _limit: SHOW_LIMIT,
    _cursor: cursor
  })
  const nextCursor = useRef<number>()

  const { ref, inView } = useInView({
    threshold: 0.5
  })

  useEffect(() => {
    setTimeout(() => {
      setHideRefElement(isLoading) // when loading, we will hide the ref element with some delay to make smoothy
    }, 600)
  }, [isLoading])

  useEffect(() => {
    if (datas?.data) {
      const list = datas.data
      if (cursor) {
        setShowDatas(old => {
          return [...old, ...list]
        })
      } else {
        setShowDatas(list)
      }

      if (datas?.cursor) {
        nextCursor.current = datas.cursor
        setEnd(false)
      } else {
        setEnd(true)
      }
    }
  }, [cursor, datas])

  // use ref to prevent infinite render if maybe
  useEffect(() => {
    if (inView) {
      setCursor(nextCursor.current || 0)
    }
  }, [inView])

  return { showDatas, isLoading, isEnd, ref, hideRefElement, setCursor }
}
