import { useState, useEffect, useRef } from 'react'
import { useInView } from 'react-intersection-observer'

import { useProducts } from 'queries/product'
import { ProductFilterQuery, Product } from 'types/product'

import { SHOW_LIMIT } from 'config/constantKey'

export const useInfiniteProducts = (productFilter?: ProductFilterQuery) => {
  const [cursor, setCursor] = useState<number>(0)
  const [isEnd, setEnd] = useState(false)
  const [hideRefElement, setHideRefElement] = useState(false)
  const [showProducts, setShowProducts] = useState<Product[]>([])
  const { data: products, isLoading } = useProducts({
    ...productFilter,
    _limit: SHOW_LIMIT,
    _cursor: cursor
  })

  const nextCursor = useRef<number>()

  const { ref, inView } = useInView({
    threshold: 0.5
  })

  useEffect(() => {
    setTimeout(() => {
      setHideRefElement(isLoading) // when loading, we will hide the ref element with some delay to make smoothy
    }, 600)
  }, [isLoading])

  useEffect(() => {
    if (products?.data) {
      const list = products.data
      if (cursor) {
        setShowProducts(old => {
          return [...old, ...list]
        })
      } else {
        setShowProducts(list)
      }
    }

    if (products?.cursor) {
      nextCursor.current = products.cursor
      setEnd(false)
    } else {
      setEnd(true)
    }
  }, [cursor, products])

  // use ref to prevent infinite render if maybe
  useEffect(() => {
    if (inView) {
      setCursor(nextCursor.current || 0)
    }
  }, [inView])

  return { showProducts, isLoading, isEnd, ref, hideRefElement, setCursor }
}
