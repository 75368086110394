export function isMetamask(): boolean {
  return (window as any).ethereum && (window as any).ethereum.isMetaMask
}

export function getMetamaskChain() {
  if (isMetamask()) {
    return parseInt((window as any).ethereum.chainId)
  }
  return -1
}

export async function addChainBSC(chainId: number) {
  const provider = (window as any).ethereum
  await provider.request({
    method: 'wallet_addEthereumChain',
    params: [
      {
        chainId: `0x${chainId.toString(16)}`,
        chainName: 'Binance Smart Chain Mainnet',
        nativeCurrency: {
          name: 'BNB',
          symbol: 'bnb',
          decimals: 18
        },
        rpcUrls: ['https://bsc-dataseed.binance.org'],
        blockExplorerUrls: ['https://bscscan.com/']
      }
    ]
  })
}

export async function addChainERC(chainId: number) {
  const provider = (window as any).ethereum
  await provider.request({
    method: 'wallet_switchEthereumChain',
    params: [
      {
        chainId: `0x${chainId.toString(16)}`
      }
    ]
  })
}

export const ercChainIds = [1, 3, 4]
