import React from 'react'
import classnames from 'classnames'

import { useWalletPopup, useLogInPopup } from 'store/popup/hook'
import { ACCESS_TOKEN_KEY } from 'config/constantKey'
import { Trans } from '@lingui/macro'
import { Button } from 'components'

export interface ConnectWalletButtonProps {
  className?: string
  requireLogin?: boolean
}

const ConnectWalletButton: React.FC<ConnectWalletButtonProps> = ({ className, requireLogin = true }) => {
  const isLoggedIn = !!localStorage.getItem(ACCESS_TOKEN_KEY)

  const [walletPopup] = useWalletPopup()
  const [logInPopup] = useLogInPopup()

  const handleShowWallet = () => {
    if (!walletPopup?.open) {
      walletPopup?.show()
    }
  }

  const handleLogin = () => {
    if (!logInPopup?.open) {
      logInPopup?.show()
    }
  }

  if (requireLogin && !isLoggedIn)
    return (
      <Button className='w-full mb-2 px-8' onClick={handleLogin}>
        <Trans>Log In</Trans>
      </Button>
    )

  return (
    <div
      className={classnames('button full connect-wallet popup-wallet-connect-trigger', {
        [className as string]: className
      })}
      onClick={handleShowWallet}
    >
      Connect Wallet
    </div>
  )
}

export default ConnectWalletButton
