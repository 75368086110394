import React from 'react'
import classnames from 'classnames'

import { Info, Carousel } from '.'

export interface CollectionHeaderProps {
  account?: string | null
}

const Header: React.FC<CollectionHeaderProps> = ({ account }) => {
  return (
    <div className='full-width md:-mt-4 -mt-9 relative'>
      <div
        className={classnames('min-h-[620px]', {
          'bg-sky-lightest': false,
          'bg-[#222128]': true
        })}
      >
        <Carousel />
      </div>
      <div className='absolute top-1/2 md:top-8 md:left-8 p-3 w-full max-w-[100%] md:max-w-[300px]'>
        <Info account={account} />
      </div>
    </div>
  )
}

export default Header
