import React from 'react'
import { Trans } from '@lingui/macro'
import Slider from 'react-slick'

import Ambiguity1 from 'assets/images/home/ambiguity_1.jpg'
import Ambiguity2 from 'assets/images/home/ambiguity_2.jpg'

const Amibiguity = () => {
  const settings = {
    className: 'home__slick',
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  }

  return (
    <div className='flex flex-col md:flex-row py-10 md:pl-[7.5rem]'>
      <div className='mt-6 md:mt-0 text-left'>
        <h3>
          <Trans>Ambiguity</Trans>
        </h3>
        <p className='text-xl mt-4'>
          <Trans>
            What is Ambiguity? Manga Token has started collaborating with mangaka(s) to create new manga. To kickstart,
            we are collaborating with Chiharu Shinagawa to launch a short series called “Ambiguity”!
          </Trans>
        </p>
        <p className='text-xl mt-4'>
          <Trans>
            We are giving our $MANGA Hodlers the first dips into our Chapter 0 launch. In order to keep this manga going
            we need YOU, our $MANGA hodlers, to participate in our event.
          </Trans>
        </p>
      </div>
      <div className='w-full md:w-[24rem] mt-8 md:mt-0 md:ml-24'>
        <Slider {...settings}>
          <img src={Ambiguity1} alt='Ambiguity 1' />
          <img src={Ambiguity2} alt='Ambiguity 2' />
        </Slider>
      </div>
    </div>
  )
}

export default Amibiguity
