import { BscConnector } from '@binance-chain/bsc-connector'
import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'

import { WalletConfig, ConnectorNames } from 'components/WalletConnectModal/type'
import Metamask from 'components/WalletConnectModal/icons/Metamask'
import TrustWallet from 'components/WalletConnectModal/icons/TrustWallet'
import WalletConnect from 'components/WalletConnectModal/icons/WalletConnect'
import BinanceChain from 'components/WalletConnectModal/icons/BinanceChain'
import SafePalWallet from 'components/WalletConnectModal/icons/SafePalWallet'

export const connectors: WalletConfig[] = [
  {
    title: 'Metamask',
    icon: Metamask,
    connectorId: ConnectorNames.Injected
  },
  {
    title: 'TrustWallet',
    icon: TrustWallet,
    connectorId: ConnectorNames.Injected
  },
  {
    title: 'WalletConnect',
    icon: WalletConnect,
    connectorId: ConnectorNames.WalletConnect
  },
  {
    title: 'Binance Chain Wallet',
    icon: BinanceChain,
    connectorId: ConnectorNames.BSC
  },
  {
    title: 'SafePal Wallet',
    icon: SafePalWallet,
    connectorId: ConnectorNames.Injected
  }
]

const NETWORK_URL = process.env.NETWORK_URL

export const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 56, 97]
})

export const bscConnector = new BscConnector({ supportedChainIds: [56, 97] })

// mainnet only
export const walletconnect = new WalletConnectConnector({
  rpc: { 56: NETWORK_URL as string },
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
  pollingInterval: 12000
})

export const connectorsByName: { [connectorName in ConnectorNames]: any } = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.WalletConnect]: walletconnect,
  [ConnectorNames.BSC]: bscConnector
}

export { ConnectorNames }
