import React, { useEffect, useCallback, useState } from 'react'
import { useWeb3React } from '@web3-react/core'

import { useAuth, useSignMessage } from 'hooks'
import { linkWalletKey } from 'config/constantKey'
import { WalletConfig } from './type'
import { updateUserWallet } from 'apis/user'

export interface WalletCardProps {
  walletConfig: WalletConfig
  onSelected?: () => void
}

const WalletCard: React.FC<WalletCardProps> = ({ walletConfig, onSelected }) => {
  const { title, icon: Icon, connectorId } = walletConfig
  const { login } = useAuth()
  const { account } = useWeb3React()
  const [isConnected, setIsConnected] = useState(false)

  const requestSign = useSignMessage(linkWalletKey)

  const handleLogin = () => {
    onSelected && onSelected()
    login(connectorId)
    setIsConnected(true)
  }

  const handleUpdateUserWallet = useCallback(async () => {
    if (isConnected && account) {
      const [sig, msg] = await requestSign()
      updateUserWallet(account, {
        sig,
        msg
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, isConnected])

  useEffect(() => {
    handleUpdateUserWallet()
  }, [handleUpdateUserWallet])

  return (
    <div
      className='flex w-full justify-between items-center py-2 px-4 mb-3 rounded border-sky-light border-[1px] border-solid bg-sky-lightest hover:border-[#5F2AB5] cursor-pointer'
      onClick={handleLogin}
    >
      <p className='mr-4 font-medium'>{title}</p>
      <Icon />
    </div>
  )
}

export default WalletCard
