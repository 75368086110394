import { atom } from 'jotai'

/* because of the stupid popup from the template, we need store to save the popup state, zzz */

interface PopupProps {
  show: () => void
  hide: () => void
  open: boolean
  overlay: HTMLDivElement
  popupContainer: HTMLDivElement
  visible: boolean
}

export const walletPopupAtom = atom<PopupProps | undefined>(undefined)
export const sellItemPopupAtom = atom<PopupProps | undefined>(undefined)
export const bidItemPopupAtom = atom<PopupProps | undefined>(undefined)
export const collectionPopupAtom = atom<PopupProps | undefined>(undefined)
export const listOrderPopupAtom = atom<PopupProps | undefined>(undefined)
export const listAuctionPopupAtom = atom<PopupProps | undefined>(undefined)
export const kycRequiredPopupAtom = atom<PopupProps | undefined>(undefined)
export const kycPendingPopupAtom = atom<PopupProps | undefined>(undefined)
export const kycRejectedPopupAtom = atom<PopupProps | undefined>(undefined)
export const checkEligibilityPopupAtom = atom<PopupProps | undefined>(undefined)
export const commonPopupAtom = atom<PopupProps | undefined>(undefined)
export const commonPopupContent = atom<React.ReactNode | null>(null)
export const commonPopupClassContainer = atom('')
export const termAndPolicytPopupAtom = atom<PopupProps | undefined>(undefined)
export const logInPopupAtom = atom<PopupProps | undefined>(undefined)
export const signUpPopupAtom = atom<PopupProps | undefined>(undefined)
