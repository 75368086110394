import { useEffect } from 'react'
import { useQuery } from 'react-query'
import qs from 'query-string'
import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import { Contract } from '@ethersproject/contracts'

import { useContract, useSignMessage } from 'hooks'
import { useSignature } from 'store/user/hook'
import { validateSignature } from 'apis/signature'
import { getBidsOfAuction, getAuctionDetail, getBidsOfUser } from 'apis/order'
import { Product, ProductMetadata, ProductFilterQuery, InfiniteQuery } from 'types/product'
import useInvalidateUrl from './invalidate'

import ERC1155ABI from 'config/abis/erc1155Abi.json'
import ERC20ABI from 'config/abis/erc20Abi.json'
import { P2POrder, Bid } from 'types/order'
import { viewBidItemKey } from 'config/constantKey'

export function filterNonNull(obj: Object) {
  return Object.fromEntries(Object.entries(obj).filter(([k, v]) => v))
}

export const useProducts = (query?: ProductFilterQuery) => {
  const url = query ? `/v2/products?${qs.stringify(filterNonNull(query))}` : '/v2/products'

  return useQuery<{
    data: Product[] & ProductMetadata[]
    cursor: number
  }>(url)
}

export const useProductDetail = (id: string) => {
  return useQuery<Product>(`/v2/products/${id}`, {
    enabled: !!id
  })
}

export const myProductDetailQueryKey = (productId: string, account?: string | null, signature?: string) =>
  `@manga_token/queries/product-detail/${productId}/${account}/${signature}`

const getMyProductDetail =
  (message: string, productId: string, signature?: string, library?: Web3Provider, account?: string | null) =>
  async () => {
    if (account && library && signature) {
      const productDetail = await validateSignature({
        id: productId,
        msg: message,
        sig: signature
      })

      return productDetail
    }

    return null
  }

export const useMyProductDetail = (productId: string, message: string) => {
  const { account, library } = useWeb3React()

  const signMessage = useSignMessage(message)
  const [signature] = useSignature(message)
  const invalidate = useInvalidateUrl(myProductDetailQueryKey(productId, account, signature))

  useEffect(() => {
    const requestSign = async () => {
      if (!signature) {
        await signMessage()
      }
    }

    requestSign()
  }, [signature, signMessage])

  return {
    ...useQuery<ProductMetadata>(
      myProductDetailQueryKey(productId, account, signature),
      getMyProductDetail(message, productId, signature, library, account),
      {
        enabled: !!account && !!library
      }
    ),
    invalidate
  }
}

export const myProductQuantityQueryKey = (tokenAddress: string, tokenId: string, account?: string | null) =>
  `@manga_token/queries/my-product-quantity/${tokenAddress}/${tokenId}/${account}`

export const getMyProductQuantity =
  (tokenId: string, account?: string | null, contract?: Contract | null) => async () => {
    if (account && contract) {
      const balance = await contract?.balanceOf(account, tokenId)

      return +balance.toString()
    }

    return 0
  }

export const useMyProductQuantity = (tokenAddress: string, tokenId: string) => {
  const { account } = useWeb3React()
  const contract = useContract(tokenAddress, ERC1155ABI, true)
  const invalidate = useInvalidateUrl(myProductQuantityQueryKey(tokenAddress, tokenId, account))

  return {
    ...useQuery<number>(
      myProductQuantityQueryKey(tokenAddress, tokenId, account),
      getMyProductQuantity(tokenId, account, contract),
      {
        enabled: !!account && !!contract
      }
    ),
    invalidate
  }
}

export const totalSupplyQueryKey = (tokenAddress: string, tokenId: string, account?: string | null) =>
  `@manga_token/queries/total-supply/${tokenAddress}/${tokenId}/${account}`

export const getTotalSupply = (tokenId: string, contract?: Contract | null) => async () => {
  if (contract) {
    const supply = await contract?.totalSupply(tokenId)

    return +supply.toString()
  }

  return 0
}

export const useTotalSupply = (tokenAddress: string, tokenId: string) => {
  const { account } = useWeb3React()
  const contract = useContract(tokenAddress, ERC1155ABI, false)
  const invalidate = useInvalidateUrl(totalSupplyQueryKey(tokenAddress, tokenId, account))

  return {
    ...useQuery<number>(totalSupplyQueryKey(tokenAddress, tokenId, account), getTotalSupply(tokenId, contract), {
      enabled: !!contract
    }),
    invalidate
  }
}

export const balanceOfProductQueryKey = (tokenAddress: string, tokenId: string, account?: string | null) =>
  `@manga_token/queries/balance-of/${tokenAddress}/${tokenId}/${account}`

export const getBalanceOf = (account: string, tokenId: string, contract?: Contract | null) => async () => {
  if (contract && account) {
    const balanceOf = await contract.balanceOf(account, tokenId)

    return +balanceOf.toString()
  }

  return 0
}

export const useBalanceOfToken = (tokenAddress: string, account: string) => {
  const contract = useContract(tokenAddress, ERC20ABI, false)
  const invalidate = useInvalidateUrl(`@manga_token/balance/${account}`)

  return {
    ...useQuery<number>(
      `@manga_token/balance/${account}`,
      async () => {
        if (contract && account) {
          const balanceOf = await contract.balanceOf(account)

          return +balanceOf.toString()
        }

        return 0
      },
      {
        enabled: !!contract || !!account
      }
    ),
    invalidate
  }
}

export const useBalanceOf = (tokenAddress: string, tokenId: string, account: string) => {
  const contract = useContract(tokenAddress, ERC1155ABI, false)
  const invalidate = useInvalidateUrl(balanceOfProductQueryKey(tokenAddress, tokenId, account))

  return {
    ...useQuery<number>(
      balanceOfProductQueryKey(tokenAddress, tokenId, account),
      getBalanceOf(account, tokenId, contract),
      {
        enabled: !!contract || !!account
      }
    ),
    invalidate
  }
}

const getInfiniteUrl = (url: string, { limit, cursor }: InfiniteQuery = {}) => {
  if (limit) {
    url = url + `&limit=${limit}`
  }
  if (cursor) {
    url = url + `&cursor=${cursor}`
  }

  return url
}

export const useP2POrderOfProduct = ({
  productId,
  limit,
  cursor
}: {
  productId: string
} & InfiniteQuery) => {
  const invalidate = useInvalidateUrl(getInfiniteUrl(`/p2p-listOrdersOfProduct?productId=${productId}`))

  return {
    invalidate,
    ...useQuery<{
      cursor: number
      data: P2POrder[]
    }>(getInfiniteUrl(`/p2p-listOrdersOfProduct?productId=${productId}`, { limit, cursor }))
  }
}

export const fixedOrderOfProductQueryKey = ({
  productId,
  limit,
  cursor
}: {
  productId: string
} & InfiniteQuery) => getInfiniteUrl(`nft-listOrders?productId=${productId}`, { limit, cursor })
export const useFixedOrderOfProduct = ({
  productId,
  limit,
  cursor
}: {
  productId: string
} & InfiniteQuery) => {
  const invalidate = useInvalidateUrl(fixedOrderOfProductQueryKey({ productId, limit }))

  return {
    invalidate,
    ...useQuery<{
      cursor: number
      p2pCursor: number
      data: P2POrder[]
    }>(fixedOrderOfProductQueryKey({ productId, limit, cursor }), {
      keepPreviousData: true
    })
  }
}

export const auctionOfProductQueryKey = ({
  productId,
  limit,
  cursor
}: {
  productId: string
} & InfiniteQuery) => getInfiniteUrl(`/auction-listOrdersOfProduct?productId=${productId}`, { limit, cursor })
export const useAuctionOrderOfProduct = ({
  productId,
  limit,
  cursor
}: {
  productId: string
} & InfiniteQuery) => {
  const invalidate = useInvalidateUrl(auctionOfProductQueryKey({ productId, limit }))

  return {
    invalidate,
    ...useQuery<{
      cursor: number
      data: P2POrder[]
    }>(auctionOfProductQueryKey({ productId, limit, cursor }), {
      keepPreviousData: true
    })
  }
}

export const bidOfAuctionQueryKey = ({ orderId, limit, cursor }: { orderId: string } & InfiniteQuery) =>
  getInfiniteUrl(`/auction-listBidsOfOrder?orderId=${orderId}`, { limit, cursor })
export const useBidOfAuction = ({
  orderId,
  msg,
  sig,
  limit,
  cursor
}: {
  orderId: string
  msg: string
  sig: string
} & InfiniteQuery) => {
  const invalidate = useInvalidateUrl(bidOfAuctionQueryKey({ orderId, limit }))

  return {
    invalidate,
    ...useQuery<{
      cursor: number
      data: Bid[]
    }>(
      bidOfAuctionQueryKey({ orderId, limit, cursor }),
      async () => getBidsOfAuction({ id: orderId, msg, sig, limit, cursor }),
      {
        enabled: !!sig,
        keepPreviousData: true
      }
    )
  }
}

export const auctionDetailQueryKey = ({ orderId, msg, sig }: { orderId: string; msg: string; sig: string }) =>
  `/auction-getOrderDetails?orderId==${orderId}&msg=${msg}&sig=${sig}`
export const useAuctionDetail = ({ orderId, msg, sig }: { orderId: string; msg: string; sig: string }) => {
  const invalidate = useInvalidateUrl(auctionDetailQueryKey({ orderId, msg, sig }))

  return {
    invalidate,
    ...useQuery<P2POrder>(
      auctionDetailQueryKey({ orderId, msg, sig }),
      async () => getAuctionDetail({ id: orderId, msg, sig }),
      {
        enabled: !!sig
      }
    )
  }
}

export const bidOfUserQueryKey = (account: string) => `/auction-listBidOrdersOfUser/${account}`
export const useBidOfUser = () => {
  const { account } = useWeb3React()

  const signMessage = useSignMessage(viewBidItemKey)
  const [signature] = useSignature(viewBidItemKey)
  const invalidate = useInvalidateUrl(bidOfUserQueryKey(account || ''))

  useEffect(() => {
    const requestSign = async () => {
      if (!signature) {
        await signMessage()
      }
    }

    requestSign()
  }, [signature, signMessage])

  return {
    invalidate,
    ...useQuery<{
      cursor: number
      data: Bid[]
    }>(bidOfUserQueryKey(account || ''), async () => getBidsOfUser({ msg: viewBidItemKey, sig: signature || '' }), {
      enabled: !!account && !!signature
    })
  }
}
