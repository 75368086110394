import React from 'react'

export interface CoverImageProps {
  imageUrl: string
  className?: string
  isFit?: boolean
}

const CoverImage: React.FC<CoverImageProps> = ({ className, imageUrl, isFit }) => {
  return (
    <figure
      className={className || 'product-preview-image liquid'}
      style={{
        background: `url(${imageUrl}) center center / cover no-repeat`,
        backgroundSize: isFit ? 'contain' : 'cover'
      }}
    ></figure>
  )
}

export default CoverImage
