import React from 'react'
import { Trans } from '@lingui/macro'

import { connectors } from 'config/connectors'

import WalletCard from 'components/WalletConnectModal/WalletCard'
import logo from 'assets/images/logo-black.png'

type ConnectWalletProps = {
  onBack: () => void
  onSuccess: () => void
}

const ConnectWallet = ({ onBack, onSuccess }: ConnectWalletProps) => {
  return (
    <>
      <div>
        <svg className='fill-current w-5 h-5 mr-3 cursor-pointer' onClick={onBack}>
          <use xlinkHref='#svg-left-arrow'></use>
        </svg>
        <span className='text-base font-medium'>
          <Trans>Back</Trans>
        </span>
      </div>
      <img src={logo} className='w-[120px] sm:w-[166px] h-[1.125rem] sm:h-6 mt-9' alt='logo' />
      <p className='my-8 text-ink-lighter'>
        <Trans>Do you have any preexisting cryptowallet you’d like to connect to your account?</Trans>
      </p>
      {connectors.map(connector => (
        <WalletCard key={connector.title} walletConfig={connector} onSelected={onSuccess} />
      ))}
    </>
  )
}

export default ConnectWallet
