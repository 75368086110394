import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import Slider from 'react-slick'
import { useQueries } from 'react-query'

import { whatsNew } from 'config/home'
import { getProductDetail } from 'apis/product'
import { Product } from 'types/product'

import { Loader, ProductPreviewItem } from 'components'
import { ReactComponent as LeftArrow } from 'assets/images/left_arrow.svg'
import { ReactComponent as RightArrow } from 'assets/images/right_arrow.svg'

const NextArrow = ({ onClick }: { className?: string; onClick?: () => void }) => {
  return (
    <div
      className='absolute top-1/2 right-0 md:right-[-10px] flex w-[52px] h-[52px] bg-white rounded-full justify-center items-center cursor-pointer border border-sky-light hover:shadow-md z-10'
      onClick={onClick}
    >
      <RightArrow />
    </div>
  )
}

const PrevArrow = ({ onClick }: { className?: string; onClick?: () => void }) => {
  return (
    <div
      className='absolute top-1/2 left-0 md:left-[-10px] flex w-[52px] h-[52px] bg-white rounded-full justify-center items-center cursor-pointer border border-sky-light hover:shadow-md z-10'
      onClick={onClick}
    >
      <LeftArrow />
    </div>
  )
}

const WhatsNew = () => {
  const [products, setProducts] = useState<Product[]>([])

  const queryResults = useQueries(
    whatsNew.map(id => {
      return {
        queryKey: ['getWhatsNew', id],
        queryFn: () => getProductDetail(id),
        onSuccess: (data: any) => {
          setProducts([...products, data])
        }
      }
    })
  )

  const isLoading = queryResults.some(query => query.isLoading)

  const settings = {
    className: 'home__slick',
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  return (
    <div className='home__radial py-20'>
      <div className='text-center'>
        <h2 className='md:text-[2.75rem] text-[2rem] leading-tight font-medium'>
          <Trans>What's New</Trans>
        </h2>
        <p className='text-xl mt-4 text-ink-lighter'>
          <Trans>
            Trophee’s consistent efforts to cooperate with manga artists worldwide continuously provides a
            broad-spectrum of new Manga NFTs that fans from any part of the world can choose from. Trophee consistently
            updates the list of manga arts, for users to enjoy the latest rare collectibles from their favourite
            mangakas.
          </Trans>
        </p>
      </div>
      {isLoading ? (
        <div className='col-span-4'>
          <Loader />
        </div>
      ) : (
        <div className='md:-mx-4 mx-[0] pt-4'>
          <Slider {...settings}>
            {products.map(product => {
              return (
                <div key={product.id} className='md:p-[1rem] p-[0]'>
                  <ProductPreviewItem key={product.id} product={product} isFeature />
                </div>
              )
            })}
          </Slider>
        </div>
      )}
    </div>
  )
}

export default WhatsNew
