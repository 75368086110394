import React, { useState, useEffect, ChangeEvent } from 'react'
import { Trans } from '@lingui/macro'

import { ProductFilterQuery } from 'types/product'

import Select from '../Select'

export interface FilterPriceProps {
  price: ProductFilterQuery
  onSetPrice: (filter: ProductFilterQuery) => void
}

const options = [
  {
    value: 'manga',
    label: 'Manga Token ($MANGA)',
    image: '/images/tokens/0xa1daa25016d830xc2cb89bbb5bba6e21db1dfe13493dfd7dcbabd68.png'
  }
]

const OptionLabel = (option: any) => {
  return (
    <div className='flex items-center'>
      <img src={option.image} alt='logo' width='16px' height='16px' />
      <span className='ml-2'>{option.label}</span>
    </div>
  )
}

const FilterPrice: React.FC<FilterPriceProps> = ({ price, onSetPrice }) => {
  const [min, setMin] = useState<string>(price?.price_from ? '' + price.price_from : '')
  const [max, setMax] = useState<string>(price?.price_to ? '' + price.price_to : '')
  const [error, setError] = useState('')
  const handleSetPrice = () => {
    if (min && isNaN(+min)) {
      return setError('InValid Price')
    }
    if (max && isNaN(+max)) {
      return setError('InValid Price')
    }
    setError('')
    return onSetPrice({
      ...(min !== '' ? { price_from: +min } : {}),
      ...(max !== '' ? { price_to: +max } : {})
    })
  }

  useEffect(() => {
    setMin(price?.price_from ? '' + price.price_from : '')
    setMax(price?.price_to ? '' + price.price_to : '')
  }, [price])

  return (
    <div className='py-4 border-t border-r-0 border-l-0 border-b-0 border-sky-light border-solid'>
      <p className='mb-[1.125rem] font-medium'>Price</p>
      <Select options={options} defaultValue={options[0]} formatOptionLabel={OptionLabel} />

      <div className='flex justify-between items-center my-3'>
        <input
          value={min}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setMin(e.target.value)}
          className='p-3 w-28 border rounded'
          placeholder='Min'
        />
        to
        <input
          value={max}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setMax(e.target.value)}
          className='p-3 w-28 border rounded'
          placeholder='Max'
        />
      </div>
      {!!error && (
        <div className='text-xs text-red-600 my-3'>
          <Trans id={error} />
        </div>
      )}
      <button className='p-3 bg-new-primary font-medium text-white rounded' onClick={handleSetPrice}>
        Set price
      </button>
    </div>
  )
}

export default FilterPrice
