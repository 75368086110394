import React, { InputHTMLAttributes } from 'react'

type SwitchProps = InputHTMLAttributes<HTMLInputElement> & {}

const Switch = ({ ...props }: SwitchProps) => {
  return (
    <div className="relative switch inline-block leading-none">
      <input className="hidden" id="switch-component" type="checkbox" {...props} />
      <label
        className="inline-block cursor-pointer w-[40px] h-[20px] rounded-full transition duration-200 bg-ink-lightest"
        htmlFor="switch-component"
      ></label>
    </div>
  )
}

export default Switch
