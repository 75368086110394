import axios from 'axios'

export interface SignatureRequest {
  msg: string
  sig: string
}
interface ValidateSignature extends SignatureRequest {
  id: string
}

export const validateSignature = async (data: ValidateSignature) => {
  const res = await axios.post('/signature-validate', data)

  return res.data
}
