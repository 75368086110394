import React, { useMemo } from 'react'
import classnames from 'classnames'
import { t, Trans } from '@lingui/macro'

import { Status } from 'types/feature'

import { ReactComponent as Calendar } from 'assets/images/calendar.svg'
import { ReactComponent as Live } from 'assets/images/live.svg'
import { ReactComponent as Gift } from 'assets/images/gift.svg'

type RibbonProps = {
  status: Status
  size?: 'medium' | 'small'
  className?: string
}

const Ribbon = ({ status, size = 'medium', className }: RibbonProps) => {
  const title = useMemo(() => {
    let text = '',
      icon,
      className = ''
    switch (status) {
      case Status.ActiveNow:
        text = t`Active now`
        icon = <Live />
        className = 'active'
        break
      case Status.Upcoming:
        text = t`UpComing`
        icon = <Calendar />
        className = 'upcoming'
        break
      case Status.GiveAway:
        text = t`GiveAway`
        icon = <Gift />
        className = 'giveaway'
        break
      default:
        break
    }
    return { text, icon, className }
  }, [status])

  return (
    <div
      className={classnames('ribbon absolute text-white', className, title.className, {
        'top-6 py-2 px-4 leading-8': size === 'medium',
        'ribbon-small top-4 p-2 leading-6': size === 'small'
      })}
    >
      <div className='flex items-center font-medium'>
        {title.icon}
        <span className='inline-block ml-2 uppercase'>
          <Trans id={title.text} />
        </span>
      </div>
    </div>
  )
}

export default Ribbon
