import React, { useMemo } from 'react'

import { Classification } from 'types/product'
import { classificationNameMapping } from 'config/classification'

export interface ClassificationInfoProps {
  classification: Classification
}

const ClassificationInfo: React.FC<ClassificationInfoProps> = ({ classification }) => {
  const theme = useMemo(() => {
    if (classification === Classification.Common) {
      return 'text-semantic-common bg-semantic-commonBg'
    }

    if (classification === Classification.Rare) {
      return 'text-semantic-blue bg-semantic-blueBg'
    }

    if (classification === Classification.Epic) {
      return 'text-semantic-purple bg-semantic-purpleBg'
    }

    if (classification === Classification.Unique) {
      return 'text-semantic-yellow bg-semantic-yellowBg'
    }

    if (classification === Classification.Special) {
      return 'text-semantic-green bg-semantic-greenBg'
    }
  }, [classification])

  return (
    <span className={`py-1 px-2 rounded ${theme}`}>{classificationNameMapping[classification] || classification}</span>
  )
}

export default ClassificationInfo
