import React from 'react'

import { RadioButton } from 'components'

export interface FilterTypeProps {
  type: number
  onChange: (type: number) => void
}

const itemTypes = [
  {
    id: 0,
    label: 'All'
  },
  {
    id: 1,
    label: 'Auction'
  },
  {
    id: 2,
    label: 'Fixed Price'
  }
]

const FilterType: React.FC<FilterTypeProps> = ({ type, onChange }) => {
  return (
    <div className='py-4 border-b'>
      <p className='mb-[1.125rem] font-medium'>Item Type</p>
      <ul>
        {itemTypes.map(item => (
          <li key={item.id}>
            <RadioButton
              id={item.label}
              label={item.label}
              name='type'
              checked={type === item.id}
              onChange={() => onChange(item.id)}
            />
          </li>
        ))}
      </ul>
    </div>
  )
}

export default FilterType
