import React from 'react'
import { Trans } from '@lingui/macro'
import { GoogleAuthProvider, FacebookAuthProvider, TwitterAuthProvider, signInWithPopup } from 'firebase/auth'

import { useLogInPopup, useSignUpPopup } from 'store/popup/hook'

import { auth } from 'libs/firebase'
import { ACCESS_TOKEN_KEY } from 'config/constantKey'

import { Button } from 'components'

type SocialLoginProps = {
  setError: (err: string) => void
}

const SocialLogin = ({ setError }: SocialLoginProps) => {
  const providerGG = new GoogleAuthProvider()
  const providerFB = new FacebookAuthProvider()
  const providerTwitter = new TwitterAuthProvider()

  const [logInPopup] = useLogInPopup()
  const [signUpPopup] = useSignUpPopup()

  const closeModal = () => {
    logInPopup?.hide()
    signUpPopup?.hide()
  }

  const handleLoginWithGoogle = () => {
    signInWithPopup(auth, providerGG)
      .then(async result => {
        const token = await result.user.getIdToken()
        if (token) {
          localStorage.setItem(ACCESS_TOKEN_KEY, token)
          closeModal()
        }
      })
      .catch(error => {
        const errorMessage = error.message
        setError(errorMessage)
      })
  }

  const handleLoginWithFB = () => {
    signInWithPopup(auth, providerFB)
      .then(async result => {
        const token = await result.user.getIdToken()
        if (token) {
          localStorage.setItem(ACCESS_TOKEN_KEY, token)
          closeModal()
        }
      })
      .catch(error => {
        const errorMessage = error.message
        setError(errorMessage)
      })
  }

  const handleLoginWithTwitter = () => {
    signInWithPopup(auth, providerTwitter)
      .then(async result => {
        const token = await result.user.getIdToken()
        if (token) {
          localStorage.setItem(ACCESS_TOKEN_KEY, token)
          closeModal()
        }
      })
      .catch(error => {
        const errorMessage = error.message
        setError(errorMessage)
      })
  }

  return (
    <>
      <Button variant='secondary' className='mb-3 w-full' onClick={handleLoginWithGoogle}>
        <svg className='fill-current w-5 h-5 mr-3'>
          <use xlinkHref='#svg-google'></use>
        </svg>
        <Trans>Sign in with Google</Trans>
      </Button>
      <Button variant='secondary' className='mb-3 w-full' onClick={handleLoginWithFB}>
        <svg className='fill-current w-5 h-5 mr-3'>
          <use xlinkHref='#svg-facebook'></use>
        </svg>
        <Trans>Sign in with Facebook</Trans>
      </Button>
      <Button variant='secondary' className='w-full' onClick={handleLoginWithTwitter}>
        <svg className='fill-current w-5 h-5 mr-3'>
          <use xlinkHref='#svg-twitter'></use>
        </svg>
        <Trans>Sign in with Twitter</Trans>
      </Button>
    </>
  )
}

export default SocialLogin
