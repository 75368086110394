import React from 'react'

import { Button } from 'components'
import { ReactComponent as BridgeSuccessIcon } from 'assets/images/bridge_fail.svg'

export interface BridgeActionFailureProps {
  title: string
  actionTitle: string
  onAction: () => void
}

const BridgeActionFailure: React.FC<BridgeActionFailureProps> = ({ title, actionTitle, onAction }) => {
  return (
    <div className='flex flex-col py-10 items-center text-new-black'>
      <BridgeSuccessIcon />
      <h6 className='w-full md:max-w-[25rem] mt-5 mb-10 text-center font-medium'>{title}</h6>
      <Button className='w-full md:w-[25rem]' onClick={onAction}>
        {actionTitle}
      </Button>
    </div>
  )
}

export default BridgeActionFailure
