import React from 'react'
import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'

import MenuLink from './MenuLink'
import { ercChainIds } from 'utils/metamask'

export interface HomeMobileNavProps {
  onToggle: () => void
  open: boolean
}

const HomeMobileNav: React.FC<HomeMobileNavProps> = ({ open, onToggle }) => {
  const { chainId } = useWeb3React()

  return (
    <div
      className='w-full h-[calc(100vh-60px)] sm:h-[calc(100vh-80px)] py-[1.125rem] bg-white overflow-y-auto'
      style={{
        borderRight: '1px solid #E6E8EC'
      }}
    >
      <div className='flex justify-end pt-2 pb-4 px-6 border-b'>
        <img src='/images/marketplace/x_close.png' alt='close menu' className='w-4 h-4' onClick={onToggle} />
      </div>

      <nav className='p-6'>
        <ul className='flex flex-col menu-main font-medium'>
          <li className='menu-main-item mb-2'>
            <MenuLink name='MangaDrop' path='/manga-drop' className='' activeClassName='border-b-2' />
          </li>

          {/* <li className='menu-main-item mb-2'>
            <NavLink to='/features' activeClassName='border-b-2'>
              <Trans>Features</Trans>
            </NavLink>
          </li> */}

          <li className='menu-main-item mb-2'>
            <MenuLink name='Marketplace' path='/marketplace' className='' activeClassName='border-b-2' />
          </li>

          <li className='menu-main-item mb-2'>
            <a href='https://farm.trophee.xyz'>
              <Trans>Farms</Trans>
            </a>
          </li>

          <li className='menu-main-item mb-2'>
            <a href='https://bsc.trophee.xyz/bridge'>
              <Trans>Bridge</Trans>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default HomeMobileNav
