import { useQuery } from 'react-query'

import useInvalidateUrl from './invalidate'

import { Pagination } from 'types/product'
import { TradingHistory } from 'types/tradingHistory'
import { getInfiniteUrl } from 'utils/core'

export const useOrderTradingHistory = ({
  orderId,
  _limit,
  _cursor
}: {
  orderId: string
} & Pagination) => {
  const invalidate = useInvalidateUrl(getInfiniteUrl(`/v2/orders/${orderId}/history`))

  return {
    invalidate,
    ...useQuery<{
      cursor: number
      data: TradingHistory[]
    }>(getInfiniteUrl(`/v2/orders/${orderId}/history`, { _limit, _cursor }))
  }
}

export const useProductTradingHistory = ({
  productId,
  _limit,
  _cursor
}: {
  productId: string
} & Pagination) => {
  const invalidate = useInvalidateUrl(getInfiniteUrl(`/v2/products/${productId}/history`))

  return {
    invalidate,
    ...useQuery<{
      cursor: number
      data: TradingHistory[]
    }>(getInfiniteUrl(`/v2/products/${productId}/history`, { _limit, _cursor }))
  }
}
