import React from 'react'

import PlusExp from './PlusExp'
import ProgressBar from './ProgressBar'

export type BadgeType =
  | 'bronze'
  | 'silver'
  | 'gold'
  | 'plantinum'
  | 'caffeinated'
  | 'ultra-powered'
  | 'crazy-scientist'
  | 'night-creature'
  | 'liked'
  | 'super-loved'
  | 'quest-conqueror'
  | 'super-villain'
  | 'profile-age'
  | 'thunderstruck'
  | 'universe-explorer'
  | 'globe-trotter'
  | 'verified-collector'
  | 'rare-gempost'
  | 'the-marketeer'
  | 'bussiness-tycoon'
  | 'the-phantom'
  | 'super-planner'
  | 'the-collector'
  | 'bronze-cup'
  | 'silver-cup'
  | 'gold-cup'
  | 'platinum-cup'

export interface BadgeItemProps {
  id?: string
  plusExp: number
  badgeSmallImgUrl: string
  badgeBigImgUrl: string
  title: string
  description: string
  type: BadgeType
  targetPoint: number
  currentPoint: number
  linkText?: boolean
  linkUnits?: string
  emptyText?: string
  completeText?: string
  invertedProgress?: boolean
  progressText?: string
}

const BadgeItem: React.FC<BadgeItemProps> = ({
  type,
  plusExp,
  badgeSmallImgUrl,
  badgeBigImgUrl,
  title,
  description,
  ...progressBarProps
}) => {
  return (
    <div className='badge-item-stat'>
      <PlusExp exp={plusExp} />
      <img className='badge-item-stat-image-preview' src={badgeSmallImgUrl} alt='badge-preview' />
      <img className='badge-item-stat-image' src={badgeBigImgUrl} alt='badge-preview' />
      <p className='badge-item-stat-title'>{title}</p>
      <p className='badge-item-stat-text'>{description}</p>

      <ProgressBar
        id={type}
        currentPoint={progressBarProps.currentPoint}
        targetPoint={progressBarProps.targetPoint}
        position='center'
        linkText={!!progressBarProps.linkText}
        linkUnits={progressBarProps.linkUnits || '/'}
        preText={progressBarProps.progressText}
        invertedProgress={progressBarProps.invertedProgress}
        emptyText={progressBarProps.emptyText}
        completeText={progressBarProps.completeText}
      />
    </div>
  )
}

export default BadgeItem
