import React from 'react'

import { CircleLoader } from 'components'

export interface BridgeActionLoadingProps {
  title: string
  description: string
  tip?: string
}

const BridgeActionLoading: React.FC<BridgeActionLoadingProps> = ({ title, description, tip }) => {
  return (
    <div className='flex flex-col py-10 items-center text-new-black'>
      <h4 className='font-medium'>{title}</h4>
      <div className='py-4 text-center'>
        <CircleLoader />
      </div>
      <h6 className='font-medium'>{description}</h6>
      <p className='mt-1'>{tip}</p>
    </div>
  )
}

export default BridgeActionLoading
