import React, { useState, useRef, useEffect } from 'react'
import { Trans } from '@lingui/macro'
import classnames from 'classnames'

import feature_6 from 'assets/images/feature/feature_6.jpg'
import { ReactComponent as DownArrow } from 'assets/images/down_arrow.svg'
import { ReactComponent as UpperArrow } from 'assets/images/upper_arrow.svg'

const Intro = () => {
  const [showMore, setShowMore] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState<number | undefined>(showMore ? undefined : 0)

  useEffect(() => {
    if (!height || !showMore || !ref.current) return undefined
    // @ts-ignore
    const resizeObserver = new ResizeObserver(el => {
      setHeight(el[0].contentRect.height + 48)
    })
    resizeObserver.observe(ref.current)
    return () => {
      resizeObserver.disconnect()
    }
  }, [height, showMore])

  useEffect(() => {
    if (showMore) setHeight((ref.current?.getBoundingClientRect().height || 0) + 48)
    else setHeight(0)
  }, [showMore])

  return (
    <div className='bg-[#f7f8fa] full-width text-center'>
      <div className='max-w-3/4 my-0 mx-auto pb-8'>
        <img
          src={feature_6}
          alt='geisha'
          className='relative w-[120px] h-[120px] mt-[-60px] object-cover rounded-full border-[#f7f8fa] border-8 border-solid z-10'
        />
        <div className='mt-6 mb-8 text-xl'>
          <Trans>
            by <span className='font-medium'>Kiriko Yumeji</span>
          </Trans>
        </div>
        <h3 className='font-medium text-[2rem] leading-10'>
          <Trans>About Kiriko Yumeji</Trans>
        </h3>
        <div className={classnames('relative', { 'feature__intro-first': !showMore })}>
          <Trans>
            Known for works such as Chevalier ~ Le Chevalier D’Eon ~ (Kodansha), Sangokushi Taisen (Sega) and Sengoku
            Taisen (Sega), Yumeji is a well known Mangaka and illustrator. She’s participating in many exhibitions to
            showcase her work around the world. Including well known museums such as the Tokyo Metropolitan Art Museum!
          </Trans>
        </div>
        <div className='overflow-hidden transition-all duration-200 ease-in-out' style={{ height }}>
          <div ref={ref}>
            <p className='mt-4'>
              <Trans>
                Her sword has such opulent details that ushers the viewer to a much more refined world. This elegant
                piece is a perfect addition for NFT collectors with distinguished taste. The One and Only Power is 2500
                USDT.
              </Trans>
            </p>
            <p className='mt-4'>
              <Trans>
                In 2018, she’s Drawn at the Master class for the “2D vs KATANA” exhibition at the Iwakuni Art Museum,
                and this one of a kind NFT showcases Yumeji’s beautiful artistic style in an astounding manner. A woman
                in a layered elegant dress wielding a decorative yet bold sword is what it is portarying.
              </Trans>
            </p>
            <p className='mt-4'>
              <Trans>
                Like most artists now-a-days, Yumeji has an online following! Her work can even be spotted in Youtube
                channels such as “We are the protagonists!” (主役は我々だ!) and “Science solves everything! By Kurare
                and Pharmacological Murder Room” (科学はすべてを解決する! [くられ with 薬理凶室]). So next time you find
                some of Yumeji’s art, be sure to think of her NFTs too! And if you’re a collector with a lot of taste
                for elegance and grace, The One and Only Power is perfect for you.
              </Trans>
            </p>
          </div>
        </div>
        <div
          className={classnames('text-base', {
            'pt-8': !showMore,
            'pt-0': showMore
          })}
          onClick={() => setShowMore(!showMore)}
        >
          <span>{!showMore ? <Trans>Show more</Trans> : <Trans>Show less</Trans>}</span>
          {!showMore ? <DownArrow /> : <UpperArrow />}
        </div>
      </div>
    </div>
  )
}

export default Intro
