import { useMemo } from 'react'
import { useLingui } from '@lingui/react'

import { SupportedLanguage } from 'components/SwitchLanguage'

export const useLocale = () => {
  const { i18n } = useLingui()

  return useMemo<SupportedLanguage>(() => i18n.locale as SupportedLanguage, [i18n])
}
