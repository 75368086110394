import React, { Suspense } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import { PageLoading } from 'components'

const Home = React.lazy(() => import(/* webpackChunkName: "home-chunk" */ './container/Home'))
const Badges = React.lazy(() => import(/* webpackChunkName: "badges-chunk" */ './container/Badges'))
const Leaderboard = React.lazy(() => import(/* webpackChunkName: "leaderboard-chunk" */ './container/Leaderboard'))

const ProductDetail = React.lazy(
  () => import(/* webpackChunkName: "product-detail-chunk" */ './container/Detail/ProductDetail')
)
const OrderDetail = React.lazy(
  () => import(/* webpackChunkName: "order-detail-chunk" */ './container/Detail/OrderDetail')
)
const AuctionDetail = React.lazy(
  () => import(/* webpackChunkName: "auction-detail-chunk" */ './container/Detail/AuctionDetail')
)

const Feature = React.lazy(() => import(/* webpackChunkName: "feature-chunk" */ './container/Feature'))

const Marketplace = React.lazy(() => import('./container/Marketplace'))
const Collection = React.lazy(() => import('./container/Collection'))
const MangaDrop = React.lazy(() => import('./container/MangaDrop'))

const AboutUs = React.lazy(() => import(/* webpackChunkName: "about-us-chunk" */ './container/AboutUs'))
const FAQ = React.lazy(() => import(/* webpackChunkName: "faq-chunk" */ './container/FAQ'))
const TermsOfUse = React.lazy(() => import('./container/TermsOfUse'))
const PrivacyPolicy = React.lazy(() => import('./container/PrivacyPolicy'))
const Bridge = React.lazy(() => import('./container/Bridge'))
const Mint = React.lazy(() => import('./container/Mint'))
const Receive = React.lazy(() => import('./container/Receive'))

const NotFound = React.lazy(() => import(/* webpackChunkName: "not-found-chunk" */ './container/NotFound'))

const Routes = () => {
  return (
    <Router>
      <Suspense fallback={<PageLoading />}>
        <Switch>
          {/* main */}
          <Route exact path='/'>
            <Home />
          </Route>
          <Route path='/badges'>
            <Badges />
          </Route>
          <Route path='/leaderboard'>
            <Leaderboard />
          </Route>
          <Route path='/marketplace-products/:id'>
            <ProductDetail />
          </Route>
          <Route path='/auctions/:id'>
            <AuctionDetail />
          </Route>
          <Route path='/orders/:id'>
            <OrderDetail />
          </Route>
          <Route path='/features'>
            <Feature />
          </Route>
          <Route path='/marketplace'>
            <Marketplace />
          </Route>
          <Route path='/manga-drop'>
            <MangaDrop />
          </Route>
          <Route path='/collections/:address'>
            <Collection />
          </Route>

          {/* settings */}
          {/* <Route path='/profile-info'>
            <ProfileInfo />
          </Route> */}

          {/* others */}
          <Route path='/about-us'>
            <AboutUs />
          </Route>
          <Route path='/faq'>
            <FAQ />
          </Route>
          <Route path='/terms-of-use'>
            <TermsOfUse />
          </Route>
          <Route path='/privacy-policy'>
            <PrivacyPolicy />
          </Route>
          <Route path='/bridge/receive'>
            <Receive />
          </Route>
          <Route path='/bridge'>
            <Bridge />
          </Route>
          <Route path='/mint'>
            <Mint />
          </Route>

          <NotFound />
        </Switch>
      </Suspense>
    </Router>
  )
}

export default Routes
