import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { t, Trans } from '@lingui/macro'
import { useLocation, useHistory } from 'react-router-dom'
import qs from 'query-string'
import { applyActionCode, signInWithEmailAndPassword } from 'firebase/auth'

import { useLogInPopup, useSignUpPopup } from 'store/popup/hook'
import { useModal } from 'hooks'
import { auth } from 'libs/firebase'
import { toastError } from 'utils/toast'
import { SIGN_UP_EMAIL, TEMP_PASSWORD } from 'config/constantKey'

import { Button } from 'components'
import SocialLogin from 'components/SocialLogin'
import { ToastError } from 'components/ToastMessage'
import SignUp from './SignUp'
import ConnectWallet from './ConnectWallet'

import logo from 'assets/images/logo-black.png'

enum Step {
  Intro,
  SignUp,
  Connect
}

const SignUpModal = () => {
  const [signUpPopup, setSignUpPopup] = useSignUpPopup()
  const [logInPopup] = useLogInPopup()

  const history = useHistory()
  const location = useLocation()
  const queryFilter = useMemo(() => qs.parse(location.search), [location.search])

  const [step, setStep] = useState(Step.Intro)
  const [error, setError] = useState('')
  const [isVerified, setIsVerified] = useState(false)

  useModal({
    container: '.popup-signup',
    trigger: '.popup-close-signup',
    setAtom: setSignUpPopup
  })

  const handleSignIn = () => {
    logInPopup?.show()
    signUpPopup?.hide()
  }

  const showPopUp = useCallback(() => {
    setTimeout(() => {
      signUpPopup?.show()
    }, 500)
  }, [signUpPopup])

  const handleSignUpSuccess = useCallback(() => {
    signUpPopup?.hide()
    setStep(Step.Connect)
    showPopUp()
  }, [showPopUp, signUpPopup])

  useEffect(() => {
    if (queryFilter.mode && queryFilter.oobCode && queryFilter.apiKey) {
      const actionCode = queryFilter.oobCode as string
      applyActionCode(auth, actionCode)
        .then(() => {
          auth.signOut()
          const email = localStorage.getItem(SIGN_UP_EMAIL) || ''
          signInWithEmailAndPassword(auth, email, TEMP_PASSWORD).then(() => {
            setIsVerified(true)
          })
        })
        .catch(error => {
          setIsVerified(false)
          toastError(<ToastError header={t`Verify Email!`} message={error.message} />)
        })
        .finally(() => {
          history.replace({
            pathname: location.pathname,
            search: ''
          })
        })
    }
  }, [history, location.pathname, queryFilter])

  useEffect(() => {
    if (isVerified) {
      setStep(Step.SignUp)
      showPopUp()
    }
  }, [isVerified, showPopUp])

  const render = () => {
    if (Step.SignUp === step) {
      return (
        <div className='popup-box-body flex flex-col p-8'>
          <SignUp
            onBack={() => setStep(Step.Intro)}
            onSuccess={handleSignUpSuccess}
            onNext={() => setIsVerified(false)}
          />
        </div>
      )
    }
    if (Step.Connect === step) {
      return (
        <div className='popup-box-body flex flex-col p-8'>
          <ConnectWallet onBack={() => setStep(Step.Intro)} onSuccess={() => signUpPopup?.hide()} />
        </div>
      )
    }

    if (Step.Intro === step) {
      return (
        <>
          <div className='popup-box-body flex flex-col p-8'>
            <img src={logo} className='w-[120px] sm:w-[166px] h-[1.125rem] sm:h-6' alt='logo' />
            <h4 className='mt-9'>
              <Trans>New to the Trophee Community?</Trans>
            </h4>
            <p className='mt-2 text-ink-lighter'>
              <Trans>Please choose your preferred sign up method below.</Trans>
            </p>
            <div className='my-3'>
              <Button className='w-full' onClick={() => setStep(Step.SignUp)}>
                <Trans>Trophee Wallet</Trans>
              </Button>
            </div>
            <div className='relative'>
              <div className='absolute text-ink-lighter px-[0.25rem] bg-white left-[47%] top-[-9px]'>
                <Trans>or</Trans>
              </div>
              <div className='border border-sky-light'></div>
            </div>
            <div className='my-3'>
              <SocialLogin setError={setError} />
              {!!error && <div className='text-red-500 mt-2'>{error}</div>}
            </div>
            <div className='border-[0px] border-t-[1px] border-sky-light border-solid text-center pt-4 mt-2 text-base'>
              <Trans>
                Already Have an Account?{' '}
                <span onClick={handleSignIn} className='text-semantic-blue underline cursor-pointer'>
                  Sign in Instead
                </span>
              </Trans>
            </div>
          </div>
        </>
      )
    }
  }

  return (
    <div className='popup-box mid popup-signup'>
      <div className='popup-close-signup'>
        <svg className='popup-close-button-icon icon-cross'>
          <use xlinkHref='#svg-cross'></use>
        </svg>
      </div>
      {render()}
    </div>
  )
}

export default SignUpModal
