import { useState } from 'react'
import { t } from '@lingui/macro'

import { Tab } from 'components'
import { Collectibles, OnSale } from '.'

const tabs = [
  {
    title: t`Collectibles`,
    id: 'collectibles'
  },
  {
    title: t`On Sale`,
    id: 'on-sale'
  }
  // {
  //   title: t`Favorites`,
  //   id: 'favorites'
  // }
]

export interface ListMyCollectionProps {
  account?: string | null
}

const ListMyCollection: React.FC<ListMyCollectionProps> = ({ account }) => {
  const [tab, setTab] = useState('collectibles')

  return (
    <div className='pt-[350px] md:pt-24 pb-20'>
      <div className='flex border-b-[2px] border-t-0 border-r-0 border-l-0 border-sky-light border-solid pb-2'>
        {tabs.map(item => (
          <Tab key={item.id} title={item.title} active={tab === item.id} onChange={() => setTab(item.id)} />
        ))}
      </div>
      <div className='py-4'>
        {tab === 'collectibles' && <Collectibles account={account} />}
        {tab === 'on-sale' && <OnSale account={account} />}
        {/* {tab === 'favorites' && <Favorites />} */}
      </div>
    </div>
  )
}

export default ListMyCollection
