import React from 'react'
import { Trans } from '@lingui/macro'
import classnames from 'classnames'

import { ReactComponent as Blink1 } from 'assets/images/blink_3.svg'
import { ReactComponent as Blink2 } from 'assets/images/blink_4.svg'

import person1 from 'assets/images/home/person_1.jpg'
import person2 from 'assets/images/home/person_2.jpg'
import person3 from 'assets/images/home/person_3.jpg'
import person4 from 'assets/images/home/person_4.jpg'
import person5 from 'assets/images/home/person_5.jpg'
import person6 from 'assets/images/home/person_6.jpg'

type PersonSize = 'big' | 'medium' | 'small'
type Person = {
  position: string
  url: string
  size: PersonSize
}

const Avatar = ({ position, url, size = 'medium' }: Person) => {
  return (
    <div className={classnames(position, 'absolute md:block hidden')}>
      <img
        src={url}
        alt='person'
        className={classnames('rounded-full', {
          'w-[100px] h-[100px]': size === 'big',
          'w-[88px] h-[88px]': size === 'medium',
          'w-[59px] h-[59px]': size === 'small'
        })}
      />
    </div>
  )
}

const people: Person[] = [
  {
    url: person1,
    position: 'top-[5%] left-[10%]',
    size: 'big'
  },
  {
    url: person2,
    position: 'top-0 right-[20%]',
    size: 'small'
  },
  {
    url: person3,
    position: 'right-0',
    size: 'medium'
  },
  {
    url: person4,
    position: 'bottom-0 right-[10%]',
    size: 'small'
  },
  {
    url: person5,
    position: 'bottom-[-5%] left-[10%]',
    size: 'big'
  },
  {
    url: person6,
    position: 'top-1/2 left-[-7%]',
    size: 'small'
  }
]

const OurCommunity = () => {
  return (
    <div className='home__radial py-20 relative'>
      <div className='absolute top-0 left-[30%]'>
        <Blink1 />
      </div>
      <div className='absolute top-40 right-[20%]'>
        <Blink2 />
      </div>
      {people.map((person, index) => (
        <Avatar key={index} url={person.url} position={person.position} size={person.size} />
      ))}
      <div className='text-center'>
        <h2 className='md:text-[2.75rem] text-[2rem] leading-tight font-medium'>
          <Trans>By the community</Trans>
        </h2>
        <p className='text-xl mt-4 text-ink-lighter'>
          <Trans>
            What the world needs now is connection. Linking Artists (Mangaka) and Art Lovers (fans) through NFTs.
          </Trans>
        </p>
      </div>
      <div className='flex flex-wrap py-14'>
        <div className='text-center md:w-1/2 w-full bg-white border border-sky-light rounded mb-[1rem] md:mb-0 md:mr-2 p-4'>
          <p className='text-5xl py-4'>🔥</p>
          <h5 className='font-medium'>
            <Trans>Don’t get left behind</Trans>
          </h5>
          <p className='text-ink-lighter mt-2'>
            <Trans>
              Get the latest news and developments on our website! Subscribe to our{' '}
              <a
                className='text-new-primary font-medium'
                href='https://www.youtube.com/channel/UCfiCki_exYQ0OrP0VzJytcg'
                target='_blank'
                rel='noreferrer'
              >
                Youtube channel
              </a>
            </Trans>
          </p>
          <p className='text-ink-lighter mt-2'>
            <Trans>
              Manga Token is a reward, payment and staking token for authentic Manga NFTs made by original manga artists
              on Trophee.xyz
            </Trans>
          </p>
        </div>
        <div className='text-center flex-1 bg-white border border-sky-light rounded md:ml-2 p-4'>
          <p className='text-5xl py-4'>🎊</p>
          <h5 className='font-medium'>
            <Trans>Connect with us and get updated</Trans>
          </h5>
          <div className='flex justify-center mt-4'>
            <a href='https://twitter.com/manga_token' target='_blank' className='px-3' rel='noreferrer'>
              <svg className='fill-current text-ink-lighter w-[20px] h-[20px]'>
                <use xlinkHref='#svg-twitter'></use>
              </svg>
            </a>
            <a href='https://www.instagram.com/manga_token' target='_blank' className='px-3' rel='noreferrer'>
              <svg className='fill-current text-ink-lighter w-[20px] h-[20px]'>
                <use xlinkHref='#svg-instagram'></use>
              </svg>
            </a>
            <a href='https://medium.com/manga-token' target='_blank' className='px-3' rel='noreferrer'>
              <svg className='fill-current text-ink-lighter w-[20px] h-[20px] leading-none align-bottom'>
                <use xlinkHref='#svg-medium'></use>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurCommunity
