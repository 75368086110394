import {
  DropdownOption,
  HexagonOption,
  ProgressOption,
  TooltipOption,
  AccordionOption,
  PopupOption,
  TabOption,
  SliderOption
} from './pluginTypes'

declare global {
  interface Window {
    // you'll need to explicitly specify the
    // type of arguments & function return type
    XM_Dropdown: any
    XM_Hexagon: any
    XM_ProgressBar: any
    XM_Tooltip: any
    XM_Accordion: any
    XM_Popup: any
    XM_Tab: any
    tns: any
  }
}

const existsInDOM = (selector: string) => {
  return document.querySelectorAll(selector).length
}

export const createDropdown = (options: DropdownOption) => {
  if (existsInDOM(options.container)) {
    return window.XM_Dropdown(options)
  }
}

export const createHexagon = (options: HexagonOption) => {
  if (existsInDOM(options.container)) {
    return window.XM_Hexagon(options)
  }
}

export const createProgressBar = (options: ProgressOption) => {
  if (existsInDOM(options.container)) {
    return window.XM_ProgressBar(options)
  }
}

export const createTooltip = (options: TooltipOption) => {
  if (existsInDOM(options.container)) {
    return window.XM_Tooltip(options)
  }
}

export const createAccordion = (options: AccordionOption) => {
  if (existsInDOM(options.triggerSelector) && existsInDOM(options.contentSelector)) {
    return window.XM_Accordion(options)
  }
}

export const createPopup = (options: PopupOption, closeCallback?: () => void) => {
  if (existsInDOM(options.container) && existsInDOM(options.trigger)) {
    return window.XM_Popup(options, (closeCallback = () => {}))
  }
}

export const createTab = (options: TabOption) => {
  if (existsInDOM(options.triggers) && existsInDOM(options.elements)) {
    return window.XM_Tab(options)
  }
}

export const createSlider = (options: SliderOption) => {
  if (existsInDOM(options.container)) {
    return window.tns(options)
  }
}
