import { useAtom } from 'jotai'

import {
  walletPopupAtom,
  sellItemPopupAtom,
  bidItemPopupAtom,
  collectionPopupAtom,
  listOrderPopupAtom,
  listAuctionPopupAtom,
  kycRequiredPopupAtom,
  kycPendingPopupAtom,
  kycRejectedPopupAtom,
  checkEligibilityPopupAtom,
  commonPopupAtom,
  commonPopupContent,
  commonPopupClassContainer,
  termAndPolicytPopupAtom,
  logInPopupAtom,
  signUpPopupAtom
} from './index'

export const useWalletPopup = () => {
  return useAtom(walletPopupAtom)
}

export const useSellItemPopup = () => {
  return useAtom(sellItemPopupAtom)
}

export const useBidItemPopup = () => {
  return useAtom(bidItemPopupAtom)
}

export const useCollectionPopup = () => {
  return useAtom(collectionPopupAtom)
}

export const useListOrderPopup = () => {
  return useAtom(listOrderPopupAtom)
}

export const useListAuctionPopup = () => {
  return useAtom(listAuctionPopupAtom)
}

export const useKYCRequestPopup = () => {
  return useAtom(kycRequiredPopupAtom)
}

export const useKYCPendingPopup = () => {
  return useAtom(kycPendingPopupAtom)
}

export const useKYCRejectedPopup = () => {
  return useAtom(kycRejectedPopupAtom)
}

export const useKYCPopup = () => {
  const [request] = useKYCRequestPopup()
  const [pending] = useKYCPendingPopup()
  const [reject] = useKYCRejectedPopup()

  return {
    Request: request,
    Pending: pending,
    Reject: reject
  }
}

export const useCheckEgilibilityPopup = () => {
  return useAtom(checkEligibilityPopupAtom)
}

export const useCommonPopup = () => {
  const [popup, setPopup] = useAtom(commonPopupAtom)
  const [content, setContent] = useAtom(commonPopupContent)
  const [containerClass, setContainerClass] = useAtom(commonPopupClassContainer)

  return {
    popup,
    setPopup,
    content,
    setContent,
    containerClass,
    setContainerClass
  }
}

export const useTermAndPolicyPopup = () => {
  return useAtom(termAndPolicytPopupAtom)
}

export const useLogInPopup = () => {
  return useAtom(logInPopupAtom)
}

export const useSignUpPopup = () => {
  return useAtom(signUpPopupAtom)
}
