import React, { useMemo } from 'react'
import classnames from 'classnames'

import Loader from './Loader'

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean
  variant?: 'primary' | 'secondary'
}

const Button: React.FC<ButtonProps> = ({ isLoading, children, className, variant = 'primary', ...props }) => {
  const variantClassname = useMemo(() => {
    if (variant === 'primary') {
      return 'bg-new-primary text-white'
    }

    if (variant === 'secondary') {
      return 'bg-white text-new-primary border border-semantic-commonBg'
    }
  }, [variant])

  return isLoading ? (
    <div className='w-full -mt-5'>
      <Loader />
    </div>
  ) : (
    <button
      className={classnames(
        variantClassname,
        'p-[0.875rem] text-base font-medium rounded focus:outline-none disabled:cursor-not-allowed disabled:opacity-50',
        {
          ...(className && {
            [className]: className
          })
        }
      )}
      disabled={isLoading || props.disabled}
      {...props}
    >
      {children}
    </button>
  )
}

export default Button
