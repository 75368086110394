import classnames from 'classnames'
import React, { ReactElement, InputHTMLAttributes, useState } from 'react'
import { FieldErrors } from 'react-hook-form'

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  leftElement?: ReactElement
  rightElement?: ReactElement
  visibilityToggle?: boolean
  errors?: FieldErrors
  name?: string
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ leftElement, rightElement, visibilityToggle = false, errors, name, ...props }: InputProps, ref) => {
    const [show, setShow] = useState(false)

    const error = name && errors?.[name]?.message
    const type = show && visibilityToggle && props.type === 'password' ? 'text' : props.type

    return (
      <div>
        <div
          className={classnames(
            'flex relative items-center h-[54px] px-[16px] border border-sky-light rounded bg-white focus-within:border-new-primary',
            {
              'border-red-500': !!error
            }
          )}
        >
          {!!leftElement && <span className='leading-none'>{leftElement}</span>}
          <input className='flex-1 sm:w-96 pl-2 pr-4' ref={ref} {...props} type={type} />
          {!!rightElement && <span className='leading-none'>{rightElement}</span>}
          {visibilityToggle && (
            <svg className='fill-current w-5 h-5 absolute top-[17px] right-2' onClick={() => setShow(!show)}>
              {show ? <use xlinkHref='#svg-eye'></use> : <use xlinkHref='#svg-eye-close'></use>}
            </svg>
          )}
        </div>
        {!!error && <p className='text-red-500 mt-2'>{error}</p>}
      </div>
    )
  }
)

export default Input
