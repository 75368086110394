import React from 'react'
import { Trans } from '@lingui/macro'
import { Link, useHistory } from 'react-router-dom'

import { Button } from 'components'

import { ReactComponent as Symbol } from 'assets/images/symbol.svg'
import { ReactComponent as Blink1 } from 'assets/images/blink_1.svg'
import { ReactComponent as Blink2 } from 'assets/images/blink_2.svg'
import image from 'assets/images/home/home_6.jpeg'

const Intro = () => {
  const history = useHistory()

  return (
    <div className='full-width bg-gradient-to-b from-[#F6EFFF] to-[rgba(246, 239, 255, 0)] md:-mt-4 -mt-9 relative'>
      <div className='absolute top-0 right-0 md:block hidden'>
        <Symbol />
      </div>
      <div className='absolute md:left-[40%] right-0 md:top-12'>
        <Blink1 />
      </div>
      <div className='absolute left-[20%] -bottom-8 md:bottom-0 md:right-[20%] md:left-auto'>
        <Blink2 />
      </div>
      <div className='content-grid md:py-24 py-10 flex flex-wrap'>
        <div className='lg:w-1/2 w-full md:pr-20 md:pb-0 pr-[0] pb-10'>
          <h2 className='font-medium md:text-[2.75rem] text-[2rem] leading-tight'>
            <Trans>
              <span className='text-new-primary'>Discover, Collect, Trade</span> authentic NFT art pieces from
              professional Mangakas
            </Trans>
          </h2>
          <p className='text-xl mt-4'>
            <Trans>
              Find your favorite among all the available NFTs on Trophee or be among the first to own these NFTs by
              getting them straight from the Mangakas by MangaDrops.
            </Trans>
          </p>
          <div className='mt-10 flex flex-wrap justify-center'>
            <Button className='px-8 w-full md:w-auto' onClick={() => history.push('/marketplace')}>
              <Trans>Marketplace</Trans>
            </Button>
            <Button
              variant='secondary'
              className='px-8 w-full mt-[0.5rem] md:w-auto md:ml-4 md:mt-0'
              onClick={() => history.push('/manga-drop')}
            >
              <Trans>MangaDrop</Trans>
            </Button>
          </div>
        </div>
        <div className='flex-1'>
          <div className='home__header-intro relative p-[1.25rem] border-white border-solid border-2 rounded'>
            <img src={image} alt='home' className='w-full h-[450px] object-cover object-top' />
            <Link to='/features' className='text-white'>
              <div className='absolute -bottom-12 right-0 md:-bottom-5 md:-right-5 bg-gradient-to-r to-[#C252CC] from-[#5F2AB5] text-center py-3 px-4 md:py-4 md:px-14 text-white rounded'>
                <h5 className='text-white font-medium mb-2'>
                  <Trans>Featured Artist</Trans>
                </h5>

                <Trans>Kiriko Yumeji</Trans>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Intro
