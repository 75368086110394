import React from 'react'
import { Trans } from '@lingui/macro'

import { FeaturedCollection as FeatureCollectionComponent } from 'components'

const FeaturedCollection = () => {
  return (
    <div className='py-20'>
      <div className='text-center'>
        <h2 className='md:text-[2.75rem] text-[2rem] leading-tight font-medium'>
          <Trans>Featured Works by Kiriko Yumeji</Trans>
        </h2>
        <p className='text-xl mt-4 text-ink-lighter'>
          <Trans>
            Trophee presents a number of categories you can choose from. Categories vary from Unique, Epic, Rare, and
            Common. Each NFT art has its own story and comes from a wide number of certified mangakas around the world
            working in collaboration with Trophee to provide users with authentic NFTs.
          </Trans>
        </p>
      </div>
      <FeatureCollectionComponent />
    </div>
  )
}

export default FeaturedCollection
