import React, { useState } from 'react'
import { t, Trans } from '@lingui/macro'

import { useLogInPopup, useSignUpPopup } from 'store/popup/hook'
import { SocialLogin, Tab } from 'components'
import { useModal } from 'hooks'

import logo from 'assets/images/logo-black.png'
import LoginWithEmail from './LoginWithEmail'
import LoginWithPhonenumber from './LoginWithPhonenumber'

const LogInModal = () => {
  const [logInPopup, setLogInPopup] = useLogInPopup()
  const [signUpPopup] = useSignUpPopup()

  const [error, setError] = useState('')
  const [tab, setTab] = useState('email')

  useModal({
    container: '.popup-login',
    trigger: '.popup-close-login',
    setAtom: setLogInPopup
  })

  const handleSignUp = () => {
    logInPopup?.hide()
    signUpPopup?.show()
  }

  return (
    <div className='popup-box mid popup-login'>
      <div className='popup-close-login'>
        <svg className='popup-close-button-icon icon-cross'>
          <use xlinkHref='#svg-cross'></use>
        </svg>
      </div>

      <div className='popup-box-body flex flex-col p-8'>
        <img src={logo} className='w-[120px] sm:w-[166px] h-[1.125rem] sm:h-6' alt='logo' />
        <h4 className='mt-9'>
          <Trans>Log In</Trans>
        </h4>
        <p className='mt-2 text-ink-lighter'>
          <Trans>Welcome back to Trophee Community.</Trans>
        </p>
        <div className='my-3'>
          <div className='flex border-b-[1px] border-t-0 border-r-0 border-l-0 border-sky-light border-solid pb-2 my-4'>
            <Tab key='email' title={t`Log In with Email`} active={tab === 'email'} onChange={() => setTab('email')} />
            <Tab
              key='phoneNumber'
              title={t`Log In with Mobile`}
              active={tab === 'phoneNumber'}
              onChange={() => setTab('phoneNumber')}
            />
          </div>
          {tab === 'email' && <LoginWithEmail onSuccess={() => logInPopup?.hide()} />}
          {tab === 'phoneNumber' && <LoginWithPhonenumber onSuccess={() => logInPopup?.hide()} />}
        </div>
        <div className='relative'>
          <div className='absolute text-ink-lighter px-[0.25rem] bg-white left-[47%] top-[-9px]'>
            <Trans>or</Trans>
          </div>
          <div className='border border-sky-light'></div>
        </div>
        <div className='my-3'>
          <SocialLogin setError={setError} />
        </div>
        {!!error && <div className='text-red-500 text-center'>{error}</div>}
        <div className='border-[0px] border-t-[1px] border-sky-light border-solid text-center pt-4 mt-2 text-base'>
          <Trans>
            New Users?{' '}
            <span onClick={handleSignUp} className='text-semantic-blue underline cursor-pointer'>
              Create an Account
            </span>
          </Trans>
        </div>
      </div>
    </div>
  )
}

export default LogInModal
