import React from 'react'
import Slider from 'react-slick'

import { featuresMangaDrop } from 'config/featuredCollection'

import { ReactComponent as LeftArrow } from 'assets/images/left_arrow.svg'
import { ReactComponent as RightArrow } from 'assets/images/right_arrow.svg'
import CarouselItem from './CarouselItem'

const NextArrow = ({ onClick }: { className?: string; onClick?: () => void }) => {
  return (
    <div
      className='absolute top-1/2 right-0 md:right-[70px] flex w-[52px] h-[52px] bg-[#fff] bg-opacity-20 rounded-full justify-center items-center cursor-pointer hover:shadow-md z-10'
      onClick={onClick}
    >
      <RightArrow />
    </div>
  )
}

const PrevArrow = ({ onClick }: { className?: string; onClick?: () => void }) => {
  return (
    <div
      className='absolute top-1/2 left-0 md:left-[70px] flex w-[52px] h-[52px] bg-[#fff] bg-opacity-20 rounded-full justify-center items-center cursor-pointer hover:shadow-md z-10'
      onClick={onClick}
    >
      <LeftArrow />
    </div>
  )
}

const Carousel = () => {
  const settings = {
    className: 'mangaDrop__slick',
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  }
  return (
    <div className='full-width md:-mt-4 -mt-9'>
      <Slider {...settings}>
        {featuresMangaDrop.map((feature, index) => (
          <CarouselItem key={index} images={feature.images} author={feature.author} title={feature.title} />
        ))}
      </Slider>
    </div>
  )
}

export default Carousel
