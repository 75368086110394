import { t } from '@lingui/macro'
import article_1 from 'assets/images/home/article_1.jpg'
import article_2 from 'assets/images/home/article_2.png'
import article_3 from 'assets/images/home/article_3.png'

export const articles = [
  {
    title: t`A step by step guide on how to stake your $MANGA on Unifarm!`,
    link: 'https://medium.com/manga-token/a-step-by-step-guide-on-how-to-stake-your-manga-on-unifarm-c338c665a38',
    content: t`Staking your LP tokens ($MANGA) is important and it would be a lot easier if you will follow these steps if you’re using a Metamask wallet`,
    image: article_1
  },
  {
    title: t`Manga Token: Unleashing Potentials`,
    link: 'https://medium.com/manga-token/manga-token-unleashing-potentials-a9c24ef29c41',
    content: t`Almost everyone loves reading manga, but we should also adapt to this fast-paced world of NFTs. Come, let's learn more about the potentials of Manga Token in this article.`,
    image: article_2
  },
  {
    title: t`Why is NFT the future of the art world`,
    link: 'https://medium.com/manga-token/why-is-nft-the-future-of-the-art-world-850a402a3fd6',
    content: t`Having your favorite Manga transformed into an NFT is so fascinating! And as we all know, we’re living in the cyberspace world and in this article we will acquire more knowledge about the future of the Art world.`,
    image: article_3
  }
]

export const whatsNew = [
  '0bc91f3f6c6ef5e53f9393fc07d4112c',
  '0e87816d381527209886fcded100d34e',
  '1a239bc437907564fb370e3f137bf6d4',
  '1f44b85e10fdab61327af0b289dd1d4f',
  '2c53ce6b3cb699e1be7b04a392e1700c',
  '6ae5857bb7a8cf965ad0081596d8a026'
]
