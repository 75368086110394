import React from 'react'

export interface SectionHeaderProps {
  preTitle: string
  title: React.ReactNode
  actionComponent?: React.ReactNode
}

const SectionHeader: React.FC<SectionHeaderProps> = ({ preTitle, title, actionComponent }) => {
  return (
    <div className='section-header'>
      <div className='section-header-info'>
        <p className='section-pretitle'>{preTitle}</p>
        <h2 className='section-title'>{title}</h2>
      </div>

      {actionComponent}
    </div>
  )
}

export default SectionHeader
