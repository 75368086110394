import React, { useState, useRef } from 'react'
import { t } from '@lingui/macro'
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input'
import en from 'react-phone-number-input/locale/en.json'
import { useForm, Controller } from 'react-hook-form'
import { signInWithPhoneNumber, RecaptchaVerifier } from 'firebase/auth'

import { auth } from 'libs/firebase'
import { SIGN_UP_PHONE } from 'config/constantKey'

import { Button, Input, Select } from 'components'

type SignUpWithPhoneProps = {
  onNext: (value: string) => void
}

type Option = {
  value: string
  label: string
}

type FormData = {
  phoneCode: Option
  phoneNumber: string
}

const countries = getCountries().map(country => ({
  label: en[country],
  value: country
}))

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    height: 54,
    minHeight: 54
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  menu: (base: any) => ({ ...base, zIndex: 20, width: 300 })
}

const SignUpWithPhone = ({ onNext }: SignUpWithPhoneProps) => {
  const [error, setError] = useState('')
  const [isLoading, setIsloading] = useState(false)
  const recaptchaWrapperRef = useRef<HTMLDivElement>(null)

  const mobile = JSON.parse(localStorage.getItem(SIGN_UP_PHONE) || '{}')
  const { control, formState, handleSubmit } = useForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      phoneCode: mobile?.phoneCode || countries[0],
      phoneNumber: mobile?.phoneNumber || ''
    }
  })
  const { errors } = formState

  const handleNext = (values: FormData) => {
    if (window.recaptchaVerifier && recaptchaWrapperRef.current) {
      window.recaptchaVerifier.clear()
      recaptchaWrapperRef.current.innerHTML = `<div id="recaptcha-container-signup"></div>`
    }

    window.recaptchaVerifier = new RecaptchaVerifier(
      'recaptcha-container-signup',
      {
        size: 'invisible',
        callback: () => {}
      },
      auth
    )

    localStorage.setItem(SIGN_UP_PHONE, JSON.stringify(values))

    const phoneNumber = `+${getCountryCallingCode((values.phoneCode?.value as any) || 'SG')}${values.phoneNumber}`
    setIsloading(true)
    signInWithPhoneNumber(auth, phoneNumber, window.recaptchaVerifier)
      .then(confirmationResult => {
        window.confirmationResult = confirmationResult
        onNext(phoneNumber)
      })
      .catch(error => {
        const errorMessage = error.message
        setError(errorMessage)
      })
      .finally(() => setIsloading(false))
  }

  const CustomOption = ({ data, innerProps }: any) => {
    return (
      <div {...innerProps} className='flex justify-between m-3 cursor-pointer'>
        <div className='flex'>
          <img
            className='w-8 mr-3'
            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${data.value}.svg`}
            alt={data.label}
          />
          <p>{data.label}</p>
        </div>
        <p>+{getCountryCallingCode(data.value)}</p>
      </div>
    )
  }

  const CustomSingleValue = ({ data }: any) => {
    return (
      <div className='flex'>
        <img
          className='w-8 mr-3'
          src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${data.value}.svg`}
          alt={data.label}
        />
        <p>+{getCountryCallingCode(data.value)}</p>
      </div>
    )
  }

  return (
    <form onSubmit={handleSubmit(handleNext)}>
      <div className='flex flex-wrap mb-4'>
        <div className='md:w-[150px] w-full md:mr-[1rem] mr-[0px] mb-[1rem] md:mb-0'>
          <Controller
            control={control}
            name='phoneCode'
            render={({ field }) => (
              <Select
                {...field}
                options={countries}
                components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
                styles={customStyles}
              />
            )}
            rules={{
              required: 'Phone Code is required'
            }}
          />
        </div>
        <div className='relative flex-1'>
          <Controller
            render={({ field }) => <Input placeholder={t`Phone Number`} {...field} errors={errors} />}
            control={control}
            name='phoneNumber'
            rules={{
              required: t`Phone Number is required`,
              pattern: {
                value: /^\d+$/,
                message: 'Invalid Phone Number'
              }
            }}
          />
        </div>
      </div>
      {error && <div className='text-red-500 my-2'>{error}</div>}
      <Button className='w-full' type='submit' isLoading={isLoading} disabled={isLoading}>
        Next
      </Button>
      <div ref={recaptchaWrapperRef}>
        <div id='recaptcha-container-signup'></div>
      </div>
    </form>
  )
}

export default SignUpWithPhone
