import React, { useRef, useState, useMemo } from 'react'
import Slider from 'react-slick'
import classnames from 'classnames'
import { isEmpty } from 'lodash'

import { useWindowSize } from 'hooks'
import { useCollectionImages } from 'store/myCollection/hook'

import { CarouselItem } from '.'

const Carousel = () => {
  const ref = useRef<Slider | null>(null)
  const [current, setCurrent] = useState(0)
  const windowSize = useWindowSize()
  const [collectionImages] = useCollectionImages()

  const collections: string[] = useMemo(() => {
    return Object.keys(collectionImages || {}).map(item => collectionImages?.[item] || '')
  }, [collectionImages])

  const settings = useMemo(() => {
    return {
      centerMode: windowSize.width >= 480,
      centerPadding: windowSize.width > 480 && windowSize.width <= 1028 ? '10%' : '25%',
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      beforeChange: (_: number, next: number) => setCurrent(next)
    }
  }, [windowSize])

  const handleNext = () => {
    ref.current?.slickNext()
  }

  const handlePrevious = () => {
    ref.current?.slickPrev()
  }

  const progress = useMemo(() => {
    return ((current + 1) / collections.length) * 100
  }, [collections.length, current])

  return (
    <div className='md:min-h-[620px] max-h-[300px] flex items-center md:justify-end'>
      <div className='w-full md:w-2/3 xl:w-full'>
        <Slider {...settings} ref={ref}>
          {collections.map((item, index) => (
            <CarouselItem key={index} item={item} />
          ))}
        </Slider>
        {!isEmpty(collections) && (
          <div className='flex items-center md:pl-[10%] xl:pl-[25%] mt-4 md:mt-10'>
            <div className='md:pr-[1rem]'>
              <span className='px-3 cursor-pointer' onClick={handlePrevious}>
                <svg
                  className={classnames('fill-current w-6 h-6', {
                    'text-white': current !== 0,
                    'text-ink-light': current === 0
                  })}
                >
                  <use xlinkHref='#svg-left-arrow'></use>
                </svg>
              </span>
              <span className='px-3 cursor-pointer' onClick={handleNext}>
                <svg
                  className={classnames('fill-current w-6 h-6', {
                    'text-white': current !== collections.length - 1,
                    'text-ink-light': current === collections.length - 1
                  })}
                >
                  <use xlinkHref='#svg-right-arrow'></use>
                </svg>
              </span>
            </div>
            <div className='relative flex-1 h-[2px] bg-ink-light rounded'>
              <div
                className='absolute bg-white h-[2px] rounded transition-all duration-300 ease-linear'
                style={{
                  width: `${progress}%`
                }}
              ></div>
            </div>
            <div className='px-4 text-white font-medium text-sm'>
              {current + 1}/{collections.length}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Carousel
