import React, { useLayoutEffect, useRef } from 'react'
import { useWeb3React } from '@web3-react/core'
import { Trans } from '@lingui/macro'

import { useWalletPopup } from 'store/popup/hook'
import { createPopup } from 'utils/plugins'
import { connectors } from 'config/connectors'

import logo from 'assets/images/logo-black.png'
import WalletCard from './WalletCard'

export interface WalletConnectModalProps {}

const WalletConnectModal: React.FC<WalletConnectModalProps> = () => {
  const popupRef = useRef<any>()
  const { account } = useWeb3React()
  const [, setWalletPopup] = useWalletPopup()

  useLayoutEffect(() => {
    popupRef.current = createPopup({
      container: '.popup-wallet-connect',
      trigger: '.popup-wallet-connect-trigger',
      overlay: {
        color: '21, 21, 31',
        opacity: 0.86
      },
      animation: {
        type: 'translate-in-fade',
        speed: 0.3,
        translateOffset: 40
      }
    })

    setWalletPopup(popupRef.current)

    return () => popupRef.current?.hide()
  }, [setWalletPopup])

  useLayoutEffect(() => {
    if (account) {
      popupRef.current?.hide()
    }
  }, [account])

  const handleClose = () => {
    popupRef.current?.hide()
  }

  return (
    <div className='popup-box small popup-wallet-connect'>
      <div className='popup-close-button popup-wallet-connect-trigger' onClick={handleClose}>
        <svg className='popup-close-button-icon icon-cross'>
          <use xlinkHref='#svg-cross'></use>
        </svg>
      </div>

      <div className='popup-box-body flex flex-col p-8'>
        <img src={logo} className='w-[120px] sm:w-[166px] h-[1.125rem] sm:h-6' alt='logo' />
        <p className='text-ink-lighter my-8'>
          <Trans>Do you have any preexisting cryptowallet you’d like to connect to your account?</Trans>
        </p>
        {connectors.map(connector => (
          <WalletCard key={connector.title} walletConfig={connector} />
        ))}
      </div>
    </div>
  )
}

export default WalletConnectModal
