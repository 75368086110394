import React from 'react'
import { Trans } from '@lingui/macro'

import { ReactComponent as NoResultIcon } from 'assets/images/no-result.svg'

const NoResult = () => {
  return (
    <div className="text-center py-20">
      <NoResultIcon />
      <p className="text-ink-lighter">
        <Trans>No results. Try some different keyword.</Trans>
      </p>
    </div>
  )
}

export default NoResult
