import React, { useState, useEffect } from 'react'
import { t, Trans } from '@lingui/macro'
import { useForm, Controller } from 'react-hook-form'
import { createUserWithEmailAndPassword, sendEmailVerification, signInWithEmailAndPassword } from 'firebase/auth'
import { isEmpty } from 'lodash'

import { auth } from 'libs/firebase'

import { Button, Input } from 'components'
import { SIGN_UP_EMAIL, TEMP_PASSWORD } from 'config/constantKey'

type SignUpWithEmailProps = {
  onNext: (value: string) => void
}

type FormData = {
  email: string
}

enum Status {
  NotVerify,
  Verifing,
  Verified
}

const SignUpWithEmail = ({ onNext }: SignUpWithEmailProps) => {
  const [error, setError] = useState('')
  const [status, setStatus] = useState<Status>(Status.NotVerify)

  const { control, formState, handleSubmit, watch } = useForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      email: localStorage.getItem(SIGN_UP_EMAIL) || ''
    }
  })
  const { errors, isValidating } = formState
  const email = watch('email')

  const handleNext = (values: FormData) => {
    if (Status.Verified === status) return onNext(values.email)
    return setError(t`Email is not verified`)
  }

  const handleVerify = () => {
    localStorage.setItem(SIGN_UP_EMAIL, email)
    setError('')
    signInWithEmailAndPassword(auth, email, TEMP_PASSWORD)
      .then(userCredential => {
        const user = userCredential.user
        if (user.emailVerified) {
          setStatus(Status.Verified)
          return setError(t`Email already exists`)
        }
        return handlSendEmailVerification()
      })
      .catch(() => {
        handleCreateUser(email, TEMP_PASSWORD)
      })
  }

  const handleCreateUser = (email: string, pass: string) => {
    createUserWithEmailAndPassword(auth, email, pass)
      .then(() => {
        handlSendEmailVerification()
      })
      .catch(error => {
        const errorMessage = error.message
        setError(errorMessage)
      })
  }

  const handlSendEmailVerification = () => {
    if (auth.currentUser) {
      sendEmailVerification(auth.currentUser)
        .then(() => {
          setStatus(Status.Verifing)
        })
        .catch(error => {
          const errorMessage = error.message
          setError(errorMessage)
        })
    }
  }

  useEffect(() => {
    if (isValidating) {
      setError('')
      setStatus(Status.NotVerify)
    }
  }, [isValidating])

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      const refreshInterval = setInterval(() => {
        user?.reload()
        if (user) {
          if (user.emailVerified) {
            clearInterval(refreshInterval)
            return setStatus(Status.Verified)
          }
        }
      }, 2000)
    })
    return () => unsubscribe()
  }, [])

  return (
    <form onSubmit={handleSubmit(handleNext)}>
      <div className='relative mb-4'>
        <Controller
          render={({ field }) => <Input placeholder={t`Email Address`} {...field} errors={errors} />}
          control={control}
          name='email'
          rules={{
            required: t`Email is required`,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: t`Invalid email address`
            }
          }}
        />
        {Status.Verified === status ? (
          <span>
            <svg className='w-5 h-5 absolute top-[17px] right-2'>
              <use xlinkHref='#svg-approved'></use>
            </svg>
          </span>
        ) : (
          <span
            className='absolute text-new-primary cursor-pointer top-[17px] right-2'
            onClick={() => isEmpty(errors) && handleVerify()}
          >
            {Status.Verifing === status ? <Trans>Resend</Trans> : <Trans>Verify</Trans>}
          </span>
        )}
        {error && <div className='text-red-500 mt-2'>{error}</div>}
        {Status.Verifing === status && (
          <div className='mt-2 text-center'>
            <Trans>Verifying... please check your email</Trans>
          </div>
        )}
      </div>
      <Button className='w-full'>Next</Button>
    </form>
  )
}

export default SignUpWithEmail
