import { useEffect } from 'react'
import { useQuery } from 'react-query'

import { BridgeOrder } from 'types/order'
import { InfiniteQuery } from 'types/product'
import { useSignMessage } from 'hooks'
import { viewDetailSignatureKey } from 'config/constantKey'
import { useSignature } from 'store/user/hook'

import useInvalidateUrl from './invalidate'
import { getBridgeOrders } from 'apis/bridge'
import { ercChainIds } from 'utils/metamask'
import { useWeb3React } from '@web3-react/core'

const getInfiniteUrl = (url: string, { limit, cursor }: InfiniteQuery = {}) => {
  if (limit) {
    url = url + `&limit=${limit}`
  }
  if (cursor) {
    url = url + `&cursor=${cursor}`
  }
  return url
}

export const bridgeOrderQueryKey = ({ owner_id, limit, cursor }: { owner_id?: string } & InfiniteQuery) =>
  getInfiniteUrl(`/bridge/orders?owner_id=${owner_id}`, { limit, cursor })
export const useBridgeOrder = ({
  owner_id,
  limit,
  cursor
}: {
  owner_id?: string
} & InfiniteQuery) => {
  const { chainId } = useWeb3React()
  const invalidate = useInvalidateUrl(bridgeOrderQueryKey({ owner_id, limit }))

  const signMessage = useSignMessage(viewDetailSignatureKey)
  const [signature] = useSignature(viewDetailSignatureKey)

  useEffect(() => {
    const requestSign = async () => {
      if (!signature && chainId) {
        if (ercChainIds.includes(chainId)) {
          await signMessage()
        }
      }
    }

    requestSign()
  }, [signature, signMessage, chainId])

  return {
    invalidate,
    ...useQuery<{
      data: BridgeOrder[]
      cursor: number
    }>(
      bridgeOrderQueryKey({ owner_id, limit, cursor }),
      async () =>
        getBridgeOrders({
          url: bridgeOrderQueryKey({ owner_id, limit, cursor }),
          msg: viewDetailSignatureKey,
          sig: signature || ''
        }),
      {
        enabled: !!(chainId && ercChainIds.includes(chainId) && signature)
      }
    )
  }
}
