import { NFTOrder } from 'types/order'
import { Classification } from 'types/product'

export const storageKey = {
  connector: '@trophee/connector',
  chainId: '@trophee/chainId',
  language: '@trophee/language',
  sigMessage: 'Sign to authorize your self'
}

export const signatureKey = 'Please sign to connect to Trophee'
export const viewDetailSignatureKey = 'Please sign to authorize and able to view detail'
export const sellItemKey = 'Please confirm that you would like to sell:'
export const viewSellSignatureKey = 'Please sign to authorize and able to view your sell orders'
export const cancelSellItemKey = 'Confirm that you would like to cancel the sale of:'
export const bidItemKey = 'Please sign to authorize and able to bid this auction'
export const viewBidItemKey = 'Please sign to authorize and able to see all of bids of this auction'
export const approveBidKey = 'Please sign to authorize and able to approve this bid for your auction'
export const checkKYCKey = 'Please sign to authorize your KYC status and confirm that you would like to pay for:'
export const checkEligibilityKey = 'Please sign to check your KYC status'
export const linkWalletKey = 'Please sign to link your wallet address with your Trophee account'

export const SHOW_LIMIT = 20
export const MANGA_ADDRESS = '0xc2cb89bbb5bba6e21db1dfe13493dfd7dcbabd68'
export const MAX_RESERVE_PRICE = 999999999999

export const TROPHEE_OWNER_ADDRESS =
  process.env.REACT_APP_AUTHOR_ADDRESS || '0xccd6cc00981952ca24e3ed61fe84b784f9cec9da'
export const MEMBERSHIP_PRODUCTS = ['1b8202f63c9cf1f9f7e8760d157ba91c'] // @todo: remove soon
export const MEMBERSHIP_NFT_ADDRESS =
  process.env.REACT_APP_CHAIN_ID === '56'
    ? '0x8e2d6051a1285d787d41322528924ed8a450ae24'
    : '0x355f98d7a683e243375f7ed82d5a2038535ee873'
export const MEMBERSHIP_LEVEL1_ID = '1'
export const MEMBERSHIP_ORDER_LEVEL1: Omit<NFTOrder, 'product'> = {
  // @todo: remove all soon
  id: '0x6455aff4924f2a4a715901a1b44652d73354eeb56590ee478db8d10c5bd29f08',
  seller_id: '0xCcd6cc00981952cA24e3ED61fE84B784f9cec9da',
  owner_id: '',
  type: 1,
  status: 0,
  product_id: '0x8e2d6051a1285d787d41322528924ed8a450ae24_1',
  product_rarity: Classification.MemberShip1,
  contract_id: 1,
  contract_address: '0x8e2d6051a1285d787d41322528924ed8a450ae24',
  serial: '0x8e2d6051a1285d787d41322528924ed8a450ae24_1',
  edition_number: 1,
  price: 1,
  name: 'Membership Level 1',
  name_jp: 'Membership Level 1',
  author_name: 'Trophee',
  author_name_jp: 'Trophee',
  order: {
    exchange: '0x0ecb63e035e82Cc88D9d94AEc29c290bF2c0570f',
    maker: '0xccd6cc00981952ca24e3ed61fe84b784f9cec9da',
    taker: '0x0000000000000000000000000000000000000000',
    quantity: '1',
    makerRelayerFee: '250',
    takerRelayerFee: '0',
    makerProtocolFee: '0',
    takerProtocolFee: '0',
    makerReferrerFee: '0',
    waitingForBestCounterOrder: false,
    feeMethod: 1,
    feeRecipient: '0xCcd6cc00981952cA24e3ED61fE84B784f9cec9da',
    side: 1,
    saleKind: 0,
    target: '0x8e2d6051a1285d787d41322528924ed8a450ae24',
    howToCall: 0,
    calldata:
      '0xf242432a000000000000000000000000ccd6cc00981952ca24e3ed61fe84b784f9cec9da00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000001000000000000000000000000000000000000000000000000000000000000000100000000000000000000000000000000000000000000000000000000000000a00000000000000000000000000000000000000000000000000000000000000000',
    replacementPattern:
      '0x000000000000000000000000000000000000000000000000000000000000000000000000ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000',
    staticTarget: '0x0000000000000000000000000000000000000000',
    staticExtradata: '0x',
    paymentToken: '0xc2cb89bbb5bba6e21db1dfe13493dfd7dcbabd68',
    basePrice: '1000000000000000000',
    extra: '0',
    listingTime: '1633808572',
    expirationTime: '0',
    salt: '36054793570952359294908871888780040341494823227747149576594787272375106778712',
    metadata: {
      asset: { id: '1', address: '0x8e2d6051a1285d787d41322528924ed8a450ae24', quantity: '1' },
      schema: 'ERC1155'
    },
    hash: '0x14b76fabef20339d74543e9b46b3e96d4b0f4b06b6ac959138746d2b24bfa26e',
    message: '0x14b76fabef20339d74543e9b46b3e96d4b0f4b06b6ac959138746d2b24bfa26e',
    messageHash: '0x6455aff4924f2a4a715901a1b44652d73354eeb56590ee478db8d10c5bd29f08',
    v: '0x1b',
    r: '0x6e8ad33e5ef381bab37c6f65cc0777ceebd235229f699bae74927312249ae6c2',
    s: '0x4f614a3d88562d9bb9cceed944aec9f83abf24522bd75565165ab4d3636f5bd4',
    signature:
      '0x6e8ad33e5ef381bab37c6f65cc0777ceebd235229f699bae74927312249ae6c24f614a3d88562d9bb9cceed944aec9f83abf24522bd75565165ab4d3636f5bd41b'
  },
  deleted_at: 0,
  created_at: 1633808676017,
  updated_at: 1633808676017,
  _search: []
}
export const MEMBERSHIP_PRODUCT_ID =
  process.env.REACT_APP_CHAIN_ID === '56' ? '77a3f553666ab77020a502e5079335c8' : '77a3f553666ab77020a502e5079335c8'
export const KYC_CODE = {
  Requested: 'KYC_REQUIRED',
  Pending: 'KYC_PENDING',
  Rejected: 'KYC_REJECTED'
}
export const KYC_LINK =
  'https://verify-with.blockpass.org/?clientId=trophee_digital_pte_ltd_8c5c9&serviceName=Trophee%20Digital%20Pte%20Ltd&env=prod'
export const notEnoughBalanceMessage = 'execution reverted: BEP20: transfer amount exceeds balance'

export const ACCESS_TOKEN_KEY = 'trophee-token'
export const TEMP_PASSWORD = 'trophee'
export const SIGN_UP_EMAIL = 'sign-up-email'
export const SIGN_UP_PHONE = 'sign-up-phonenumber'

export const tropheeETHUrl = 'https://eth.trophee.xyz'
