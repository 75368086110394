import React, { useState } from 'react'
import { t, Trans } from '@lingui/macro'
import { useForm, Controller } from 'react-hook-form'
import { PhoneAuthProvider, signInWithCredential } from 'firebase/auth'

import { auth } from 'libs/firebase'
import { ACCESS_TOKEN_KEY, SIGN_UP_PHONE } from 'config/constantKey'

import { Button, Input } from 'components'

type OTPFormProps = {
  phoneNumber: string
  onSuccess: () => void
}

type FormData = {
  otp: string
}

const OTPForm = ({ phoneNumber, onSuccess }: OTPFormProps) => {
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const { control, formState, handleSubmit } = useForm<FormData>({
    mode: 'onChange'
  })
  const { errors } = formState

  const handleCreateAccount = (values: FormData) => {
    setIsLoading(true)
    window.confirmationResult
      .confirm(values.otp)
      .then(() => {
        const credential = PhoneAuthProvider.credential(window.confirmationResult.verificationId, values.otp)
        signInWithCredential(auth, credential)
          .then(async userCredential => {
            const token = await userCredential.user.getIdToken()
            if (token) {
              localStorage.setItem(ACCESS_TOKEN_KEY, token)
              localStorage.removeItem(SIGN_UP_PHONE)
              onSuccess()
            }
          })
          .catch((error: any) => {
            const errorMessage = error.message
            setError(errorMessage)
          })
          .finally(() => setIsLoading(false))
      })
      .catch((error: any) => {
        const errorMessage = error.message
        setError(errorMessage)
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <div className='mt-3'>
      <form onSubmit={handleSubmit(handleCreateAccount)}>
        <span>
          <Trans>Your Phone Number</Trans>
        </span>
        <p className='font-medium'>{phoneNumber}</p>
        <div className='my-4'>
          <div className='mb-3'>
            <Controller
              render={({ field }) => <Input placeholder={t`Enter OTP`} {...field} errors={errors} />}
              control={control}
              name='otp'
              rules={{
                required: t`OTP is required`
              }}
            />
          </div>
          {error && <div className='text-red-500 mt-2'>{error}</div>}
        </div>
        <Button className='w-full' type='submit' isLoading={isLoading} disabled={isLoading}>
          <Trans>Create Account</Trans>
        </Button>
      </form>
    </div>
  )
}

export default OTPForm
