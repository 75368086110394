import React from 'react'

type SlideItemProps = {
  item: string
}

const SlideItem = ({ item }: SlideItemProps) => {
  return (
    <div className='px-3 md:h-[350px] lg:h-[400px]'>
      <img className='w-full h-full rounded object-contain' src={item} alt={item} />
    </div>
  )
}

export default SlideItem
