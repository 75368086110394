import { useCallback, useMemo } from 'react'
import { useAtom } from 'jotai'
import { useWeb3React } from '@web3-react/core'
import { Web3Provider, JsonRpcSigner } from '@ethersproject/providers'

import { useSignature, useDerivedSignature } from 'store/user/hook'
import { walletChanged } from 'store/user'

import { signatureKey, storageKey } from 'config/constantKey'

export const useSigner = () => {
  const { account, library } = useWeb3React<Web3Provider>()

  const signer = useMemo(() => {
    if (account && library) {
      return library.getSigner(account)
    }
  }, [account, library])

  return signer
}

export const signMessage = async (signer: JsonRpcSigner, message?: string) => {
  // bsc wallet connector
  if (signer.provider.connection.url === 'eip-1193:') {
    return (window as any).BinanceChain.request({
      method: 'eth_sign',
      params: [signer._address, message || signatureKey]
    })
  }

  return signer.signMessage(message || signatureKey)
}

export const useSignMessage = (message?: string, strict = true) => {
  const msg = useMemo(() => message || signatureKey, [message])
  const [signature] = useSignature(msg)
  const [, setSignature] = useDerivedSignature()
  const signer = useSigner()

  const [, setChanged] = useAtom(walletChanged)

  return useCallback(async () => {
    if (!signer) {
      return Promise.resolve(['', ''])
    }

    // don't strict, send stored msg and sign
    if (!strict) {
      const sig = localStorage.getItem(storageKey.sigMessage)
      if (!sig) {
        const sign = await signMessage(signer, storageKey.sigMessage)
        localStorage.setItem(storageKey.sigMessage, sign)
        setChanged(false)

        return [sign, storageKey.sigMessage]
      }

      return [sig, storageKey.sigMessage]
    }

    // already have signature for that message
    if (signature) {
      return Promise.resolve([signature, msg])
    }

    const sign = await signMessage(signer, msg)
    setSignature({
      message: msg,
      signature: sign
    })

    return [sign, msg]
  }, [signer, strict, msg, signature, setSignature, setChanged])
}

export interface SignDynamicMessage {
  name: string
  serial: string
  price: number
  symbol?: string
}

export const useSignDynamicMessage = (messageKey: string, priceLabel?: string) => {
  const msg = useCallback(
    ({ name, serial, price, symbol }: SignDynamicMessage) => {
      return `${messageKey} \n
      Item name: ${name}
      Serial: ${serial}
      ${priceLabel || 'Price'}: ${price} ${symbol || '$MANGA'}`
    },
    [messageKey, priceLabel]
  )
  const signer = useSigner()

  return useCallback(
    async ({ name, serial, price, symbol }: SignDynamicMessage) => {
      if (signer) {
        const sig = await signMessage(signer, msg({ name, serial, price, symbol }))

        return [sig, msg({ name, serial, price, symbol })]
      }

      return []
    },
    [msg, signer]
  )
}
