import { MANGA_ADDRESS } from 'config/constantKey'
import { useMemo } from 'react'

import { Product } from 'types/product'

export const useProductPaymentToken = (product: Product) => {
  return useMemo(() => {
    return (
      product.order_fixed_price?.order?.paymentToken ||
      product.order_p2p?.order?.paymentToken ||
      product.order_auction?.order?.paymentToken ||
      MANGA_ADDRESS
    )
  }, [product])
}
