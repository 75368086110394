import React, { useLayoutEffect } from 'react'
import { Link } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import { Trans } from '@lingui/macro'
import { signOut } from 'firebase/auth'

import { Button, ConnectWalletButton } from 'components'
import { useAuth } from 'hooks'
// import { useMyProfile } from 'store/user/hook'

import { createDropdown } from 'utils/plugins'
import {
  ACCESS_TOKEN_KEY,
  MANGA_ADDRESS,
  MEMBERSHIP_LEVEL1_ID,
  MEMBERSHIP_NFT_ADDRESS,
  MEMBERSHIP_PRODUCT_ID
} from 'config/constantKey'
import { useBalanceOf, useBalanceOfToken } from 'queries/product'
import { useGetStatistics, useKYCStatus } from 'queries/user'

import { isTestNet } from 'utils/core'
import { formatCurrency, formatTokenPrice } from 'utils/price'
import { formatKYCStatus } from 'utils/format'
import { useLogInPopup, useSignUpPopup } from 'store/popup/hook'
import { auth } from 'libs/firebase'

export interface ProfileDropdownProps {}

const ProfileDropdown: React.FC<ProfileDropdownProps> = () => {
  // const [profileInfo] = useMyProfile()
  const { account } = useWeb3React()
  const { logout } = useAuth()
  const { data: mangaBalance } = useBalanceOfToken(
    isTestNet ? '0xa1daa25016d83895f215ee724c78fcc1085accb2' : MANGA_ADDRESS,
    account || ''
  )
  const { data: isMembership } = useBalanceOf(MEMBERSHIP_NFT_ADDRESS, MEMBERSHIP_LEVEL1_ID, account || '')
  const { data: statistics } = useGetStatistics(account)
  const { data: KYCStatus } = useKYCStatus(account)

  const [logInPopup] = useLogInPopup()
  const [signUpPopup] = useSignUpPopup()

  const isLoggedIn = !!localStorage.getItem(ACCESS_TOKEN_KEY)

  useLayoutEffect(() => {
    createDropdown({
      trigger: '.header-profile-dropdown-trigger',
      container: '.header-profile-dropdown',
      offset: {
        top: 48,
        right: 18
      },
      animation: {
        type: 'translate-top'
      }
    })
  }, [])

  const handleCopy = () => {
    navigator.clipboard.writeText(account || '')
  }

  const handleLogin = () => {
    if (!logInPopup?.open) {
      logInPopup?.show()
    }
  }

  const handleSignUp = () => {
    if (!signUpPopup?.open) {
      signUpPopup?.show()
    }
  }

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        logout()
        localStorage.removeItem(ACCESS_TOKEN_KEY)
      })
      .catch(error => {})
  }

  return (
    <div>
      <div className='header-profile-dropdown-trigger mx-3'>
        <img className='action-item-icon w-8 h-8 sm:w-9 sm:h-9' src='/images/avatar.png' alt='profile' />
      </div>
      <div className='dropdown-navigation header-profile-dropdown'>
        {isLoggedIn ? (
          <div>
            {account ? (
              <>
                <div className='flex items-center justify-between my-2'>
                  <div />
                  <p className='text-new-darker text-sm'>{`${account.slice(0, 6)}...${account.slice(-4)}`}</p>
                  <img
                    src='/images/profile/copy_icon.png'
                    alt='copy address'
                    className='w-3 h-4 cursor-pointer'
                    onClick={handleCopy}
                  />
                </div>

                <div className='flex items-center justify-between py-1 px-4 mb-3 border border-semantic-commonBg rounded'>
                  <img src={`/images/tokens/${MANGA_ADDRESS}.png`} alt='manga logo' className='w-8 h-8 ml-[-10px]' />
                  <div className='text-center'>
                    <p className='text-new-darker'>Balance</p>
                    {mangaBalance && (
                      <p className='text-new-black font-medium'>
                        {formatCurrency(+formatTokenPrice(mangaBalance, 18))}
                      </p>
                    )}
                  </div>
                  <div />
                </div>

                <Link
                  to={`/marketplace-products/${MEMBERSHIP_PRODUCT_ID}`}
                  className='flex items-center justify-between py-3 text-xs md:text-base'
                >
                  <div className='flex items-center'>
                    <img src='/images/profile/user_icon.png' alt='my membership' className='w-5 h-5 mr-3' />
                    <Trans>My Membership</Trans>
                  </div>
                  <p className='text-sm text-new-darker'>{isMembership ? 'Premium Member' : 'Classic Member'}</p>
                </Link>

                <Link to='#' className='flex items-center justify-between py-3 text-xs md:text-base'>
                  <div className='flex items-center'>
                    <img src='/images/profile/document_icon.png' alt='my kyc status' className='w-5 h-5 mr-3' />
                    <Trans>KYC Status</Trans>
                  </div>
                  <p className='text-sm text-new-darker'>{formatKYCStatus(KYCStatus || '') || 'Loading...'}</p>
                </Link>

                <Link
                  to={`/collections/${account.toLowerCase()}`}
                  className='flex items-center justify-between py-3 text-xs md:text-base'
                >
                  <div className='flex items-center'>
                    <img src='/images/profile/image_icon.png' alt='my collection' className='w-5 h-5 mr-3' />
                    <Trans>My Collection</Trans>
                  </div>
                  <p className='text-sm text-new-darker'>{statistics?.total ?? 0} pieces</p>
                </Link>
              </>
            ) : (
              <ConnectWalletButton />
            )}
            <p className='dropdown-navigation-button button white small' onClick={handleLogout}>
              Logout
            </p>
          </div>
        ) : (
          <>
            <Button className='w-full mb-2 px-8' variant='secondary' onClick={handleLogin}>
              <Trans>Log In</Trans>
            </Button>
            <Button className='w-full mb-2 px-8' onClick={handleSignUp}>
              <Trans>Sign Up</Trans>
            </Button>
          </>
        )}
      </div>
    </div>
  )
}

export default ProfileDropdown
