import { useMemo } from 'react'

import { Product } from 'types/product'

export const useDataUrls = (product?: Product) => {
  return useMemo(() => {
    const videoUrls = product?.assets_collections_urls?.video || []
    const imageUrls = product?.assets_collections_urls?.product_carousel || []
    if (videoUrls.length && imageUrls.length) {
      return [...videoUrls, ...imageUrls]
    }

    return imageUrls || product?.assets_urls || []
  }, [product])
}

export const useDataAssets = (product?: Product) => {
  return useMemo(() => {
    const videoAssets = product?.assets_collections?.video || []
    const imageAssets = product?.assets_collections?.product_carousel || []
    if (videoAssets.length && imageAssets.length) {
      return [...videoAssets, ...imageAssets]
    }

    return imageAssets || product?.assets || []
  }, [product])
}

export const useFullDataUrls = (product?: Product) => {
  return useMemo(() => {
    const videoUrls = product?.assets_collections_urls?.video || []
    const imageUrls = product?.assets_collections_urls?.product_full_screen || []
    if (videoUrls.length && imageUrls.length) {
      return [...videoUrls, ...imageUrls]
    }

    return imageUrls || product?.assets_urls || []
  }, [product])
}
