import axios from 'axios'

import { SignatureRequest } from 'apis/signature'
import { ACCESS_TOKEN_KEY } from 'config/constantKey'

export const updateUserWallet = async (account: string, options: SignatureRequest) => {
  const token = localStorage.getItem(ACCESS_TOKEN_KEY)
  const res = await axios.patch(
    `/v2/user/${account}`,
    {},
    {
      headers: {
        msg: JSON.stringify(options.msg),
        sig: options.sig,
        Authorization: `Bearer ${token}`
      }
    }
  )
  return res.data
}
