import { useQuery } from 'react-query'
import qs from 'query-string'

import { BidOrder, NFTOrder } from 'types/order'
import useInvalidateUrl from './invalidate'

const filterNonNull = (obj: Object) => {
  return Object.fromEntries(Object.entries(obj).filter(([k, v]) => k === 'status' || v))
}

export const collectiblesQueryKey = (account: string) => `/v2/orders?owner_id=${account.toLowerCase()}&status=1`
export const onSellQueryKey = (account: string) => `/v2/orders?seller_id=${account.toLowerCase()}&status=0`

export const useCollections = (query: any) => {
  const url = `/v2/orders?${qs.stringify(filterNonNull(query))}`
  const invalidate = useInvalidateUrl(url)

  return {
    invalidate,
    ...useQuery<{
      data: NFTOrder[]
      cursor: number
    }>(url, {
      enabled: !!query.owner_id || !!query.seller_id
    })
  }
}

export const useMyAuctionBids = (auctionId: string, query: any) => {
  const url = query
    ? `/v2/orders/auction/${auctionId}/bids?${qs.stringify(filterNonNull(query))}`
    : `/v2/orders/auction/${auctionId}/bids`
  return useQuery<{
    data: BidOrder[]
    cursor: number
  }>(url)
}
