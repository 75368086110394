import React from 'react'
import { Trans } from '@lingui/macro'

import { useModal } from 'hooks'
import { useCollectionPopup } from 'store/popup/hook'
import { useCollection } from 'store/myCollection/hook'

// import { Collection } from 'components'

const CollectionModal = () => {
  const [, setCollectionPopup] = useCollectionPopup()
  const [collection] = useCollection()

  useModal({
    container: '.popup-collection',
    trigger: '.popup-collection-trigger',
    setAtom: setCollectionPopup
  })
  return (
    <div className='popup-box popup-collection'>
      <div className='popup-close-button popup-collection-trigger'>
        <svg className='popup-close-button-icon icon-cross'>
          <use xlinkHref='#svg-cross'></use>
        </svg>
      </div>

      <div className='popup-box-body'>
        <div className='popup-box-content limited' data-simplebar>
          <div className='p-8'>
            <h4 className='font-medium text-2xl'>{collection?.title}</h4>
            <p className='font-medium text-ink-lighter'>
              <Trans>{collection?.quantity} Editions</Trans>
            </p>
            <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1'>
              {/* {Array.from(Array(collection?.quantity).keys()).map(item => (
                <Collection
                  key={item}
                  author={collection?.author}
                  title={collection?.title}
                  image={collection?.image}
                  classification={collection?.classification}
                  inscription={collection?.inscription}
                />
              ))} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CollectionModal
