import { useQuery } from 'react-query'
import axios from 'axios'

import useInvalidateUrl from './invalidate'
import { Pagination } from 'types/product'
import { NFTOrder } from 'types/order'
import { SignatureRequest } from 'apis/signature'

const getInfiniteUrl = (url: string, { _limit, _cursor }: Pagination = {}) => {
  if (_limit) {
    url = url + `&_limit=${_limit}`
  }
  if (_cursor) {
    url = url + `&_cursor=${_cursor}`
  }

  return url
}

export const useFixedOrders = ({
  productId,
  _limit,
  _cursor
}: {
  productId: string
} & Pagination) => {
  const invalidate = useInvalidateUrl(
    getInfiniteUrl(`/v2/orders?type=1&type=2&status=0&_sort=price&product_id=${productId}`)
  )

  return {
    invalidate,
    ...useQuery<{
      cursor: number
      data: NFTOrder[]
    }>(getInfiniteUrl(`/v2/orders?type=1&type=2&status=0&_sort=price&product_id=${productId}`, { _limit, _cursor }))
  }
}

export const useAuctionOrders = ({
  productId,
  _limit,
  _cursor
}: {
  productId: string
} & Pagination) => {
  const invalidate = useInvalidateUrl(getInfiniteUrl(`/v2/orders?type=3&status=0&_sort=price&product_id=${productId}`))

  return {
    invalidate,
    ...useQuery<{
      cursor: number
      data: NFTOrder[]
    }>(getInfiniteUrl(`/v2/orders?type=3&status=0&_sort=price&product_id=${productId}`, { _limit, _cursor }))
  }
}

export const getDetail = async (url: string, options?: SignatureRequest) => {
  const res = await axios.get<NFTOrder>(
    url,
    options && {
      headers: {
        msg: JSON.stringify(options.msg),
        sig: options.sig
      }
    }
  )

  return res.data
}

export const useAuctionDetail = (auctionId: string) => {
  const invalidate = useInvalidateUrl(getInfiniteUrl(`/v2/orders/auction/${auctionId}`))

  return {
    invalidate,
    ...useQuery<NFTOrder>(
      getInfiniteUrl(`/v2/orders/auction/${auctionId}`),
      () => getDetail(getInfiniteUrl(`/v2/orders/auction/${auctionId}`)),
      {
        refetchInterval: 10000
      }
    )
  }
}

export const useOrderDetail = (orderId: string) => {
  const invalidate = useInvalidateUrl(getInfiniteUrl(`/v2/orders/${orderId}`))

  return {
    invalidate,
    ...useQuery<NFTOrder>(getInfiniteUrl(`/v2/orders/${orderId}`), () =>
      getDetail(getInfiniteUrl(`/v2/orders/${orderId}`))
    )
  }
}

export const useOwnedItems = (productID: string, account: string) => {
  const url = `/v2/me/products/${productID}/orders?account=${account}&_limit=60`
  const invalidate = useInvalidateUrl(url)

  return {
    invalidate,
    ...useQuery<NFTOrder[]>(url, {
      enabled: !!account
    })
  }
}
