import React, { useState, useRef, useEffect } from 'react'

type CollapseProps = {
  title: string
  content: string
}
const Collapse = ({ title, content }: CollapseProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const [show, setShow] = useState(false)
  const [height, setHeight] = useState<number | undefined>(show ? undefined : 0)
  useEffect(() => {
    if (!height || !show || !ref.current) return undefined
    // @ts-ignore
    const resizeObserver = new ResizeObserver(el => {
      setHeight(el[0].contentRect.height)
    })
    resizeObserver.observe(ref.current)
    return () => {
      resizeObserver.disconnect()
    }
  }, [height, show])

  useEffect(() => {
    if (show) setHeight(ref.current?.getBoundingClientRect().height)
    else setHeight(0)
  }, [show])

  return (
    <div className='border border-sky-light rounded bg-white mb-4 px-3 py-6' onClick={() => setShow(!show)}>
      <div className='flex justify-between items-center'>
        <p className='font-medium'>{title}</p>
        <span className='text-[2rem] w-8 text-right'>{show ? '-' : '+'}</span>
      </div>
      <div className='overflow-hidden transition-all duration-200 ease-in-out' style={{ height }}>
        <div ref={ref}>
          <p className='text-ink-lighter  pt-4'>{content}</p>
        </div>
      </div>
    </div>
  )
}

export default Collapse
