import React from 'react'
import { t, Trans } from '@lingui/macro'

import { Button, Input } from 'components'

const Subscribe = () => {
  return (
    <div className='pt-10'>
      <div className='flex justify-between lg:flex-row flex-col items-center border border-sky-light p-4'>
        <div className='flex lg:flex-row flex-col justify-center md:items-center items-start'>
          <p className='text-5xl'>🔥</p>
          <div className='lg:ml-[1rem] lg:mt-[0px] ml-[0px] mt-[1rem]'>
            <p className='font-medium text-xl'>
              <Trans>Never miss a drop</Trans>
            </p>
            <p className='text-ink-lighter'>
              <Trans>Subscribe for the latest news, drops & collectibles</Trans>
            </p>
          </div>
        </div>
        <div className='flex md:w-[fit-content] w-full lg:flex-row flex-col lg:mt-[0px] mt-[1rem]'>
          {/* <Input placeholder={t`Enter your email address`} />
          <Button className='lg:ml-[1rem] lg:mt-[0px] ml-[0px] mt-[1rem]'>
            <Trans>Subscribe</Trans>
          </Button> */}
          <div className='w-full sm:w-96 lg:w-[480px]'>
            <iframe
              title='Subscribe form'
              src='https://mangatokenofficial.substack.com/embed'
              height='320'
              style={{
                width: '100%',
                border: '1px solid #EEE',
                background: 'white'
              }}
              frameBorder='0'
              scrolling='no'
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Subscribe
