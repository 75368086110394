import React, { useLayoutEffect } from 'react'
import classnames from 'classnames'

import { createProgressBar } from 'utils/plugins'

export interface ProgressBarProps {
  id: string
  targetPoint: number
  currentPoint: number
  linkText: boolean
  linkUnits: string
  invertedProgress?: boolean
  preText?: string
  postText?: string
  emptyText?: string
  completeText?: string
  position?: 'start' | 'center' | 'end'
  animateOnScroll?: boolean
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  id,
  targetPoint,
  currentPoint,
  linkText,
  linkUnits,
  invertedProgress,
  emptyText,
  completeText,
  animateOnScroll,
  preText,
  postText,
  position
}) => {
  useLayoutEffect(() => {
    createProgressBar({ container: `#${id}`, height: 4, lineColor: '#e7e8ee' })

    createProgressBar({
      container: `#${id}`,
      height: 4,
      gradient: { colors: ['#615dfa', '#41efff'] },
      scale: {
        start: 0,
        end: targetPoint,
        stop: currentPoint
      },
      linkText: !!linkText,
      linkUnits: linkUnits,
      emptyText: emptyText,
      completeText: completeText,
      invertedProgress: invertedProgress,
      animateOnScroll: !!animateOnScroll
    })
  }, [id, targetPoint, currentPoint, linkText, linkUnits, invertedProgress, emptyText, completeText, animateOnScroll])

  return (
    <div className='progress-stat'>
      <div id={id} className='progress-stat-bar'></div>

      <div className='bar-progress-wrap'>
        <p
          className={classnames('bar-progress-info negative', {
            [position as string]: !!position
          })}
        >
          {preText ? (
            <>
              <span className='light'>{preText}</span>
              <span className='bar-progress-text'></span>
            </>
          ) : (
            <>
              <span className='bar-progress-text no-space'></span>
              {postText}
            </>
          )}
        </p>
      </div>
    </div>
  )
}

export default ProgressBar
