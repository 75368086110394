import React from 'react'
import { Trans } from '@lingui/macro'

import { useModal } from 'hooks'
import { useKYCRejectedPopup } from 'store/popup/hook'

export interface KYCRejectProps {}

const KYCRejectModal: React.FC<KYCRejectProps> = () => {
  const [popup, setPopup] = useKYCRejectedPopup()

  useModal({
    container: '.popup-kyc-reject',
    trigger: '.popup-close-kyc-reject', // hacky way to force the script can find the popup,
    setAtom: setPopup
  })

  return (
    <div className='popup-box popup-kyc-reject w-full md:w-96 h-80 p-4 md:p-10'>
      <div className='popup-close-kyc-reject' onClick={() => popup?.hide()}>
        <svg className='popup-close-button-icon icon-cross'>
          <use xlinkHref='#svg-cross'></use>
        </svg>
      </div>

      <div className='popup-box-body flex-col items-center justify-center text-center'>
        <h4 className='font-medium uppercase'>
          <Trans>KYC Rejected</Trans>
        </h4>
        <p className='mt-4 font-lg'>
          <Trans>This transaction requires KYC for regulatory purposes.</Trans>
        </p>
        <p className='mt-4 font-lg'>
          <Trans>
            There was an issue with your KYC form, please contact our team at{' '}
            <a href='mailto:support@trophee.xyz'>support@trophee.xyz</a> to resolve the issue.
          </Trans>
        </p>
      </div>
    </div>
  )
}

export default KYCRejectModal
