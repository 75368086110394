import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import round from 'lodash/round'
import { Trans } from '@lingui/macro'

import CoverImage from '../CoverImage'
import Button from '../Button'
import ClassificationInfo from 'components/ClassificationInfo'
import { Classification, Product } from 'types/product'
import { useUSDPrice } from 'hooks/useUSDPrice'
import { useLocale, useProductPaymentToken, useTimer } from 'hooks'
import { formatCurrency } from 'utils/price'
import { checkAuctionEnded } from 'utils/core'
import { MAX_RESERVE_PRICE } from 'config/constantKey'

export interface ProductPreviewItemProps {
  product: Product
  onSell?: () => void
  onCancelSell?: () => void
  canceling?: boolean
  onViewAuction?: () => void
  isFeature?: boolean
}

const ProductPreviewItem: React.FC<ProductPreviewItemProps> = ({
  product,
  onSell,
  onCancelSell,
  canceling,
  onViewAuction,
  isFeature
}) => {
  const locale = useLocale()
  const fixedPrice = useMemo(() => product.order_fixed_price?.price || product.order_p2p?.price, [product])
  const auctionPrice = useMemo(
    () => product?.order_auction?.bid_highest?.price || product?.order_auction?.price_from,
    [product]
  )
  const paymentToken = useProductPaymentToken(product)
  const usdFixedPrice = useUSDPrice(fixedPrice || 0, paymentToken)
  const usdAuctionPrice = useUSDPrice(auctionPrice || 0, paymentToken)
  const isAuctionEnded = useMemo(() => {
    return checkAuctionEnded(product.order_auction)
  }, [product])

  const detailUrl = useMemo(() => {
    if (product.order_count === 1) {
      if (product.order_fixed_price) {
        return `/orders/${product.order_fixed_price.id}`
      }
      if (product.order_auction && !isAuctionEnded) {
        return `/auctions/${product.order_auction.id}`
      }
      if (product.main_order_id) {
        return `/orders/${product.main_order_id}`
      }
    }

    return `/marketplace-products/${product.id}`
  }, [product, isAuctionEnded])

  const timer = useTimer(product?.order_auction?.expired_at ?? new Date().getTime())

  const renderEdition = useMemo(() => {
    if (product.order_count) {
      if (product.order_count === 1) {
        return (
          <p className='text-xs text-ink-lighter font-medium'>
            <Trans>Single Edition</Trans>
          </p>
        )
      }

      return (
        <p className='text-xs text-ink-lighter font-medium'>
          <Trans>Edition Size</Trans>: {product.order_count}
        </p>
      )
    }
  }, [product])

  return (
    <div className='product-preview h-full'>
      <Link to={detailUrl}>
        <CoverImage
          imageUrl={
            isFeature
              ? product.assets_collections_urls?.featured_collection?.[0] || product.assets_urls?.[1]
              : product.assets_collections_urls?.marketplace_preview?.[0] || product.assets_urls?.[1]
          }
        />
      </Link>

      <div className='product-preview-info'>
        <p className='text-xs text-new-darker'>{locale === 'en' ? product.author_name : product.author_name_jp}</p>
        <p className='product-preview-title truncate py-[0.375rem]'>
          <Link to={detailUrl}>{locale === 'en' ? product.name : product.name_jp}</Link>
        </p>
        <Link to={`/marketplace`}>
          {product.rarity ? (
            <div className='flex justify-between items-center'>
              <div>
                <span className='block text-xs'>
                  <ClassificationInfo classification={product.rarity as Classification} />
                </span>
              </div>

              {renderEdition}
            </div>
          ) : (
            <p className='mt-8'></p>
          )}
        </Link>

        {onViewAuction && (
          <Button className='w-full mt-3 primary' onClick={onViewAuction}>
            View Bids
          </Button>
        )}
        {onSell && (
          <div className='button bg-purple w-full hover:bg-purple-80 popup-sell-item-trigger' onClick={onSell}>
            Sell
          </div>
        )}
        <div className='flex mt-4'>
          <div className='flex-1'>
            <p className='mb-[0.125rem] text-xs text-new-darker'>Buy now</p>
            {fixedPrice && fixedPrice !== MAX_RESERVE_PRICE ? (
              <>
                <div className='flex items-center'>
                  <img src={`/images/tokens/${paymentToken}.png`} alt='payment logo' className='w-5 h-5' />
                  <p className='ml-[6px] font-medium'>{formatCurrency(round(fixedPrice || 0, 2))}</p>
                </div>
                <p className='ml-[2px] text-xs text-new-darker'>~${formatCurrency(round(usdFixedPrice, 2))}</p>
              </>
            ) : (
              <p className='h-[2.7rem]'>--</p>
            )}
          </div>

          {!!auctionPrice && <div className='w-px h-14 bg-sky-light mx-3'></div>}
          {!!auctionPrice && (
            <div className='flex-1'>
              <p className='mb-[0.125rem] text-xs text-new-darker'>
                <Trans>Bid</Trans>
              </p>

              <div className='flex items-center'>
                <img src={`/images/tokens/${paymentToken}.png`} alt='payment logo' className='w-5 h-5' />
                <p className='ml-1 font-medium'>{formatCurrency(round(auctionPrice || 0, 2))}</p>
              </div>
              <p className='ml-[2px] text-xs text-new-darker'>~${formatCurrency(round(usdAuctionPrice, 2))}</p>
              <p className='mt-[2px] text-xs text-new-darker'>
                {isAuctionEnded
                  ? 'Auction Ended'
                  : `Ends in: ${timer.days}d ${('0' + timer?.hours).slice(-2)}h ${('0' + timer?.minutes).slice(-2)}m`}
              </p>
            </div>
          )}
        </div>

        {onCancelSell && (
          <Button className='mt-3 bg-red-300 w-full hover:bg-red-200' onClick={onCancelSell} isLoading={canceling}>
            Cancel Sell
          </Button>
        )}
      </div>
    </div>
  )
}

export default ProductPreviewItem
