import React, { useState } from 'react'
import { t, Trans } from '@lingui/macro'

import { Tab } from 'components'
import SignUpWithEmail from './SignUpWithEmail'
import SignUpWithPhone from './SignUpWithPhone'
import PasswordForm from './PasswordForm'
import OTPForm from './OTPForm'

import logo from 'assets/images/logo-black.png'

type SignUpProps = {
  onBack: () => void
  onSuccess: () => void
  onNext: () => void
}

const SignUp = ({ onBack, onSuccess, onNext }: SignUpProps) => {
  const [tab, setTab] = useState('email')
  const [next, setNext] = useState(false)
  const [value, setValue] = useState('')

  const handleBack = () => {
    if (!next) return onBack()
    return setNext(false)
  }

  const handleNext = (value: string) => {
    setNext(true)
    setValue(value)
    onNext()
  }

  const renderForm = () => {
    if (tab === 'email') {
      if (!next) return <SignUpWithEmail onNext={handleNext} />
      return <PasswordForm email={value} onSuccess={onSuccess} />
    }
    if (!next) return <SignUpWithPhone onNext={handleNext} />
    return <OTPForm phoneNumber={value} onSuccess={onSuccess} />
  }

  return (
    <>
      <div>
        <svg className='fill-current w-5 h-5 mr-3 cursor-pointer' onClick={handleBack}>
          <use xlinkHref='#svg-left-arrow'></use>
        </svg>
        <span className='text-base font-medium'>
          <Trans>Back</Trans>
        </span>
      </div>
      <img src={logo} className='w-[120px] sm:w-[166px] h-[1.125rem] sm:h-6 mt-9' alt='logo' />
      <h4 className='mt-9'>
        <Trans>New to the Trophee Community?</Trans>
      </h4>
      <p className='mt-2 text-ink-lighter'>
        {next ? (
          <Trans>For the last step, please setup a secure password for your account.</Trans>
        ) : (
          <Trans>Please provide your email address or phone number to create your Trophee account.</Trans>
        )}
      </p>
      {!next && (
        <div className='flex border-b-[1px] border-t-0 border-r-0 border-l-0 border-sky-light border-solid pb-2 my-4'>
          <Tab key='email' title={t`Sign up with Email`} active={tab === 'email'} onChange={() => setTab('email')} />
          <Tab
            key='phoneNumber'
            title={t`Sign up with Mobile`}
            active={tab === 'phoneNumber'}
            onChange={() => setTab('phoneNumber')}
          />
        </div>
      )}
      <div>{renderForm()}</div>
    </>
  )
}

export default SignUp
