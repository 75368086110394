import React, { useState, useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'

import { useInfiniteBridgeOrder } from 'hooks'
import { useSignature } from 'store/user/hook'

import {
  ReceiveItem,
  ListWrapper,
  Loader,
  NoData,
  BridgeActionLoading,
  BridgeActionSuccess,
  BridgeActionFailure
} from 'components'
import { ercChainIds } from 'utils/metamask'

import nodata from 'assets/images/no-data.png'
import { tropheeETHUrl, viewDetailSignatureKey } from 'config/constantKey'
import { Tab } from 'container/Bridge'
import { Trans } from '@lingui/macro'
import { TransactionStatus } from 'components/Bridge/List'

const List: React.FC<{
  onSwitchTab?: (type: Tab) => void
}> = ({ onSwitchTab }) => {
  const { account, chainId } = useWeb3React()
  const { showOrders, isLoading, isEnd, ref, hideRefElement, invalidate } = useInfiniteBridgeOrder({
    owner_id: account?.toLowerCase()
  })
  const [signature] = useSignature(viewDetailSignatureKey)

  const [transferLoading, setTransferLoading] = useState(false)
  const [transferStatus, setTransferStatus] = useState<TransactionStatus>()

  const setLoading = useCallback((loading: boolean) => setTransferLoading(loading), [])
  const setStatus = useCallback((status: TransactionStatus | undefined) => setTransferStatus(status), [])

  if (!chainId) {
    return (
      <div className='py-20 border border-sky-light rounded-b shadow-xl'>
        <NoData />
      </div>
    )
  }

  if (!ercChainIds.includes(chainId)) {
    return (
      <div className='py-20 text-center border border-sky-light rounded-b shadow-xl'>
        <img className='w-[200px] h-[200px]' src={nodata} alt='no-data' />
        <p className='mx-auto text-xl'>Please switch to Ethereum Network to see your transfered NFT</p>
      </div>
    )
  }

  if (!signature) {
    return (
      <div className='py-20 text-center border border-sky-light rounded-b shadow-xl'>
        <img className='w-[200px] h-[200px]' src={nodata} alt='no-data' />
        <p className=' w-[20rem] mx-auto text-xl'>
          Please sign a message to prove that you are an owner of this wallet and able to see your transfered NFT
        </p>
      </div>
    )
  }

  return (
    <div className='pb-20 border border-sky-light rounded-b shadow-xl'>
      <div className='flex flex-wrap justify-center md:justify-between items-center p-8 bg-sky-lightest border-[0px] border-b-[1px] border-sky-light border-solid'>
        <h4 className='text-new-darker font-light text-xl'>
          <Trans>Select NFT and Claim</Trans>
        </h4>
      </div>

      {transferLoading && (
        <BridgeActionLoading
          title='Claim in Progress'
          description='Claiming NFT to Trophee ETH '
          tip='Confirm this transaction in your wallet'
        />
      )}
      {transferStatus?.status === 'success' && (
        <BridgeActionSuccess
          title='NFT claimed successfully'
          actionTitle='Go to Trophee ETH'
          onAction={() => (window.location.href = tropheeETHUrl)}
        />
      )}
      {transferStatus?.status === 'failure' && (
        <BridgeActionFailure
          title={`Transfer failed: ${transferStatus.message}`}
          actionTitle='Try again'
          onAction={() => setStatus(undefined)}
        />
      )}

      {!transferLoading && !transferStatus && (
        <div className='mt-3 px-8'>
          <ListWrapper isLoading={isLoading && !showOrders.length} isNoData={showOrders.length === 0}>
            {showOrders.map(order => (
              <ReceiveItem
                key={order.id}
                detail={order}
                onReceived={invalidate}
                onLoading={setLoading}
                onSettled={setStatus}
              />
            ))}
          </ListWrapper>
          {!hideRefElement && !isEnd && showOrders.length > 0 && (
            <div ref={ref}>
              <Loader />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default List
