import React, { HTMLProps } from 'react'
import clsx from 'classnames'

export interface CheckboxProps extends HTMLProps<HTMLInputElement> {
  labelClass?: string
}

const Checkbox: React.FC<CheckboxProps> = ({ id, label, labelClass, ...props }) => {
  return (
    <label className="flex items-center my-3">
      <input type="checkbox" className="option-input" {...props} />
      <span className={clsx('mt-[1px]', labelClass)}>{label}</span>
    </label>
  )
}

export default Checkbox
