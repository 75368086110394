import React, { useState, useCallback, useEffect } from 'react'
import { omit } from 'lodash'
import { ProductFilterQuery } from 'types/product'

import FilterType from './FilterType'
import FilterClass from './FilterClass'
import FilterPrice from './FilterPrice'

export interface FilterSidebarProps {
  onToggle: () => void
  open: boolean
  filter: ProductFilterQuery
  setFilter: (filter: ProductFilterQuery) => void
}

const FilterSidebar: React.FC<FilterSidebarProps> = ({ filter, setFilter, onToggle }) => {
  const [type, setType] = useState(filter?.type || 0)
  const [rarity, setRarity] = useState(filter.rarity)

  const handleApply = () => {
    setFilter({ ...omit(filter, ['price_from', 'price_to']), type, rarity })
  }

  const handlSetPrice = useCallback(
    (filterPrice: ProductFilterQuery) => {
      setFilter(filterPrice)
    },
    [setFilter]
  )

  useEffect(() => {
    setType(filter?.type || 0)
    setRarity(filter.rarity)
  }, [filter])

  return (
    <div
      className='w-full h-[calc(100vh-60px)] sm:h-[calc(100vh-80px)] py-8 sm:py-6 px-6 bg-white overflow-y-auto'
      style={{
        borderRight: '1px solid #E6E8EC'
      }}
    >
      <div className='flex justify-between pt-2 pb-4 border-b'>
        <div className='flex items-center'>
          <img src='/images/marketplace/filter.png' alt='filter icon' className='w-5 h-5' />
          <p className='ml-1'>Filter</p>
        </div>
        <img
          src='/images/marketplace/sidebar-close.png'
          alt='filter close icon'
          className='w-5 h-4 cursor-pointer'
          onClick={onToggle}
        />
      </div>

      <FilterType type={type} onChange={setType} />
      <FilterClass rarity={rarity} setRarity={setRarity} />
      <button className='mb-3 py-3 px-4 bg-new-primary font-medium text-white rounded' onClick={handleApply}>
        Apply
      </button>

      <FilterPrice
        price={{
          price_from: filter.price_from,
          price_to: filter.price_to
        }}
        onSetPrice={handlSetPrice}
      />
    </div>
  )
}

export default FilterSidebar
