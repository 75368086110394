import { useState, useEffect, useMemo } from 'react'
import { useWeb3React } from '@web3-react/core'
import { Contract } from '@ethersproject/contracts'
import { Web3Provider, JsonRpcProvider } from '@ethersproject/providers'

import ERC20_ABI from 'config/abis/erc20Abi.json'

export const networkProvider = new JsonRpcProvider(process.env.REACT_APP_NETWORK_URL)

export const getSignerOrProvider = (library: Web3Provider, account: string | null | undefined) => {
  return account ? (library as Web3Provider).getSigner(account) : library || networkProvider
}

export const useContract = (address: string | undefined, ABI: any, withSignerIfPossible = true) => {
  const { library, account } = useWeb3React()
  return useMemo(() => {
    if (!address || !ABI) {
      return null
    }

    try {
      if (!withSignerIfPossible) {
        return new Contract(address, ABI, networkProvider)
      }

      if (!library) {
        return null
      }

      return new Contract(address, ABI, getSignerOrProvider(library, account))
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, account, library, ABI, withSignerIfPossible])
}

export const useTokenContract = (tokenAddress: string, withSignerIfPossible = true): Contract | null => {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export const useDecimal = (tokenContract: Contract | null): number | undefined => {
  const [decimal, setDecimal] = useState<number>()
  useEffect(() => {
    const getDecimal = async () => {
      try {
        const dec = await tokenContract?.decimals()
        setDecimal(dec)
      } catch {
        setDecimal(undefined)
      }
    }

    if (tokenContract) {
      getDecimal()
    }
  }, [tokenContract])

  return decimal
}

export const useSymbol = (tokenContract: Contract | null): string | undefined => {
  const [symbol, setSymbol] = useState<string>()
  useEffect(() => {
    const getSymbol = async () => {
      try {
        const sym = await tokenContract?.symbol()
        setSymbol(sym)
      } catch {
        setSymbol(undefined)
      }
    }

    if (tokenContract) {
      getSymbol()
    }
  }, [tokenContract])

  return symbol
}

export const useProxy = (proxyContract: Contract | null, account?: string | null) => {
  const [proxy, setProxy] = useState()

  useEffect(() => {
    const getProxy = async () => {
      const proxy = await proxyContract?.proxies(account)
      if (parseInt(proxy) !== 0) {
        setProxy(proxy)
        return
      }
    }

    if (proxyContract && account) {
      getProxy()
    }
  }, [proxyContract, account])

  return proxy
}
