import { Classification, Product, ProductMetadata, ProductOrder } from './product'

export interface OrderRequest {
  tokenId: string
  tokenAddress: string
  customerAddress: string
}

export interface OrderRequestResponse {
  NFTId: string
  createdAt: number
  customerAddress: string
  id: string
  status: number
  tokenAddress: string
  tokenId: string
  order: ProductOrder
  authorName: string
  authorAvatarUrl: string
  _type?: 'fixed_price' | 'p2p'
}

export interface P2POrder extends OrderRequestResponse {
  nft_data: ProductMetadata
  maxPrice?: number
  minPrice?: number
}

export interface Bid {
  id: string
  order: ProductOrder
  orderId: string
  orderOwner: string
  owner: string
  status: number
  createdAt: number
  updatedAt: number
}

export interface WyvernOrder {
  target: string
  staticTarget: string
  extra: string
  makerRelayerFee: string
  feeRecipient: string
  feeMethod: number
  maker: string
  side: number
  staticExtradata: string
  saleKind: number
  paymentToken: string
  replacementPattern: string
  metadata: {
    asset: {
      address: string
      id: string
      quantity?: string
    }
    schema: string
  }
  basePrice: string
  taker: string
  hash: string
  calldata: string
  salt: string
  howToCall: number
  listingTime: string
  exchange: string
  expirationTime: string
  quantity: string
  takerProtocolFee: string
  waitingForBestCounterOrder: boolean
  makerProtocolFee: string
  takerRelayerFee: string
  makerReferrerFee: string
  message?: string
  messageHash?: string
  v?: number | string
  r?: string
  s?: string
  signature?: string
}

export enum OrderType {
  FixedPrice = 1,
  P2P = 2,
  Auction = 3
}

export interface NFTOrder {
  asset_nft_url?: string
  product_id: string
  product: Product
  created_at: number
  name_jp: string
  name: string
  contract_id: number
  updated_at: number
  contract_address: string
  type: OrderType
  owner_id: string
  author_name_jp: string
  author_name: string
  deleted_at: number
  expired_at?: number
  price: number
  price_from?: number
  seller_id: string
  serial: string
  edition_number: number
  id: string
  order: WyvernOrder
  status: number
  product_rarity: Classification
  _membership_requirement?: boolean
  bid_highest?: {
    id: string
    auction_id: string
    auction_seller_id: string
    buyer_id: string
    price: number
    created_at: number
    deleted_at: number
    updated_at: number
    won_at: number
  }
  tags?: string[]
  inscription?: string
  _search?: string[]
}

export interface BidOrder {
  id: string
  buyer_id?: string
  auction_id?: string
  auction_seller_id?: string
  price?: number
  order?: WyvernOrder
  won_at?: number

  deleted_at?: number
  created_at?: number
  updated_at?: number
}

export interface BridgeOrder {
  id: string
  src: {
    token_id: string
    token_address: string
    name: string
    payment_token: string
  }
  owner_id: string
  deleted_at?: number
  created_at?: number
  seller_id: string
  dest: {
    token_id: string
    token_address: string
    name: string
    payment_token: string
  }
  order: WyvernOrder
  product?: Product
}
