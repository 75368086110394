import React, { useCallback, useState } from 'react'
import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'

import Transfer from './Transfer'
import {
  BridgeItem,
  BridgeActionLoading,
  ConnectWalletButton,
  ListWrapper,
  Loader,
  BridgeActionSuccess,
  BridgeActionFailure
} from 'components'
import { useInfiniteCollections } from 'hooks'
import { NFTOrder } from 'types/order'
import { Tab } from 'container/Bridge'

export interface TransactionStatus {
  status: 'success' | 'failure'
  message: string
}

const List: React.FC<{
  onSwitchTab: (type: Tab) => void
}> = ({ onSwitchTab }) => {
  const [selectedItem, setSelectedItem] = useState<NFTOrder>()
  const { account } = useWeb3React()
  const { showOrders, isLoading, isEnd, ref, hideRefElement, invalidate } = useInfiniteCollections(account, {
    owner_id: account?.toLowerCase(),
    status: 1
  })
  const [transferLoading, setTransferLoading] = useState(false)
  const [transferStatus, setTransferStatus] = useState<TransactionStatus>()

  const setLoading = useCallback((loading: boolean) => setTransferLoading(loading), [])
  const setStatus = useCallback((status: TransactionStatus | undefined) => setTransferStatus(status), [])

  return (
    <div className='pb-10'>
      <div className='border border-sky-light rounded-b shadow-xl'>
        <div className='flex flex-wrap justify-center md:justify-between items-center py-4 px-8 bg-sky-lightest border-[0px] border-b-[1px] border-sky-light border-solid'>
          <h4 className='text-new-darker font-light text-xl'>
            <Trans>Select NFTs to Transfer</Trans>
          </h4>
          <div className='mt-2 md:mt-0'>
            {account ? (
              !transferLoading &&
              !transferStatus && (
                <div className='flex justify-end gap-x-4'>
                  <Transfer
                    order={selectedItem}
                    onTransfered={invalidate}
                    onLoading={setLoading}
                    onSettled={setStatus}
                  />
                </div>
              )
            ) : (
              <div className='flex w-[fit-content] my-0 ml-auto'>
                <ConnectWalletButton requireLogin={false} />
              </div>
            )}
          </div>
        </div>

        {transferLoading && (
          <BridgeActionLoading
            title='Transfer in Progress'
            description='Transferring NFT from Trophee BSC'
            tip='Confirm this transaction in your wallet'
          />
        )}
        {transferStatus?.status === 'success' && (
          <BridgeActionSuccess
            title='NFT transferred successfully'
            actionTitle='Continue to Claim on ETH'
            onAction={() => onSwitchTab(Tab.Receive)}
          />
        )}
        {transferStatus?.status === 'failure' && (
          <BridgeActionFailure
            title={`Transfer failed: ${transferStatus.message}`}
            actionTitle='Try again'
            onAction={() => setStatus(undefined)}
          />
        )}

        {!transferLoading && !transferStatus && (
          <div className='p-4'>
            <ListWrapper isLoading={isLoading && !showOrders.length} isNoData={showOrders.length === 0}>
              {showOrders.map(order => (
                <BridgeItem
                  key={order.id}
                  order={order}
                  isSelected={selectedItem?.id === order.id}
                  setSelectedItem={setSelectedItem}
                />
              ))}
            </ListWrapper>
            {!hideRefElement && !isEnd && showOrders.length > 0 && (
              <div ref={ref}>
                <Loader />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default List
