export enum Status {
  Upcoming = 'upcoming',
  ActiveNow = 'active',
  GiveAway = 'giveaway',
  SoldOut = 'sold_out',
  Ended = 'ended'
}

export type Art = {
  title: string
  image: string
  content: string
  status?: Status
  date?: number
  releaseDetail?: {
    edition: string
    type: string
    date: {
      from: string
      to: string
    }
  }
  auctionId?: string
  orderId?: string
  productId?: string
}

export type FeaturedCollection = {
  background: string
  avatar: string
  name: string
  title: string
  content: string
}
