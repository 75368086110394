import { useEffect } from 'react'
import { Web3Provider } from '@ethersproject/providers'
import { BigNumber } from '@ethersproject/bignumber'
import { useWeb3React } from '@web3-react/core'
import { useQuery } from 'react-query'

import { useProducts } from 'queries/product'
import { ProductMetadata } from 'types/product'
import { useSignMessage } from './useSignMessage'
import { useSignature } from 'store/user/hook'
import { NFTOrder } from 'types/order'

import { validateSignature } from 'apis/signature'
import ERC1155ABI from 'config/abis/erc1155Abi.json'
import multicall from 'utils/multicall'

export const myOrdersQueryKey = (account?: string | null, signature?: string) =>
  `@manga_token/queries/myOrders/${account}/${signature}`

const getMyOrders =
  (message: string, products: ProductMetadata[], signature?: string, library?: Web3Provider, account?: string | null) =>
  async () => {
    const promises: Promise<ProductMetadata>[] = []

    if (account && library && signature) {
      const calls = products.map(product => {
        return {
          address: product.tokenAddress,
          name: 'balanceOf',
          params: [account, product.tokenId]
        }
      })

      const balances = await multicall(ERC1155ABI, calls)

      const formatBalances = balances.map((b: BigNumber[]) => b[0].toString())
      const myProducts = products.filter((_, index) => {
        return +formatBalances[index] > 0
      })

      myProducts.forEach(product => {
        promises.push(
          validateSignature({
            id: product.id,
            msg: message,
            sig: signature
          })
        )
      })

      const productsNoWatermark = await Promise.all(promises)

      return productsNoWatermark
    }

    return []
  }

export const useMyOrders = (message: string) => {
  const { account, library } = useWeb3React()
  const { data: allProducts } = useProducts()

  const signMessage = useSignMessage(message)
  const [signature] = useSignature(message)

  useEffect(() => {
    const requestSign = async () => {
      if (!signature) {
        await signMessage()
      }
    }

    requestSign()
  }, [signature, signMessage])

  return useQuery<ProductMetadata[]>(
    myOrdersQueryKey(account, signature),
    getMyOrders(message, allProducts?.data || [], signature, library, account),
    {
      enabled: !!account && !!library && !!allProducts
    }
  )
}

export const useIsOwner = (order?: NFTOrder) => {
  const { account } = useWeb3React()
  if (account && order) {
    const lowercaseAccount = account.toLowerCase()

    return lowercaseAccount === order.seller_id.toLowerCase() || lowercaseAccount === order.owner_id.toLowerCase()
  }

  return false
}
