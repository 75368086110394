import { useState, useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'
import round from 'lodash/round'

import { getUSDPrice } from 'utils/price'
import { MANGA_ADDRESS } from 'config/constantKey'

export const useUSDPrice = (basePrice: number, address: string): number => {
  let compareAddress = useMemo(() => address || MANGA_ADDRESS, [address])

  const [usdPrice, setUSDPrice] = useState(0)
  const { data } = useQuery(address + '/usd', () => getUSDPrice(compareAddress), {
    staleTime: 12000,
    cacheTime: 18000
  })

  useEffect(() => {
    if (compareAddress === '0xe9e7cea3dedca5984780bafc599bd69add087d56') {
      setUSDPrice(1 * basePrice)
      return
    }
    if (data && data[compareAddress]?.usd) {
      setUSDPrice(round(basePrice * data[compareAddress].usd, 2))
    }
  }, [data, basePrice, compareAddress])

  return usdPrice
}
