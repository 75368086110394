import React, { useCallback, useState } from 'react'
import clsx from 'classnames'

import { Banner, BridgeList, Layout, ReceiveList } from 'components'

export enum Tab {
  Bridge,
  Receive
}

const Bridge = () => {
  const [tab, setTab] = useState<Tab>(Tab.Bridge)

  const handleChangeTab = (item: Tab) => () => {
    setTab(item)
  }

  const onSwitchTab = useCallback((item: Tab) => {
    setTab(item)
  }, [])

  return (
    <Layout>
      <Banner title='BSC to ETH Bridge' description='Exclusively for Trophee NFTs' />
      <div className='w-full mt-9 py-3 bg-sky-lightest'>
        <div className='mx-auto w-full md:max-w-[580px]'>
          <div>
            <h4>WARNING</h4>
            <p className='text-ink-lighter leading-8'>
              Please ensure you have enough gas on both BSC and ETH to complete the process. Gas costs may vary
              depending on time of transaction.
            </p>
          </div>
          <div className='mt-6'>
            <h4>Walkthrough</h4>
            <ol type='1' className='!ml-6 text-ink-lighter !list-decimal'>
              <li className='leading-8'>Ensure your wallet is on the BSC network</li>
              <li className='leading-8'>Select the NFT you want to transfer from Trophee BSC to Trophee ETH</li>
              <li className='leading-8'>Click "Transfer" and Sign (BSC Gas Fees Required)</li>
              <li className='leading-8'>Wait for confirmation of the Transfer</li>
              <li className='leading-8'>Switch your wallet to the ETH network</li>
              <li className='leading-8'>Sign to verify your wallet address for claiming (No Gas Fees)</li>
              <li className='leading-8'>
                Click “Claim” on the NFT you want to receive and Sign (ETH Gas Fees Required)
              </li>
              <li className='leading-8'>Wait for confirmation of the Claim</li>
              <li className='leading-8'>Transfer Complete</li>
            </ol>
          </div>
        </div>
      </div>

      <div className='flex justify-evenly mt-4 py-3 text-lg lg:text-xl font-medium border border-sky-light !border-b-0'>
        <div
          className={clsx('border-new-primary cursor-pointer', tab === Tab.Bridge ? 'border-b-2' : 'border-0')}
          onClick={handleChangeTab(Tab.Bridge)}
        >
          Transfer from BSC
        </div>
        <div className='relative h-8 w-[1px] mb-2 bg-[#E6E8EC]'></div>
        <div
          className={clsx('border-new-primary cursor-pointer', tab === Tab.Receive ? 'border-b-2' : 'border-0')}
          onClick={handleChangeTab(Tab.Receive)}
        >
          Claim on ETH
        </div>
      </div>

      {tab === Tab.Bridge && <BridgeList onSwitchTab={onSwitchTab} />}
      {tab === Tab.Receive && <ReceiveList onSwitchTab={onSwitchTab} />}
    </Layout>
  )
}

export default Bridge
