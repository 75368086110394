import React, { useState, useRef } from 'react'
import { t, Trans } from '@lingui/macro'
import { useForm, Controller } from 'react-hook-form'
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input'
import en from 'react-phone-number-input/locale/en.json'
import { isEmpty } from 'lodash'
import { signInWithPhoneNumber, RecaptchaVerifier, PhoneAuthProvider, signInWithCredential } from 'firebase/auth'

import { auth } from 'libs/firebase'
import { ACCESS_TOKEN_KEY } from 'config/constantKey'

import { Input, Button, Select } from 'components'
import { toastSuccess } from 'utils/toast'

type LoginWithPhonenumberProps = {
  onSuccess: () => void
}

type FormData = {
  phoneCode: Option
  phoneNumber: string
  otp: string
}

type Option = {
  value: string
  label: string
}

const countries = getCountries().map(country => ({
  label: en[country],
  value: country
}))

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    height: 54,
    minHeight: 54
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  menu: (base: any) => ({ ...base, zIndex: 20, width: 300 })
}

const LoginWithPhonenumber = ({ onSuccess }: LoginWithPhonenumberProps) => {
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isVerifing, setIsVerifing] = useState(false)
  const recaptchaWrapperRef = useRef<HTMLDivElement>(null)

  const { control, formState, handleSubmit, watch } = useForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      phoneCode: countries[0]
    }
  })
  const phoneNumber = watch('phoneNumber')
  const phoneCode = watch('phoneCode')
  const { errors } = formState

  const handleLogin = (values: FormData) => {
    if (!window.confirmationResult) {
      return setError(t`Phone Number is not verified`)
    }
    return window.confirmationResult
      .confirm(values.otp)
      .then(() => {
        const credential = PhoneAuthProvider.credential(window.confirmationResult.verificationId, values.otp)
        setIsLoading(true)
        signInWithCredential(auth, credential)
          .then(async userCredential => {
            const token = await userCredential.user.getIdToken()
            if (token) {
              localStorage.setItem(ACCESS_TOKEN_KEY, token)
              onSuccess()
              toastSuccess(
                <div>
                  Success!
                  <p className='mt-1'>Login Successfully !</p>
                </div>
              )
            }
          })
          .catch((error: any) => {
            const errorMessage = error.message
            setError(errorMessage)
          })
          .finally(() => setIsLoading(false))
      })
      .catch((error: any) => {
        const errorMessage = error.message
        setError(errorMessage)
      })
  }

  const handleVerify = () => {
    setIsVerifing(false)

    if (window.recaptchaVerifier && recaptchaWrapperRef.current) {
      window.recaptchaVerifier.clear()
      recaptchaWrapperRef.current.innerHTML = `<div id="recaptcha-container-login"></div>`
    }

    window.recaptchaVerifier = new RecaptchaVerifier(
      'recaptcha-container-login',
      {
        size: 'invisible',
        callback: () => {}
      },
      auth
    )

    const phone = `+${getCountryCallingCode((phoneCode?.value as any) || 'SG')}${phoneNumber}`
    signInWithPhoneNumber(auth, phone, window.recaptchaVerifier)
      .then(confirmationResult => {
        window.confirmationResult = confirmationResult
      })
      .catch(error => {
        const errorMessage = error.message
        setError(errorMessage)
      })
      .finally(() => setIsVerifing(false))
  }

  const CustomOption = ({ data, innerProps }: any) => {
    return (
      <div {...innerProps} className='flex justify-between m-3 cursor-pointer'>
        <div className='flex'>
          <img
            className='w-8 mr-3'
            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${data.value}.svg`}
            alt={data.label}
          />
          <p>{data.label}</p>
        </div>
        <p>+{getCountryCallingCode(data.value)}</p>
      </div>
    )
  }

  const CustomSingleValue = ({ data }: any) => {
    return (
      <div className='flex'>
        <img
          className='w-8 mr-3'
          src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${data.value}.svg`}
          alt={data.label}
        />
        <p>+{getCountryCallingCode(data.value)}</p>
      </div>
    )
  }

  return (
    <div className='mb-3'>
      <form onSubmit={handleSubmit(handleLogin)}>
        <div className='mb-3'>
          <div className='flex flex-wrap mb-2'>
            <div className='md:w-[150px] w-full md:mr-[1rem] mr-[0px] mb-[1rem] md:mb-0'>
              <Controller
                control={control}
                name='phoneCode'
                render={({ field }) => (
                  <Select
                    {...field}
                    options={countries}
                    components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
                    styles={customStyles}
                  />
                )}
                rules={{
                  required: 'Phone Code is required'
                }}
              />
            </div>
            <div className='relative flex-1'>
              <Controller
                render={({ field }) => <Input placeholder={t`Phone Number`} {...field} errors={errors} />}
                control={control}
                name='phoneNumber'
                rules={{
                  required: t`Phone Number is required`,
                  pattern: {
                    value: /^\d+$/,
                    message: 'Invalid Phone Number'
                  }
                }}
              />
              <span
                className='absolute text-new-primary cursor-pointer top-[17px] right-2'
                onClick={() => (isVerifing || isEmpty(errors)) && handleVerify()}
                id='btn-verify'
              >
                <Trans>Verify</Trans>
              </span>
            </div>
          </div>
          <div>
            <Controller
              render={({ field }) => <Input placeholder={t`OTP`} {...field} errors={errors} />}
              control={control}
              name='otp'
              rules={{
                required: t`OTP is required`
              }}
            />
          </div>
          {error && <div className='text-red-500 mt-2'>{error}</div>}
        </div>
        <Button className='w-full' type='submit' isLoading={isLoading} disabled={isLoading}>
          <Trans>Log In</Trans>
        </Button>
      </form>
      <div ref={recaptchaWrapperRef}>
        <div id='recaptcha-container-login'></div>
      </div>
    </div>
  )
}

export default LoginWithPhonenumber
