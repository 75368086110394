import { useEffect } from 'react'
import { Web3ReactProvider } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import { HelmetProvider } from 'react-helmet-async'
import { QueryClient, QueryClientProvider, QueryFunctionContext } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import axios from 'axios'
import SimpleReactLightbox from 'simple-react-lightbox'
import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import { en, ja } from 'make-plural/plurals'
import { ToastContainer, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import './index.css'

import Routes from './routes'
import { messages as enMessages } from './locales/en/messages'
import { messages as jaMessages } from './locales/ja/messages'

import './config/axios'
import './utils/svgLoader'
import './libs/firebase'

const getLibrary = (provider: any): Web3Provider => {
  const library = new Web3Provider(provider)
  library.pollingInterval = 12000
  return library
}

// Create a client
const defaultQueryFn = async ({ queryKey }: QueryFunctionContext) => {
  const { data } = await axios.get(queryKey[0] as string)
  return data
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
      retry: 2,
      refetchOnWindowFocus: false
    }
  }
})

function App() {
  useEffect(() => {
    i18n.loadLocaleData({
      en: { plurals: en },
      ja: { plurals: ja }
    })
    i18n.load({
      en: enMessages,
      ja: jaMessages
    })
    i18n.activate('en')
  }, [])

  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          <SimpleReactLightbox>
            <I18nProvider i18n={i18n}>
              <Routes />
            </I18nProvider>
            <ToastContainer
              position='top-right'
              autoClose={2000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              transition={Slide}
            />
          </SimpleReactLightbox>
        </HelmetProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Web3ReactProvider>
  )
}

export default App
