import { SupportedLanguage } from 'components/SwitchLanguage'
import { Product } from 'types/product'

export const getLocalizedDetail =
  ({ detail, locale }: { detail: Product; locale: SupportedLanguage }) =>
  (field: 'name' | 'authorName' | 'description') => {
    if (field === 'name') {
      return locale === 'ja' ? detail.name_jp : detail.name
    }

    if (field === 'authorName') {
      return locale === 'ja' ? detail.author_name_jp : detail.author_name
    }

    if (field === 'description') {
      return locale === 'ja' ? detail.description_jp : detail.description
    }

    return ''
  }
