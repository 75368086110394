import React from 'react'
import { Link } from 'react-router-dom'
import { Trans } from '@lingui/macro'

import { Button } from 'components'

type CarouselItemProps = {
  images: string[]
  author: string
  title: string
}

const CarouselItem = ({ images, author, title }: CarouselItemProps) => {
  const gridClass = `grid-cols-${images.length}`
  return (
    <div className='relative'>
      <div className={`h-[498px] grid gap-0 mt-0 ${gridClass}`}>
        {images.map((item, index) => (
          <img key={index} src={item} alt={item} className='h-[498px] w-full object-cover' />
        ))}
      </div>
      <div className='absolute inset-0 flex justify-center items-center bg-black bg-opacity-50'>
        <div className='text-center'>
          <p className='text-white uppercase'>
            <Trans>Featured Collection</Trans>
          </p>
          <h1 className='text-white md:text-6xl font-medium my-4'>
            <Trans id={title} />
          </h1>
          <p className='text-white text-xl mb-4'>
            <Trans>by:</Trans> <Trans id={author} />
          </p>
          <Link to='/features'>
            <Button>
              <Trans>View Collection</Trans>
            </Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default CarouselItem
