import BigNumber from 'bignumber.js'
import axios from 'axios'

export const formatTokenPrice = (price: string | number, decimal = 18) => {
  return new BigNumber(price).div(10 ** decimal).toString()
}

export const getUSDPrice = async (tokenAddress: string) => {
  const address = '0xc2cb89bbb5bba6e21db1dfe13493dfd7dcbabd68'
  const res = await axios.get(
    `https://api.coingecko.com/api/v3/simple/token_price/binance-smart-chain?contract_addresses=${address}&vs_currencies=usd`
  )

  return res.data
}

export const formatCurrency = (price: number) => {
  return new Intl.NumberFormat('en-US').format(price)
}
