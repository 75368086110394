import React, { useMemo, useState } from 'react'
import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'

import { Button } from 'components'
import { ToastSuccess } from 'components/ToastMessage'
import { useContract } from 'hooks'
import { NFTOrder } from 'types/order'

import { toastSuccess } from 'utils/toast'
import ERC1155_ABI from 'config/abis/erc1155Abi.json'
import { TransactionStatus } from './List'
import { ercChainIds } from 'utils/metamask'

export interface TransferProps {
  order: NFTOrder | undefined
  onTransfered?: () => Promise<void>
  onLoading: (loading: boolean) => void
  onSettled: (status: TransactionStatus) => void
}

const Transfer: React.FC<TransferProps> = ({ order, onTransfered, onLoading, onSettled }) => {
  const { account, chainId } = useWeb3React()
  const contract = useContract(order?.order?.metadata?.asset?.address, ERC1155_ABI)

  const isERCChain = useMemo(() => chainId && ercChainIds.includes(chainId), [chainId])

  const [loading, setLoading] = useState(false)

  const handleTransfer = async () => {
    try {
      onLoading(true)
      setLoading(true)
      const desWallet = process.env.REACT_APP_AUTHOR_ADDRESS
      const tx = await contract?.safeTransferFrom(account, desWallet, order?.order?.metadata?.asset?.id, 1, '0x', {
        gasLimit: 100000
      })
      await tx.wait()
      if (onTransfered) {
        await onTransfered()

        // call after toast 3s
        setTimeout(() => {
          onTransfered()
        }, 3000)
      }

      onSettled({
        status: 'success',
        message: tx.hash
      })
      toastSuccess(<ToastSuccess header='Transfer Success with tx' message={tx.hash} tx={tx.hash} />)
    } catch (error: any) {
      onSettled({
        status: 'failure',
        message: error?.message ? error.message : error
      })
      console.error('transfer error', error)
    } finally {
      onLoading(false)
      setLoading(false)
    }
  }

  return (
    <Button disabled={!order || !contract || loading || !!isERCChain} onClick={handleTransfer}>
      <Trans>{isERCChain ? 'Wrong Network' : 'Transfer Asset'}</Trans>
    </Button>
  )
}

export default Transfer
