import React from 'react'
import { Trans } from '@lingui/macro'
import { Link } from 'react-router-dom'

import { useTermAndPolicyPopup } from 'store/popup/hook'
import { Button } from 'components'
import { useModal } from 'hooks'

const TermAndPolicyModal = () => {
  const [termPopup, setTermAndPolicyPopup] = useTermAndPolicyPopup()

  useModal({
    container: '.popup-term-and-policy',
    trigger: '.popup-close-term-and-policy',
    setAtom: setTermAndPolicyPopup
  })

  return (
    <div className='popup-box small popup-term-and-policy'>
      <div className='popup-close-term-and-policy'>
        <svg className='popup-close-button-icon icon-cross'>
          <use xlinkHref='#svg-cross'></use>
        </svg>
      </div>

      <div className='popup-box-body flex flex-col p-8'>
        <h3 className='mt-4 text-center'>
          <Trans>Confirmation</Trans>
        </h3>
        <p className='my-4 text-center'>
          <Trans>
            By confirming, you indicate that you have read and agree to Trophee’s{' '}
            <Link to='/terms-of-use' target='_blank' className='text-ink-basic font-normal underline'>
              Terms of Use
            </Link>{' '}
            and{' '}
            <Link to='/privacy-policy' target='_blank' className='text-ink-basic font-normal underline'>
              Privacy Policy
            </Link>
          </Trans>
        </p>
        <Button className='w-full' onClick={() => termPopup?.hide()}>
          <Trans>Confirm</Trans>
        </Button>
      </div>
    </div>
  )
}

export default TermAndPolicyModal
