import { useState, useEffect, useRef } from 'react'
import { useInView } from 'react-intersection-observer'

import { useFixedOrders } from 'queries/order'
import { NFTOrder } from 'types/order'

import { SHOW_LIMIT } from 'config/constantKey'

export const useInfiniteFixedOrders = (productId: string) => {
  const [cursor, setCursor] = useState<number>(0)
  const [isEnd, setEnd] = useState(false)
  const [hideRefElement, setHideRefElement] = useState(false)
  const [showOrders, setShowOrders] = useState<NFTOrder[]>([])
  const {
    data: orders,
    isLoading,
    invalidate
  } = useFixedOrders({
    productId,
    _limit: SHOW_LIMIT,
    _cursor: cursor
  })
  const nextCursor = useRef<number>()

  const { ref, inView } = useInView({
    threshold: 0.5
  })

  useEffect(() => {
    setTimeout(() => {
      setHideRefElement(isLoading) // when loading, we will hide the ref element with some delay to make smoothy
    }, 600)
  }, [isLoading])

  useEffect(() => {
    if (orders?.data) {
      const list = orders.data
      if (list.length > 0) {
        setShowOrders(old => {
          return [...old, ...list]
        })
      }

      if (orders.cursor) {
        nextCursor.current = orders.cursor
      } else {
        setEnd(true)
      }
    }
  }, [orders])

  // use ref to prevent infinite render if maybe
  useEffect(() => {
    if (inView) {
      setCursor(nextCursor.current || 0)
    }
  }, [inView])

  return { showOrders, isLoading, isEnd, ref, hideRefElement, invalidate }
}
