import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import Cookies from 'js-cookie'

import { useAuth } from 'hooks'
import { useRefreshSignature } from 'store/user/hook'
import { storageKey } from 'config/constantKey'
import { ConnectorNames } from 'config/connectors'

import Header from './Header'
import WalletConnectModal from './WalletConnectModal'
import Footer from './Footer'
import KYCRequestModal from './KYCModal/KYCRequestModal'
import KYCPendingModal from './KYCModal/KYCPendingModal'
import KYCRejectModal from './KYCModal/KYCRejectModal'
import CommonModal from './Modal'
import { TermAndPolicyModal } from 'components'
import LogInModal from './LoginModal'
import SignUpModal from './SignUpModal'

const defaultTitle = 'Trophee | Manga NFT Marketplace'
const defaultDescription = "Trophee is the world's largest Manga NFT Marketplace"
export interface LayoutProps {
  title?: string
  description?: string
}

const Layout: React.FC<LayoutProps> = ({ title = defaultTitle, description = defaultDescription, children }) => {
  const { login } = useAuth()

  // auto connect wallet
  useEffect(() => {
    const connectorId = Cookies.get(storageKey.connector)
    if (connectorId && Object.values(ConnectorNames).includes(connectorId as ConnectorNames)) {
      login(connectorId as ConnectorNames)
    }
  }, [login])

  // handle when account changed
  // useFetchMyProfile()
  useRefreshSignature()

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name='description' content={description} />
      </Helmet>

      <Header />
      <div className='content-grid py-20 min-h-[89vh]'>{children}</div>
      <Footer />
      {/* connect wallet modal */}
      <WalletConnectModal />
      {/* KYC Modal */}
      <KYCRequestModal />
      <KYCPendingModal />
      <KYCRejectModal />
      {/* Common Modal */}
      <CommonModal />
      {/* Terms of use and  Privacy Policy Modal */}
      <TermAndPolicyModal />
      {/* login modal */}
      <LogInModal />
      {/* signup modal */}
      <SignUpModal />
    </>
  )
}

export default Layout
