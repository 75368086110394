import React from 'react'
import { isEmpty } from 'lodash'

import ProductPreviewItem from './ProductPreviewItem'
import { Product } from 'types/product'
import { NoResult } from 'components'

export interface ProductListingProps {
  products: Product[]
}

const ProductListing: React.FC<ProductListingProps> = ({ products }) => {
  if (isEmpty(products)) return <NoResult />

  return (
    <>
      {products.map(product => {
        return <ProductPreviewItem key={product.id} product={product} />
      })}
    </>
  )
}

export default ProductListing
