import { atom } from 'jotai'

import { ProfileInfo } from 'types/profile'

export interface SignatureAtomProps {
  [key: string]: string
}

export interface UpdateSignatureProps {
  message: string
  signature: string
}

export const signatureAtom = atom<SignatureAtomProps | undefined>(undefined)

export const derivedSignatureAtom = atom(null, (get, set, update: UpdateSignatureProps | undefined) =>
  set(
    signatureAtom,
    update
      ? {
          ...get(signatureAtom),
          [update.message]: update.signature
        }
      : undefined
  )
)

export const myProfileAtom = atom<ProfileInfo | undefined>(undefined)
export const walletChanged = atom<boolean>(false)
