import React from 'react'
import { useHistory } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import { Trans } from '@lingui/macro'
import classnames from 'classnames'
import compareDesc from 'date-fns/compareDesc'

import { Art, Status } from 'types/feature'
import { useBalanceOf } from 'queries/product'
import { useTimer } from 'hooks'
import { formatDateTime } from 'utils/format'
import { arts } from 'config/featuredCollection'
import { MEMBERSHIP_NFT_ADDRESS, MEMBERSHIP_LEVEL1_ID } from 'config/constantKey'

import { Button, Ribbon } from 'components'

type ArtItemProps = {
  art: Art
  index: number
  status: Status
  isMembership: boolean
}

const ArtItem = ({ art, status, index }: ArtItemProps) => {
  const history = useHistory()
  const timer = useTimer(
    Date.parse(Status.Upcoming === status ? art.releaseDetail?.date.from || '' : art.releaseDetail?.date.to || '')
  )

  return (
    <div
      className={classnames('feature__arts-item flex md:flex-nowrap flex-wrap md:py-20 py-8', {
        'md:flex-row-reverse': index % 2 !== 0,
        'md:flex-row': index % 2 === 0
      })}
    >
      <div
        className={classnames('md:w-6/12 w-full', {
          'md:pl-8': index % 2 !== 0,
          'md:pr-8': index % 2 === 0
        })}
      >
        <div className='relative'>
          <img src={art.image} alt='' className='feature__arts-image w-full object-cover' />
          {[Status.Ended, Status.SoldOut].includes(status) ? (
            <div className='absolute inset-0 flex justify-center items-center bg-black bg-opacity-50'>
              <div className='py-2 px-4 bg-gray-600 text-white rounded-md'>
                <Trans>{status === Status.Ended ? 'Ended' : 'Sold Out'}</Trans>
              </div>
            </div>
          ) : (
            <Ribbon status={status} />
          )}
        </div>
      </div>
      <div className={`flex-1 ${index % 2 ? 'md:pr-8' : 'md:pl-8'} md:pt-0 pt-8`}>
        <h3 className='font-medium text-[2rem] leading-10'>
          <Trans id={art.title} />
        </h3>
        <div className='pt-8 leading-6 feature__arts-content' dangerouslySetInnerHTML={{ __html: art.content }} />
        {!!art?.releaseDetail && (
          <div className='mb-8'>
            <p className='font-medium'>
              <Trans>Release Details:</Trans>
            </p>
            <p>
              <Trans id={art.releaseDetail.edition} />
            </p>
            <p>
              <Trans id={art.releaseDetail.type} />
            </p>
            <p>
              {formatDateTime(new Date(art.releaseDetail.date.from))} -{' '}
              {formatDateTime(new Date(art.releaseDetail.date.to))}
            </p>
          </div>
        )}
        {art.auctionId && status !== Status.SoldOut && Status.Ended !== status && (
          <div className='pb-10 font-medium'>
            <span className='text-xs text-gray-400'>
              {Status.ActiveNow === status ? <Trans>Ends in</Trans> : <Trans>Auction Starts in</Trans>}
            </span>
            <div className='flex items-center pt-2'>
              <div className='flex items-center justify-center w-[36px] h-[36px] bg-[#f0f2f5] rounded-[2px] mx-[0.5rem] first:ml-0'>
                {timer?.days}d
              </div>
              :
              <div className='flex items-center justify-center w-[36px] h-[36px] bg-[#f0f2f5] rounded-[2px] mx-[0.5rem]'>
                {('0' + timer?.hours).slice(-2)}h
              </div>
              :
              <div className='flex items-center justify-center w-[36px] h-[36px] bg-[#f0f2f5] rounded-[2px] mx-[0.5rem]'>
                {('0' + timer?.minutes).slice(-2)}m
              </div>
            </div>
          </div>
        )}
        <div className='flex items-center'>
          <Button
            className='py-[11px] mr-2'
            variant='secondary'
            onClick={() =>
              history.replace(
                status === Status.Ended
                  ? `/marketplace-products/${art.productId || ''}`
                  : art.auctionId
                  ? `/auctions/${art.auctionId || ''}`
                  : art.orderId
                  ? `/orders/${art.orderId || ''}`
                  : `/marketplace-products/${art.productId || ''}`
              )
            }
          >
            Go to NFT
          </Button>
        </div>
      </div>
    </div>
  )
}

const Arts = () => {
  const { account } = useWeb3React()
  const { data: isMembership } = useBalanceOf(MEMBERSHIP_NFT_ADDRESS, MEMBERSHIP_LEVEL1_ID, account || '')

  const getStatus = (art: Art) => {
    if (compareDesc(new Date(), new Date(art.releaseDetail?.date.to || '')) < 0) return Status.Ended

    if (compareDesc(new Date(), new Date(art.releaseDetail?.date.from || '')) > 0) return Status.Upcoming
    if (compareDesc(new Date(), new Date(art.releaseDetail?.date.from || '')) < 0) return Status.ActiveNow

    return Status.Ended
  }

  return (
    <div className='py-10'>
      {arts.map((art, index) => (
        <ArtItem
          key={index}
          art={art}
          status={art.status || getStatus(art)}
          index={index}
          isMembership={!!isMembership}
        />
      ))}
    </div>
  )
}

export default Arts
