import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import { round } from 'lodash'

import { CoverImage, ClassificationInfo, Ribbon } from 'components'
import { useUSDPrice } from 'hooks/useUSDPrice'
import { useLocale, useProductPaymentToken, useTimer } from 'hooks'

import { Classification, Product, TagKey } from 'types/product'
import { Status } from 'types/feature'
import { formatCurrency } from 'utils/price'
import { getLocalizedDetail } from 'utils/localization'
import { checkAuctionEnded } from 'utils/core'
import { MAX_RESERVE_PRICE } from 'config/constantKey'

type ListItemProps = {
  product: Product
}

const ListItem = ({ product }: ListItemProps) => {
  const locale = useLocale()
  const openAtTimer = useTimer(product.open_at)
  const auctionExpiredAtTimer = useTimer(product.order_auction?.expired_at)

  const ribbonType = useMemo(
    () => Object.keys(product.tags || {}).find(key => product?.tags?.[key as TagKey]),
    [product]
  )
  const fixedPrice = useMemo(() => product.order_fixed_price?.price || product.order_p2p?.price, [product])
  const auctionPrice = useMemo(
    () => product?.order_auction?.bid_highest?.price || product?.order_auction?.price_from,
    [product]
  )
  const isAuctionEnded = useMemo(() => {
    return checkAuctionEnded(product.order_auction)
  }, [product])
  const paymentToken = useProductPaymentToken(product)

  const renderRibbon = () => {
    if (!ribbonType) return null
    if (![Status.Ended, Status.SoldOut].includes(ribbonType as Status)) {
      return <Ribbon status={ribbonType as Status} size='small' />
    }
    return (
      <div className='absolute inset-0 flex justify-center items-center bg-black bg-opacity-50'>
        <div className='py-2 px-4 bg-gray-600 text-white rounded-md'>
          {Status.Ended === ribbonType ? <Trans>Ended</Trans> : <Trans>Sold Out</Trans>}
        </div>
      </div>
    )
  }

  const usdFixedPrice = useUSDPrice(fixedPrice || 0, paymentToken)
  const usdAuctionPrice = useUSDPrice(auctionPrice || 0, paymentToken)

  const detailUrl = useMemo(() => {
    if (product.order_count === 1) {
      if (product.order_fixed_price) {
        return `/orders/${product.order_fixed_price.id}`
      }
      if (product.order_auction && !isAuctionEnded) {
        return `/auctions/${product.order_auction.id}`
      }
      if (product.main_order_id) {
        return `/orders/${product.main_order_id}`
      }
    }

    return `/marketplace-products/${product.id}`
  }, [product, isAuctionEnded])

  const renderEdition = useMemo(() => {
    if (!!auctionPrice && product.order_count) {
      if (product.order_count === 1) {
        return (
          <p className='text-xs text-ink-lighter font-medium'>
            <Trans>Single Edition</Trans>
          </p>
        )
      }

      return (
        <p className='text-xs text-ink-lighter font-medium'>
          <Trans>Edition Size</Trans>: {product.order_count}
        </p>
      )
    }
    if (!!fixedPrice && (product.order_count || product.order_open_count)) {
      return (
        <p className='text-xs text-ink-lighter font-medium'>
          <Trans>Remaining</Trans>: {product.order_open_count}/{product.order_count}
        </p>
      )
    }
  }, [auctionPrice, fixedPrice, product])

  return (
    <div className='rounded bg-white cursor-pointer border border-sky-light h-full'>
      <div className='relative'>
        <Link to={detailUrl}>
          <CoverImage
            className='h-[302px]'
            imageUrl={product.assets_collections_urls?.marketplace_preview?.[0] || product.assets_urls?.[1]}
          />
          {renderRibbon()}
        </Link>
      </div>
      <div className='p-3'>
        <p className='text-xs text-ink-lighter'>{getLocalizedDetail({ detail: product, locale })('authorName')}</p>
        <p className='font-medium py-[0.375rem] truncate'>{getLocalizedDetail({ detail: product, locale })('name')}</p>

        <div className='flex justify-between items-center'>
          <span className='text-xs'>
            <ClassificationInfo classification={product.rarity as Classification} />
          </span>
          {renderEdition}
        </div>
        <div className='flex mt-3'>
          {fixedPrice && fixedPrice !== MAX_RESERVE_PRICE && (
            <div className='flex-1'>
              <p className='mb-[0.125rem] text-xs text-new-darker'>
                <Trans>Buy</Trans>
              </p>
              <div className='flex items-center'>
                <img src={`/images/tokens/${paymentToken}.png`} alt='payment logo' className='w-5 h-5' />
                <p className='ml-[6px] font-medium'>{formatCurrency(round(fixedPrice || 0, 2))}</p>
              </div>
              <p className='mt-1 text-xs text-new-darker'>
                {Status.Ended === ribbonType ? (
                  <Trans>Auction Ended</Trans>
                ) : (
                  `~$${formatCurrency(round(usdFixedPrice || usdAuctionPrice, 2))}`
                )}
              </p>
            </div>
          )}
          {!!auctionPrice ? (
            <div className='flex-1'>
              <p className='mb-[0.125rem] text-xs text-new-darker'>
                <Trans>Bid</Trans>
              </p>

              <div className='flex items-center'>
                <img src={`/images/tokens/${paymentToken}.png`} alt='payment logo' className='w-5 h-5' />
                <p className='ml-[6px] font-medium'>{formatCurrency(round(auctionPrice || 0, 2))}</p>
              </div>
              <p className='ml-[2px] text-xs text-new-darker truncate'>~${formatCurrency(round(usdAuctionPrice, 2))}</p>
              <p className='mt-[2px] text-xs text-new-darker'>
                {isAuctionEnded
                  ? 'Auction Ended'
                  : `Ends in: ${auctionExpiredAtTimer.days}d ${('0' + auctionExpiredAtTimer?.hours).slice(-2)}h ${(
                      '0' + auctionExpiredAtTimer?.minutes
                    ).slice(-2)}m`}
              </p>
            </div>
          ) : (
            openAtTimer &&
            [Status.Upcoming, Status.GiveAway].includes(ribbonType as Status) && (
              <div className='flex-1 text-left'>
                <p className='mb-[0.125rem] text-xs text-new-darker'>
                  {Status.Upcoming === ribbonType ? <Trans>Auction Starts in</Trans> : <Trans>Giveaway ends in</Trans>}
                </p>
                <div className='flex items-center pt-2 font-medium text-sm'>
                  <div className='flex items-center justify-center w-[30px] h-[30px] bg-[#f0f2f5] rounded-[2px] mr-[0.5rem]'>
                    {openAtTimer?.days}d
                  </div>
                  :
                  <div className='flex items-center justify-center w-[30px] h-[30px] bg-[#f0f2f5] rounded-[2px] mx-[0.5rem]'>
                    {('0' + openAtTimer?.hours).slice(-2)}h
                  </div>
                  :
                  <div className='flex items-center justify-center w-[30px] h-[30px] bg-[#f0f2f5] rounded-[2px] ml-[0.5rem]'>
                    {('0' + openAtTimer?.minutes).slice(-2)}m
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  )
}

export default ListItem
