import React from 'react'
import classnames from 'classnames'

export interface PlusExpProps {
  exp: number
  extraClassName?: string
}

const PlusExp: React.FC<PlusExpProps> = ({ exp, extraClassName }) => {
  return (
    <p className={classnames('text-sticker', extraClassName)}>
      <svg className='inline-block mr-2 text-sticker-icon icon-plus-small'>
        <use xlinkHref='#svg-plus-small'></use>
      </svg>
      {exp} EXP
    </p>
  )
}

export default PlusExp
