import { t } from '@lingui/macro'

import feature_5 from 'assets/images/feature/feature_5.jpg'
import feature_6 from 'assets/images/feature/feature_6.jpg'
import feature_7 from 'assets/images/feature/feature_7.jpg'

import other_collection_1 from 'assets/images/feature/other_collection_1.png'
import other_collection_2 from 'assets/images/feature/other_collection_2.png'
import other_collection_3 from 'assets/images/feature/other_collection_3.png'
import other_collection_4 from 'assets/images/feature/other_collection_4.png'
import other_collection_5 from 'assets/images/feature/other_collection_5.jpeg'
import other_collection_6 from 'assets/images/feature/other_collection_6.png'
import { Status } from 'types/feature'

export const background = [feature_6, feature_5]

export const arts = [
  {
    title: t`Mischievous angel`,
    image: feature_5,
    content: `
      <p>God of mischief? Think again, it's an angel up to no good. What kind of trick will she play on you. By Kiriko Yumeji</p>   
    `,
    releaseDetail: {
      edition: t`Single Edition`,
      type: t`Fixed Price`,
      date: {
        from: '2021/11/25 00:00 UTC',
        to: '2021/12/31 00:00 UTC'
      }
    },
    orderId: '0xbd74ad153252977a14ca4fcfea7e1b9093af7aa91a10637473f8425ee5304614',
    productId: 'bfbc928419c908bdd2add64fbc4b173b'
  },
  {
    title: t`Queen of Morpho "Chloe"`,
    image: feature_6,
    content: `
      <p>Chloe who is metamorphosized into the queen of all morphos. By Kiriko Yumeji.</p>
    `,
    releaseDetail: {
      edition: t`Single Edition`,
      type: t`Fixed Price`,
      date: {
        from: '2021/11/25 00:00 UTC',
        to: '2021/12/31 00:00 UTC'
      }
    },
    orderId: '0x55341789e26103ab262c9b2ec69cd25493b6cd3c9569369b93c5e2bab1350ccf',
    productId: 'bb74695706af426ea32d27a898f7f764'
  },
  {
    title: t`The One and Only Power`,
    image: feature_7,
    content: `
      <p>Master class art drawn for the "2D vs KATANA" exhibition at the Iwakuni Art Museum from September to November 2018.</p>
    `,
    releaseDetail: {
      edition: t`Single Edition`,
      type: t`Fixed Price`,
      date: {
        from: '2021/11/25 00:00 UTC',
        to: '2021/12/31 00:00 UTC'
      }
    },
    status: Status.SoldOut,
    productId: 'e86d735dc6d4e218725fceb7acca4135'
  }
]

export const featureCollections = [
  'bb74695706af426ea32d27a898f7f764',
  'bfbc928419c908bdd2add64fbc4b173b',
  'e86d735dc6d4e218725fceb7acca4135'
]

export const otherCollections = [
  {
    background: other_collection_1,
    avatar: other_collection_4,
    name: t`Kiriko Yumeji`,
    title: t`Explosion of Color`,
    content: t`
          But I must explain to you how all mistaken idea of denouncing pleasure and praising pain was born...
       `
  },
  {
    background: other_collection_2,
    avatar: other_collection_5,
    name: t`Kiriko Yumeji`,
    title: t`Explosion of Color`,
    content: t`
          But I must explain to you how all mistaken idea of denouncing pleasure and praising pain was born...
        `
  },
  {
    background: other_collection_3,
    avatar: other_collection_6,
    name: t`Kiriko Yumeji`,
    title: t`Explosion of Color`,
    content: t`
          But I must explain to you how all mistaken idea of denouncing pleasure and praising pain was born...
        `
  }
]

export const featuresMangaDrop = [
  {
    images: [feature_5, feature_6],
    author: t`Kiriko Yumeji`,
    title: t`Kiriko Yumeji’s works`
  }
]
