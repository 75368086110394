import { useQuery, useMutation, useQueryClient } from 'react-query'
import axios from 'axios'

import { ProfileInfo, Statistic } from 'types/profile'
import { signatureKey } from 'config/constantKey'
import { useMyProfile, useSignature } from 'store/user/hook'
import { getKYCStatus } from 'apis/order'
import { checkKYCError } from 'utils/core'

export const useUserProfile = (id?: string | null) => {
  return useQuery<ProfileInfo>(`/user-get?id=${id}`, {
    enabled: !!id
  })
}

const updateProfileWithSign = (message: string, sign?: string) => (info: ProfileInfo) => {
  return axios.patch<ProfileInfo>('/user-save', info, {
    headers: {
      'trophee-msg': message,
      'trophee-sig': sign
    }
  })
}

export const useUpdateUserProfile = (id: string) => {
  const [signature] = useSignature(signatureKey)
  const updateUserProfile = updateProfileWithSign(signatureKey, signature)
  const queryClient = useQueryClient()
  const [myProfile, setMyProfile] = useMyProfile()

  return useMutation(updateUserProfile, {
    onSuccess: data => {
      if (data.data) {
        setMyProfile(
          myProfile
            ? {
                ...data.data,
                avatar_url: myProfile.avatar_url
              }
            : {
                ...data.data
              }
        )
        queryClient.setQueryData(`/user-get?id=${id}`, data.data)
      }
    }
  })
}

const updateAvatarWithSign = (message: string, sign?: string) => (avatar: File) => {
  const formData = new FormData()
  formData.append('avatar', avatar)

  return axios.post<ProfileInfo>('/user-avatar', formData, {
    headers: {
      'trophee-msg': message,
      'trophee-sig': sign
    }
  })
}

export const useUpdateUserAvatar = (id: string) => {
  const [signature] = useSignature(signatureKey)
  const updateUserAvatar = updateAvatarWithSign(signatureKey, signature || '')
  const queryClient = useQueryClient()
  const [myProfile, setMyProfile] = useMyProfile()

  return useMutation(updateUserAvatar, {
    onSuccess: data => {
      if (data.data) {
        setMyProfile(
          myProfile
            ? {
                ...myProfile,
                avatar_url: data.data.avatar_url
              }
            : {
                ...data.data
              }
        )
        queryClient.setQueryData(`/user-get?id=${id}`, {
          ...queryClient.getQueryData(`/user-get?id=${id}`),
          avatar_url: data.data.avatar_url
        })
      }
    }
  })
}

export const useGetStatistics = (account?: string | null) => {
  return useQuery<Statistic>(`/v2/me/statistics?account=${account}`, {
    enabled: !!account
  })
}

export const useKYCStatus = (account?: string | null) => {
  return useQuery<string>(
    `/v2/me/kyc?account=${account}`,
    async () => {
      try {
        await getKYCStatus(account || '')
        return 'KYC_ACCEPTED'
      } catch (err: unknown) {
        const code = checkKYCError(err)

        return code
      }
    },
    {
      enabled: !!account
    }
  )
}
