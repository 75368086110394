import { useAtom } from 'jotai'

import { collectionAtom, collectionImages, sellItemAtom } from './index'

export const useCollection = () => {
  return useAtom(collectionAtom)
}

export const useSellItem = () => {
  return useAtom(sellItemAtom)
}

export const useCollectionImages = () => {
  return useAtom(collectionImages)
}
