import React from 'react'

import { Button } from 'components'
import { ReactComponent as BridgeSuccessIcon } from 'assets/images/bridge_success.svg'

export interface BridgeActionSuccessProps {
  title: string
  actionTitle: string
  onAction: () => void
}

const BridgeActionSuccess: React.FC<BridgeActionSuccessProps> = ({ title, actionTitle, onAction }) => {
  return (
    <div className='flex flex-col py-10 items-center text-new-black'>
      <BridgeSuccessIcon />
      <h6 className='w-full md:max-w-[25rem] mt-5 mb-10 text-center font-medium'>{title}</h6>
      <Button className='w-full md:w-[25rem]' onClick={onAction}>
        {actionTitle}
      </Button>
    </div>
  )
}

export default BridgeActionSuccess
