import React from 'react'
import { Trans } from '@lingui/macro'
import { useHistory } from 'react-router-dom'

import card from 'assets/images/home/card.png'
import { Button } from 'components'
import { MEMBERSHIP_PRODUCT_ID } from 'config/constantKey'

const Membership = () => {
  const history = useHistory()
  return (
    <div className='flex flex-wrap items-center py-10'>
      <div className='md:w-1/2 w-full'>
        <img className='w-full' src={card} alt='Premium Membership NFT' />
      </div>
      <div className='flex-1 text-new-darker'>
        <h3>
          <Trans>Premium Membership NFT</Trans>
        </h3>
        <p className='mt-3 text-xl'>
          <Trans>Trophee has just launched its Premium Membership NFT!</Trans>
        </p>
        <p className='mt-3 text-xl'>
          <Trans>
            This NFT upgrades its owner's account to a Premium Membership which gives exclusive access to upcoming
            premium member only auctions, giveaways, events, and more.
          </Trans>
        </p>
        <p className='mt-3 text-xl'>
          <Trans>Get one of the 100 launch units today before they sell out!</Trans>
        </p>
        <Button className='mt-3' onClick={() => history.push(`/marketplace-products/${MEMBERSHIP_PRODUCT_ID}`)}>
          <Trans>See Details</Trans>
        </Button>
      </div>
    </div>
  )
}

export default Membership
