import React from 'react'

import { Loader, NoData, NoResult } from 'components'

type ListWrapperProps = {
  isNoData: boolean
  isNoResult?: boolean
  isLoading?: boolean
}

const ListWrapper: React.FC<ListWrapperProps> = ({ isNoData, isNoResult = false, isLoading, children }) => {
  if (isLoading) return <Loader />
  if (isNoResult) {
    return <NoResult />
  }
  if (isNoData) {
    return <NoData />
  }
  return (
    <div className='py-4 grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 justify-center gap-y-8'>
      {children}
    </div>
  )
}

export default ListWrapper
