import React from 'react'

import Loader from './Loader'

export interface PageLoadingProps {}

const PageLoading: React.FC<PageLoadingProps> = () => {
  return (
    <div className='page-loader'>
      <div className='page-loader-decoration'>
        <svg className='icon-logo-trophee-pl' viewBox='0 0 200 200' preserveAspectRatio='xMinYMin meet'>
          <path d='M198.92 109.21C198.92 137.09 189.42 160.6 170.22 179.31C170.15 179.38 170.08 179.45 170.01 179.52C155.6 193.64 131.68 191.13 119.86 174.79C116.02 169.48 112.89 163.17 111.49 155.8C112.37 155.5 113.3 155.21 114.23 154.82L122.9 154.82C139.02 154.82 154.3 148.69 165.91 137.58C177.57 126.36 184.23 111.32 184.62 95.25L184.62 67.09L147 67.09L147 59.54L154.25 59.54L154.25 43.18L44.67 43.18L44.67 59.54L51.92 59.54L51.92 67.09L14.3 67.09L14.3 95.25C14.7 111.32 21.36 126.36 33.01 137.58C44.62 148.69 59.91 154.82 76.02 154.82L84.69 154.82C85.62 155.21 86.55 155.5 87.44 155.8C85.86 164.05 82.12 170.98 77.63 176.66C65.66 191.78 42.72 193.18 28.87 179.76C28.72 179.61 28.56 179.46 28.41 179.31C9.5 160.6 0 137.09 0 109.21C0 81.34 9.5 57.83 28.41 39.12C47.37 20.45 70.88 11 99.34 11C127.75 11 151.26 20.45 170.22 39.12C189.42 57.83 198.92 81.34 198.92 109.21Z' />
          <path d='M135.39 138.51C143.03 128.42 147 115.97 147 103.29L147 78.21L173.55 78.21L173.55 93.64C173.55 94.08 173.55 94.52 173.5 95.01C172.91 118.96 154.84 138.9 131.62 142.96C132.89 141.59 134.17 140.12 135.39 138.51Z' />
          <path d='M130.44 69L138.18 69L138.18 102.89C138.18 119.11 130.49 134.39 117.56 143.75C115.7 145.07 113.74 146.29 111.68 147.37C107.03 149.82 102.13 151.39 97.53 151.19C97.66 151.16 97.82 151.08 98.02 150.95C101.79 148.89 105.32 146.44 108.5 143.75C122.26 131.99 130.44 114.65 130.44 96.13L130.44 69Z' />
          <path d='M51.92 78.21L51.92 103.29C51.92 115.97 55.89 128.42 63.53 138.51C64.76 140.12 66.03 141.59 67.3 142.96C44.09 138.9 26.01 118.96 25.42 95.01C25.42 94.52 25.37 94.08 25.37 93.64L25.37 78.21L51.92 78.21Z' />
        </svg>
      </div>
      <div className='page-loader-info'>
        <p className='page-loader-info-title'>Trophee</p>

        <p className='page-loader-info-text'>Loading...</p>
      </div>

      <Loader />
    </div>
  )
}

export default PageLoading
