import React from 'react'
import { t, Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import { useForm, Controller } from 'react-hook-form'
import { useMutation } from 'react-query'

import { useInfiniteCollections, useSignMessage } from 'hooks'
import { mintOrder, uploadOrderImages } from 'apis/order'
import { signatureKey } from 'config/constantKey'
import { toastError } from 'utils/toast'

import { Button, ConnectWalletButton, Input, InputFile, ListWrapper, Loader, MintItem } from 'components'
import { ToastError } from 'components/ToastMessage'

type FormData = {
  name: string
  description: string
  price: number
  asset_nft?: FileList
  featured_collection?: FileList
  marketplace_preview?: FileList
  product_carousel?: FileList
  product_full_screen?: FileList
  video?: FileList
}

const List = () => {
  const { account } = useWeb3React()
  const requestSign = useSignMessage(signatureKey)

  const { control, formState, handleSubmit } = useForm<FormData>({
    mode: 'onChange'
  })

  const { errors } = formState

  const { showOrders, isLoading, isEnd, ref, hideRefElement, invalidate } = useInfiniteCollections(account, {
    seller_id: account?.toLowerCase()
  })
  const { mutate: handleCreateOrder, isLoading: isLoadingCreateOrder } = useMutation(mintOrder)
  const { mutate: handleUploadImages, isLoading: isLoadingUploadImages } = useMutation(uploadOrderImages)

  const getFormData = (values: FormData) => {
    let hasFile = false
    const formData = new FormData()
    if (values.asset_nft) {
      hasFile = true
      formData.append('asset_nft', values.asset_nft[0])
    }
    if (values.featured_collection) {
      hasFile = true
      Array.from(values.featured_collection).map(file =>
        formData.append('assets_collections.featured_collection', file)
      )
    }
    if (values.marketplace_preview) {
      hasFile = true
      Array.from(values.marketplace_preview).map(file =>
        formData.append('assets_collections.marketplace_preview', file)
      )
    }
    if (values.product_carousel) {
      hasFile = true
      Array.from(values.product_carousel).map(file => formData.append('assets_collections.product_carousel', file))
    }
    if (values.product_full_screen) {
      hasFile = true
      Array.from(values.product_full_screen).map(file =>
        formData.append('assets_collections.product_full_screen', file)
      )
    }
    if (values.video) {
      hasFile = true
      Array.from(values.video).map(file => formData.append('assets_collections.video', file))
    }
    return {
      hasFile,
      data: formData
    }
  }

  const handleMint = async (values: FormData) => {
    const [sig, msg] = await requestSign()

    const dataCreateOrder = {
      name: values.name,
      description: values.description,
      price: values.price
    }
    const dataUpload = getFormData(values)
    handleCreateOrder(
      {
        data: dataCreateOrder,
        msg,
        sig
      },
      {
        onSuccess: data => {
          if (dataUpload.hasFile) {
            handleUploadImages(
              { orderId: data.id, data: dataUpload.data, msg, sig },
              {
                onSuccess: () => {
                  invalidate()
                },
                onError: (err: any) => {
                  toastError(<ToastError header={t`Mint Order Failed!`} message={err?.message} />)
                }
              }
            )
          } else {
            invalidate()
          }
        },
        onError: (err: any) => {
          toastError(<ToastError header={t`Mint Order Failed!`} message={err?.message} />)
        }
      }
    )
  }

  return (
    <div className='py-20'>
      <div className='border border-sky-light rounded shadow-xl'>
        <div className='p-4 border-[0px] border-b-[1px] border-sky-light border-solid'>
          <h4>
            <Trans>Mint Your NFT</Trans>
          </h4>
        </div>
        <div className='p-4'>
          <form onSubmit={handleSubmit(handleMint)}>
            <div className='mb-[1rem] grid grid-cols-1 md:grid-cols-2'>
              <div className='grid grid-cols-1'>
                <Controller
                  render={({ field }) => <Input placeholder={t`Enter Order Name`} {...field} errors={errors} />}
                  control={control}
                  name='name'
                  rules={{
                    required: t`Name is required`
                  }}
                />
                <Controller
                  render={({ field }) => <Input placeholder={t`Enter Order Description`} {...field} errors={errors} />}
                  control={control}
                  name='description'
                  rules={{
                    required: t`Description is required`
                  }}
                />
                <Controller
                  render={({ field }) => <Input placeholder={t`Enter Order Price`} {...field} errors={errors} />}
                  control={control}
                  name='price'
                  rules={{
                    required: t`Price is required`,
                    pattern: {
                      value: /^\d+(\.\d+)?$/,
                      message: t`Invalid Price`
                    }
                  }}
                />
              </div>
              <div className='grid grid-cols-1'>
                <Controller
                  render={({ field }) => (
                    <InputFile label={t`Upload Asset NFT`} type='file' {...field} errors={errors} />
                  )}
                  control={control}
                  name='asset_nft'
                />
                <Controller
                  render={({ field }) => (
                    <InputFile label={t`Upload Asset Featured Collection`} multiple {...field} errors={errors} />
                  )}
                  control={control}
                  name='featured_collection'
                />
                <Controller
                  render={({ field }) => (
                    <InputFile label={t`Upload Asset Marketplace Preview`} multiple {...field} errors={errors} />
                  )}
                  control={control}
                  name='marketplace_preview'
                />
                <Controller
                  render={({ field }) => (
                    <InputFile label={t`Upload Asset Product Carousel`} multiple {...field} errors={errors} />
                  )}
                  control={control}
                  name='product_carousel'
                />
                <Controller
                  render={({ field }) => (
                    <InputFile label={t`Upload Asset Video`} accept='video/*' multiple {...field} errors={errors} />
                  )}
                  control={control}
                  name='video'
                />
                <Controller
                  render={({ field }) => (
                    <InputFile label={t`Upload Asset Product Fullscreen`} multiple {...field} errors={errors} />
                  )}
                  control={control}
                  name='product_full_screen'
                />
              </div>
            </div>
            <div className='text-right'>
              {account ? (
                <Button
                  type='submit'
                  disabled={isLoadingCreateOrder || isLoadingUploadImages}
                  isLoading={isLoadingCreateOrder || isLoadingUploadImages}
                >
                  <Trans>Mint</Trans>
                </Button>
              ) : (
                <div className='flex w-[fit-content] my-0 mx-auto'>
                  <ConnectWalletButton />
                </div>
              )}
            </div>
          </form>
          <ListWrapper isLoading={isLoading && !showOrders.length} isNoData={showOrders.length === 0}>
            {showOrders.map(order => (
              <MintItem key={order.id} order={order} />
            ))}
          </ListWrapper>
          {!hideRefElement && !isEnd && showOrders.length > 0 && (
            <div ref={ref}>
              <Loader />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default List
