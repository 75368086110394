import React from 'react'
import { Trans } from '@lingui/macro'

import nodata from 'assets/images/no-data.png'

const NoData = () => {
  return (
    <div className="text-center py-20">
      <img className="w-[200px] h-[200px]" src={nodata} alt="no-data" />
      <p className="text-ink-lighter">
        <Trans>No items to display</Trans>
      </p>
    </div>
  )
}

export default NoData
