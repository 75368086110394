import React from 'react'
import ReactSelect, { Props as SelectProps } from 'react-select'

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    height: 54,
    minHeight: 54
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  menu: (base: any) => ({ ...base, zIndex: 20 })
}

const Select: React.FC<SelectProps> = props => {
  return <ReactSelect styles={customStyles} {...props} />
}

export default Select
