import React, { useState, useEffect } from 'react'
import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import round from 'lodash/round'
import clsx from 'classnames'

import { useModal, useSymbol, useTokenContract } from 'hooks'
import { useCommonPopup } from 'store/popup/hook'
import { Button } from 'components'

import { MANGA_ADDRESS } from 'config/constantKey'
import { formatCurrency, formatTokenPrice } from 'utils/price'

export const InsufficientModal = ({ paymentToken }: { paymentToken?: string }) => {
  const { account } = useWeb3React()
  const [balance, setBalance] = useState('0')
  const tokenContract = useTokenContract(paymentToken || MANGA_ADDRESS, false)
  const symbol = useSymbol(tokenContract)

  useEffect(() => {
    const getMangaBalance = async () => {
      const amount = await (tokenContract && tokenContract.balanceOf(account))
      setBalance(formatTokenPrice(+amount.toString(), 18))
    }

    if (account && tokenContract) {
      getMangaBalance()
    }
  }, [tokenContract, account])

  return (
    <div className='text-center'>
      <h5 className='font-medium'>
        <Trans>Transaction Failed</Trans>
      </h5>
      <p className='my-4'>
        Insufficient {symbol}. Get {symbol} to complete the transaction.
      </p>
      <p>
        <Trans>Current Balance</Trans>: {formatCurrency(round(+balance || 0, 2))} {symbol}
      </p>

      <div className='w-60 mt-10 mb-4 mx-auto'>
        <a target='_blank' rel='noreferrer' href='https://farm.trophee.xyz/swap'>
          <Button className='w-full'>Exchange on Trophee</Button>
        </a>
      </div>

      <div className='w-60 mx-auto'>
        <a
          target='_blank'
          rel='noreferrer'
          href={`https://pancakeswap.finance/swap?outputCurrency=${
            paymentToken || MANGA_ADDRESS
          }&inputCurrency=0x55d398326f99059ff775485246999027b3197955`}
        >
          <Button className='w-full' variant='secondary'>
            Exchange on Pancakeswap
          </Button>
        </a>
      </div>
    </div>
  )
}

const CommonModal: React.FC = () => {
  const { setPopup, content, containerClass } = useCommonPopup()

  useModal({
    container: '.popup-common',
    trigger: '.popup-close-common', // hacky way to force the script can find the popup,
    setAtom: setPopup
  })

  return (
    <div className={clsx('popup-box popup-common w-[90%] md:w-[60rem] p-4 md:p-10', containerClass)}>
      <div className='popup-close-common'>
        <svg className='popup-close-button-icon icon-cross'>
          <use xlinkHref='#svg-cross'></use>
        </svg>
      </div>

      <div className='popup-box-body flex-col items-center'>{content}</div>
    </div>
  )
}

export default CommonModal
