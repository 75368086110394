import React from 'react'
import { Trans } from '@lingui/macro'

import { background } from 'config/featuredCollection'

const Background = () => {
  const gridClass = `grid-cols-${background.length}`
  return (
    <div className='relative md:-mt-4 -mt-9 full-width'>
      <div className={`max-h-[498px] grid gap-0 mt-0 ${gridClass}`}>
        {background.map((item, index) => (
          <img key={index} src={item} alt={item} className='max-h-[498px] w-full h-full object-cover' />
        ))}
      </div>
      <div className='absolute inset-0 flex justify-center items-center bg-black bg-opacity-50'>
        <div className='text-center'>
          <h1 className='text-white md:text-6xl font-medium mb-4'>
            <Trans>Kiriko Yumeji's works</Trans>
          </h1>
          {/* <span className='text-white text-xl'>
            <Trans>by Mangaka</Trans>
          </span> */}
        </div>
      </div>
    </div>
  )
}

export default Background
