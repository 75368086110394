import React, { useMemo } from 'react'
import classnames from 'classnames'

import { useLocale } from 'hooks'
import { serialMapping } from 'utils/core'
import { NFTOrder } from 'types/order'
import { Classification } from 'types/product'

import { ClassificationInfo, CoverImage } from 'components'
import { Trans } from '@lingui/macro'

type ItemProps = {
  order: any
  isSelected: boolean
  setSelectedItem: (item: NFTOrder | undefined) => void
}

const Item = ({ order, isSelected, setSelectedItem }: ItemProps) => {
  const locale = useLocale()

  const handleSelect = () => {
    if (isSelected) {
      setSelectedItem(undefined)
    } else {
      setSelectedItem(order)
    }
  }
  const author = useMemo(() => (locale === 'en' ? order.author_name : order.author_name_jp), [locale, order])
  const title = useMemo(() => (locale === 'en' ? order.name : order.name_jp), [locale, order])
  const image = useMemo(
    () => order.product.assets_collections_urls?.marketplace_preview?.[0] || order.product.assets_urls?.[1],
    [order]
  )
  const classification = useMemo(() => order.product.rarity, [order])

  return (
    <div
      className={classnames(
        'rounded bg-white cursor-pointer border border-sky-light relative popup-collection-trigger',
        {
          'border-new-primary shadow': isSelected
        }
      )}
      onClick={handleSelect}
    >
      <CoverImage className='h-[302px]' imageUrl={image || ''} />
      <div className='p-3'>
        <p className='text-xs text-ink-lighter'>{author}</p>
        <p className='font-medium py-[0.375rem]'>{title}</p>
        <div className='flex justify-between items-center'>
          <span className='text-xs'>
            <ClassificationInfo classification={classification as Classification} />
          </span>
          <p className='text-xs text-ink-lighter font-medium'>
            <Trans>Edition</Trans>: {serialMapping(order)}
          </p>
        </div>
      </div>
    </div>
  )
}

export default Item
