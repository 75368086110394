import React, { useMemo, ChangeEvent, useState, useEffect } from 'react'
import { t } from '@lingui/macro'
import debounce from 'lodash.debounce'
// import { DateRangePicker, FocusedInputShape } from 'react-dates'
// import * as moment from 'moment'

import { useInfiniteMangaDrop } from 'hooks'

import { Input, Select, ListWrapper, Loader } from 'components'
import ListItem from './ListItem'

type Option = {
  value: string
  label: string
}

const List = () => {
  const options: Option[] = useMemo(
    () => [
      {
        label: t`Show All`,
        value: 'all'
      },
      {
        label: t`Show Upcoming`,
        value: 'Upcoming'
      },
      {
        label: t`Show Active`,
        value: 'Active'
      },
      {
        label: t`Show Giveaway`,
        value: 'Giveaway'
      },
      {
        label: t`Show Sold out`,
        value: 'Sold Out'
      },
      {
        label: t`Show Ended`,
        value: 'Ended'
      }
    ],
    []
  )
  // const windowSize = useWindowSize()
  const [search, setSearch] = useState<string>('')
  const [filter, setFilter] = useState<Option | null>(options[0])
  // const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(null)
  // const [date, setDate] = useState<{
  //   startDate: moment.Moment | null
  //   endDate: moment.Moment | null
  // }>({
  //   startDate: null,
  //   endDate: null
  // })

  // const { showDatas, isLoading, isEnd, ref, hideRefElement, setCursor } = useInfiniteMangaDrop({
  //   ...(!!filter.search ? { _search: filter.search } : {}),
  //   ...(!filter.tag.value || filter.tag?.value === 'all'
  //     ? {}
  //     : { tag: filter.tag.value === 'ended-soldout' ? ['Sold Out', 'Ended'] : [filter.tag.value] })
  // })

  const { showDatas, isLoading, isEnd, ref, hideRefElement, setCursor } = useInfiniteMangaDrop({
    ...(!!search ? { _search: search } : {}),
    ...(!filter?.value || filter?.value === 'all' ? {} : { tag: filter.value })
  })

  const handleSearch = useMemo(
    () =>
      debounce((e: ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value?.toLowerCase())
      }, 500),
    []
  )

  useEffect(() => {
    setCursor(0)
  }, [search, filter, setCursor])

  return (
    <div className='pt-10'>
      <div className='flex flex-wrap justify-between'>
        <div className='flex flex-wrap w-full md:w-auto'>
          <div className='md:w-[400px] w-full md:mr-[1rem] md:mb-0 mb-[1rem]'>
            <Input
              leftElement={
                <svg className='fill-current text-ink-lighter w-5 h-5'>
                  <use xlinkHref='#svg-search'></use>
                </svg>
              }
              placeholder={t`Search title, mangaka or set...`}
              type='search'
              onChange={handleSearch}
            />
          </div>
          <div className='md:w-[250px] w-full lg:mb-0 mb-[1rem]'>
            <Select options={options} defaultValue={options[0]} onChange={setFilter} />
          </div>
        </div>
        {/* <div className='md:w-[250px] w-full'>
          <DateRangePicker
            startDate={date.startDate}
            startDateId='startDate'
            startDatePlaceholderText={t`Date from `}
            endDate={date.endDate}
            endDateId='endDate'
            endDatePlaceholderText={t`Date to`}
            onDatesChange={setDate}
            focusedInput={focusedInput}
            onFocusChange={setFocusedInput}
            numberOfMonths={windowSize.width <= 425 ? 1 : 2}
            anchorDirection={windowSize.width <= 768 ? 'left' : 'right'}
            hideKeyboardShortcutsPanel
          />
        </div> */}
      </div>
      <div>
        <ListWrapper
          isLoading={isLoading && !showDatas.length}
          isNoData={!search && showDatas.length === 0}
          isNoResult={!!search && showDatas.length === 0}
        >
          {showDatas.map(item => (
            <ListItem key={item.id} product={item} />
          ))}
        </ListWrapper>
        {!hideRefElement && !isEnd && showDatas.length > 0 && (
          <div ref={ref}>
            <Loader />
          </div>
        )}
      </div>
    </div>
  )
}

export default List
