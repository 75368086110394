import React from 'react'
import { Trans } from '@lingui/macro'
import Slider from 'react-slick'

import { articles } from 'config/home'

import { NewsPreviewItem } from 'components'
import { ReactComponent as LeftArrow } from 'assets/images/left_arrow.svg'
import { ReactComponent as RightArrow } from 'assets/images/right_arrow.svg'

const NextArrow = ({ onClick }: { className?: string; onClick?: () => void }) => {
  return (
    <div
      className='absolute top-1/2 right-0 md:right-[-10px] flex w-[52px] h-[52px] bg-white rounded-full justify-center items-center cursor-pointer border border-sky-light hover:shadow-md z-10'
      onClick={onClick}
    >
      <RightArrow />
    </div>
  )
}

const PrevArrow = ({ onClick }: { className?: string; onClick?: () => void }) => {
  return (
    <div
      className='absolute top-1/2 left-0 md:left-[-10px] flex w-[52px] h-[52px] bg-white rounded-full justify-center items-center cursor-pointer border border-sky-light hover:shadow-md z-10'
      onClick={onClick}
    >
      <LeftArrow />
    </div>
  )
}

const GetStarted = () => {
  const settings = {
    className: 'home__slick',
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }
  return (
    <div className='py-20'>
      <div className='text-center'>
        <h2 className='md:text-[2.75rem] text-[2rem] leading-tight font-medium'>
          <Trans>Get Started</Trans>
        </h2>
        {/* <p className='text-xl mt-4 text-ink-lighter'>
          <Trans>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Trans>
        </p> */}
      </div>
      <div className='md:-mx-4 mx-[0] pt-4'>
        <Slider {...settings}>
          {articles.map((item, index) => (
            <NewsPreviewItem
              key={index}
              image={item.image}
              title={item.title}
              content={item.content}
              link={item.link}
            />
          ))}
        </Slider>
      </div>
    </div>
  )
}

export default GetStarted
