import { useState, useLayoutEffect, useRef, useCallback, useEffect } from 'react'
import { createPopup } from 'utils/plugins'

interface ModalProps {
  container: string
  trigger: string
  setAtom?: (ref: any) => void
  rerenderProps?: any
}

export const useModal = ({ container, trigger, setAtom, rerenderProps }: ModalProps) => {
  const popupRef = useRef<any>()
  const [visible, setVisible] = useState(false)

  const handleShow = useCallback(() => {
    if (popupRef.current) {
      setVisible(true)
      return popupRef.current.show()
    }
  }, [])

  const handleHide = useCallback(() => {
    if (popupRef.current) {
      setVisible(false)
      return popupRef.current.hide()
    }
  }, [])

  useLayoutEffect(() => {
    popupRef.current = createPopup(
      {
        container,
        trigger,
        overlay: {
          color: '21, 21, 31',
          opacity: 0.86
        },
        animation: {
          type: 'translate-in-fade',
          speed: 0.3,
          translateOffset: 40
        }
      },
      () => setVisible(false)
    )

    setAtom?.({
      ...popupRef.current,
      show: handleShow,
      hide: handleHide
    })
  }, [container, trigger, setAtom, rerenderProps, handleShow, handleHide])

  useEffect(() => {
    setAtom?.({
      ...popupRef.current,
      show: handleShow,
      hide: handleHide,
      visible
    })
  }, [setAtom, handleShow, handleHide, visible])
}
