import React from 'react'
import { Trans } from '@lingui/macro'

import { CoverImage } from 'components'

type NewsPreviewItemProps = {
  image: string
  title: string
  content: string
  link: string
}

const NewsPreviewItem = ({ image, title, content, link }: NewsPreviewItemProps) => {
  return (
    <div className='md:p-[1rem] p-[0] h-full'>
      <a href={link} target='_blank' rel='noreferrer'>
        <div className='rounded bg-white hover:shadow-lg cursor-pointer'>
          <CoverImage className='h-[22rem]' imageUrl={image} />
          <div className='p-4'>
            <h4 className='font-medium text-2xl'>
              <Trans id={title} />
            </h4>
            <p className='text-ink-lighter my-2 leading-6'>
              <Trans id={content} />
            </p>
            <p className='text-new-primary font-medium text-sm'>
              <Trans>Read more</Trans>
            </p>
          </div>
        </div>
      </a>
    </div>
  )
}

export default NewsPreviewItem
