import React, { useState, useMemo } from 'react'
import Slider from 'react-slick'
import { useQueries } from 'react-query'
import { Link } from 'react-router-dom'

import { featureCollections } from 'config/featuredCollection'
import { getProductDetail } from 'apis/product'
import { Product } from 'types/product'

import { ReactComponent as LeftArrow } from 'assets/images/left_arrow.svg'
import { ReactComponent as RightArrow } from 'assets/images/right_arrow.svg'
import { Loader } from 'components'

const NextArrow = ({ onClick }: { className?: string; onClick?: () => void }) => {
  return (
    <div
      className='absolute top-1/2 right-0 md:right-[-10px] flex w-[52px] h-[52px] bg-white rounded-full justify-center items-center cursor-pointer border border-sky-light hover:shadow-md z-10'
      onClick={onClick}
    >
      <RightArrow />
    </div>
  )
}

const PrevArrow = ({ onClick }: { className?: string; onClick?: () => void }) => {
  return (
    <div
      className='absolute top-1/2 left-0 md:left-[-10px] flex w-[52px] h-[52px] bg-white rounded-full justify-center items-center cursor-pointer border border-sky-light hover:shadow-md z-10'
      onClick={onClick}
    >
      <LeftArrow />
    </div>
  )
}

const FeaturedCollection = () => {
  const [features, setFeatures] = useState<Product[]>([])

  const queryResults = useQueries(
    featureCollections.map(id => {
      return {
        queryKey: ['getFeatureCollection', id],
        queryFn: () => getProductDetail(id),
        onSuccess: (data: any) => {
          setFeatures([...features, data])
        }
      }
    })
  )

  const isLoading = queryResults.some(query => query.isLoading)

  const settings = useMemo(() => {
    return {
      className: 'home__slick',
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: features.length >= 3 ? 3 : features.length,
      slidesToScroll: 3,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: features.length >= 2 ? 2 : features.length,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: features.length >= 1 ? 1 : features.length,
            slidesToScroll: 1
          }
        }
      ]
    }
  }, [features.length])

  return (
    <div className='md:-mx-4 mx-[0] pt-4'>
      {isLoading ? (
        <Loader />
      ) : (
        <Slider {...settings}>
          {features.map((feature, index) => (
            <div key={index} className='md:p-[1rem] p-[0]'>
              <div className='bg-white border border-gray-300 rounded'>
                <Link to={`/marketplace-products/${feature.id}`}>
                  <div>
                    <img
                      src={feature.assets_collections_urls?.featured_collection?.[0] || feature.assets_urls?.[1]}
                      alt={feature.name}
                      className='feature__collection-item__bg w-full object-cover max-h-[450px]'
                    />
                  </div>
                </Link>
                <div className='text-center p-8'>
                  <p className='text-xs text-gray-400'>{feature.author_name}</p>
                  <Link to={`/marketplace-products/${feature.id}`}>
                    <div className='text-2xl font-medium leading-[2rem] h-[4rem]'>{feature.name}</div>
                  </Link>
                  <div className='truncate-3' dangerouslySetInnerHTML={{ __html: feature.description || '' }} />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      )}
    </div>
  )
}

export default FeaturedCollection
