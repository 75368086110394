import React from 'react'

export interface ToastErrorProps {
  header?: string
  message: string
}

const ToastError: React.FC<ToastErrorProps> = ({ header, message }) => {
  return (
    <div className='font-normal'>
      <p className='text-red-600 font-medium'>{header || 'Order Failed!'}</p>
      <p className='mt-1 text-red-600'>{message}</p>
    </div>
  )
}

export default ToastError
