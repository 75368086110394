import { t } from '@lingui/macro'

import { Classification } from 'types/product'

export const classificationNameMapping = {
  [Classification.Common]: t`Common`,
  [Classification.Rare]: t`Rare`,
  [Classification.Epic]: t`Epic`,
  [Classification.Unique]: t`Unique`,
  [Classification.Special]: t`Special`,
  [Classification.MemberShip1]: t`Membership Level 1`
}

export const classificationColorMapping = {
  [Classification.Common]: '#454F5B',
  [Classification.Rare]: '#0B6BFB',
  [Classification.Epic]: '#5F2AB5',
  [Classification.Unique]: '#F28602',
  [Classification.Special]: '#108C4A',
  [Classification.MemberShip1]: '#637381'
}
