import React, { useMemo } from 'react'
import { useWeb3React } from '@web3-react/core'
import { NavLink } from 'react-router-dom'

import { ercChainIds } from 'utils/metamask'
import { tropheeETHUrl } from 'config/constantKey'

export interface MenuLinkProps {
  path: string
  name: string
  className: string
  activeClassName: string
}

const MenuLink: React.FC<MenuLinkProps> = ({ path, name, className, activeClassName }) => {
  const { chainId } = useWeb3React()

  const isERCChain = useMemo(() => chainId && ercChainIds.includes(chainId), [chainId])

  return !isERCChain ? (
    <NavLink className={className} activeClassName={activeClassName} to={path}>
      {name}
    </NavLink>
  ) : (
    <a className={className} href={`${tropheeETHUrl}${path}`}>
      {name}
    </a>
  )
}

export default MenuLink
