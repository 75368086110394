import { useCallback } from 'react'
import { NoBscProviderError } from '@binance-chain/bsc-connector'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected
} from '@web3-react/injected-connector'
import {
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
  WalletConnectConnector
} from '@web3-react/walletconnect-connector'
import Cookies from 'js-cookie'

import { useDerivedSignature, useMyProfile } from 'store/user/hook'
import { useTermAndPolicyPopup } from 'store/popup/hook'

import { ConnectorNames, connectorsByName } from 'config/connectors'
import { storageKey } from 'config/constantKey'
import { addChainBSC } from 'utils/metamask'
import { toastError } from 'utils/toast'

export const useAuth = () => {
  const { activate, deactivate } = useWeb3React()
  const [, setSignature] = useDerivedSignature()
  const [, setMyProfile] = useMyProfile()
  const [termPopup] = useTermAndPolicyPopup()

  const login = useCallback(
    (connectorID: ConnectorNames) => {
      const connector = connectorsByName[connectorID]
      let isError = false
      activate(connector, async (error: Error) => {
        isError = true
        if (error instanceof UnsupportedChainIdError) {
          await addChainBSC(+(process.env.REACT_APP_CHAIN_ID as string))
          await activate(connector, async (err2: Error) => {
            if (err2 instanceof UnsupportedChainIdError) {
              return toastError('Unsupported Chain Id Error. Check your chain Id.')
            }
            if (err2 instanceof NoEthereumProviderError || err2 instanceof NoBscProviderError) {
              return toastError('No provider was found')
            }
            if (
              err2 instanceof UserRejectedRequestErrorInjected ||
              err2 instanceof UserRejectedRequestErrorWalletConnect
            ) {
              if (connector instanceof WalletConnectConnector) {
                const walletConnector = connector as WalletConnectConnector
                walletConnector.walletConnectProvider = null
              }
              return toastError('Authorization Error')
            }

            return toastError(err2.message)
          })
        } else {
          Cookies.remove(storageKey.connector, {
            // domain: process.env.NODE_ENV === 'development' ? 'localhost' : '.trophee.xyz'
          })
          if (error instanceof NoEthereumProviderError || error instanceof NoBscProviderError) {
            return toastError('No provider was found')
          }
          if (
            error instanceof UserRejectedRequestErrorInjected ||
            error instanceof UserRejectedRequestErrorWalletConnect
          ) {
            if (connector instanceof WalletConnectConnector) {
              const walletConnector = connector as WalletConnectConnector
              walletConnector.walletConnectProvider = null
            }
            return toastError('Authorization Error')
          }

          return toastError(error.message)
        }
      }).then(() => {
        if (!isError) {
          if (!Cookies.get(storageKey.connector)) {
            termPopup?.show()
          }
        }
        Cookies.set(storageKey.connector, connectorID, {
          // domain: process.env.NODE_ENV === 'development' ? 'localhost' : '.trophee.xyz'
        })
      })
    },
    [activate, termPopup]
  )

  const logout = useCallback(() => {
    deactivate()
    setSignature(undefined)
    setMyProfile(undefined)
    Cookies.remove(storageKey.connector, {
      // domain: process.env.NODE_ENV === 'development' ? 'localhost' : '.trophee.xyz'
    })
    localStorage.removeItem(storageKey.sigMessage)
    window.location.reload()
  }, [deactivate, setSignature, setMyProfile])

  return { login, logout }
}
