import React from 'react'
import { Trans } from '@lingui/macro'

import { useModal } from 'hooks'
import { useKYCRequestPopup } from 'store/popup/hook'
import { Button } from 'components'
import { KYC_LINK } from 'config/constantKey'

export interface KYCRequestProps {}

const KYCRequestModal: React.FC<KYCRequestProps> = () => {
  const [popup, setPopup] = useKYCRequestPopup()

  useModal({
    container: '.popup-kyc-request',
    trigger: '.popup-close-kyc-request', // hacky way to force the script can find the popup,
    setAtom: setPopup
  })

  return (
    <div className='popup-box popup-kyc-request w-full md:w-96 h-80 p-4 md:p-10'>
      <div className='popup-close-kyc-request' onClick={() => popup?.hide()}>
        <svg className='popup-close-button-icon icon-cross'>
          <use xlinkHref='#svg-cross'></use>
        </svg>
      </div>

      <div className='popup-box-body flex-col items-center justify-center text-center'>
        <h4 className='font-medium uppercase'>
          <Trans>KYC Required</Trans>
        </h4>
        <p className='mt-4 font-lg'>
          <Trans>This transaction requires KYC for regulatory purposes.</Trans>
        </p>
        <p className='mt-4 font-lg'>
          <Trans>Kindly KYC via Blockpass through the link below (this process may take up to 24 hours).</Trans>
        </p>
        <a className='mt-4' href={KYC_LINK} target='_blank' rel='noreferrer'>
          <Button>Blockpass KYC</Button>
        </a>
      </div>
    </div>
  )
}

export default KYCRequestModal
