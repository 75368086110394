import { useState, useEffect, useRef, useCallback } from 'react'

export const useTimer = (endDate?: number) => {
  const ref = useRef<number>()
  const [timer, setTimer] = useState<{
    total: number
    days: number
    hours: number
    minutes: number
  }>({
    total: 0,
    days: 0,
    hours: 0,
    minutes: 0
  })

  const getTimeRemaining = (endTime: number) => {
    const total = endTime - Date.now()
    const minutes = Math.floor((total / 1000 / 60) % 60)
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24)
    const days = Math.floor(total / (1000 * 60 * 60 * 24))

    return {
      total,
      days,
      hours,
      minutes
    }
  }

  const startTimer = useCallback((time: number) => {
    let { total, days, hours, minutes } = getTimeRemaining(time)
    if (total >= 0) {
      setTimer({
        total,
        days,
        hours,
        minutes
      })
    }
  }, [])

  const clearTimer = useCallback(
    (e: number) => {
      if (ref.current) clearInterval(ref.current)
      startTimer(e)
      const id = window.setInterval(() => {
        startTimer(e)
      }, 60000) // 1 minutes
      ref.current = id
    },
    [startTimer]
  )

  useEffect(() => {
    endDate && clearTimer(endDate)

    return () => {
      if (ref.current) clearInterval(ref.current)
    }
  }, [endDate, clearTimer])

  return timer
}
