import { useQuery } from 'react-query'
import qs from 'query-string'

import { Product } from 'types/product'
import { filterNonNull } from './product'

export const useMangaDrop = (query: any) => {
  const url = query ? `/v2/products?${qs.stringify(filterNonNull(query))}` : '/v2/products'
  return useQuery<{
    data: Product[]
    cursor: number
  }>([url, query])
}
