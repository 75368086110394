import React, { HTMLProps } from 'react'

export interface RadioButtonProps extends HTMLProps<HTMLInputElement> {}

const RadioButton: React.FC<RadioButtonProps> = ({ id, label, ...props }) => {
  return (
    <label className="flex items-center my-3">
      <input type="radio" className="option-input radio" {...props} />
      <span className="mt-[1px]">{label}</span>
    </label>
  )
}

export default RadioButton
