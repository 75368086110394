import { useEffect, useMemo } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useAtom } from 'jotai'

import { signatureAtom, derivedSignatureAtom, myProfileAtom, walletChanged } from './index'
import { useUserProfile } from 'queries/user'
import { useLowercaseAccount } from 'hooks'
import { storageKey } from 'config/constantKey'

export const useSignature = (message: string, strict = true) => {
  const [signature] = useAtom(signatureAtom)
  const [alreadyChanged] = useAtom(walletChanged)

  const messageSignature = useMemo(() => {
    if (alreadyChanged) {
      return undefined
    }

    // get stored sign if not strict
    if (!strict && localStorage.getItem(storageKey.sigMessage)) {
      return localStorage.getItem(storageKey.sigMessage) || undefined
    }

    if (signature) {
      return signature[message]
    }
  }, [strict, signature, message, alreadyChanged])

  return [messageSignature, strict ? message : storageKey.sigMessage]
}

export const useDerivedSignature = () => {
  return useAtom(derivedSignatureAtom)
}

export const useFetchMyProfile = () => {
  const account = useLowercaseAccount()
  const { data } = useUserProfile(account)
  const [, setMyProfile] = useAtom(myProfileAtom)

  useEffect(() => {
    if (data) {
      setMyProfile(data)
    }
  }, [data, setMyProfile])
}

export const useMyProfile = () => {
  return useAtom(myProfileAtom)
}

export const useRefreshSignature = () => {
  const { library } = useWeb3React()
  const [, setSignature] = useAtom(signatureAtom)
  const [, setChanged] = useAtom(walletChanged)

  useEffect(() => {
    if (library) {
      library.provider.on('accountsChanged', async (accounts: unknown) => {
        if (accounts) {
          localStorage.removeItem(storageKey.sigMessage)
          setChanged(true)
          setSignature(undefined)
        }
      })
    }
  }, [library, setSignature, setChanged])
}
