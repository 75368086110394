import React, { useMemo, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { Trans } from '@lingui/macro'
import { OpenSeaPort, Network } from 'opensea-js'

import { KYC_CODE, notEnoughBalanceMessage } from 'config/constantKey'
import { checkKYCError, isTestNet } from 'utils/core'
import { toastError, toastSuccess } from 'utils/toast'
import { useCommonPopup, useKYCPopup } from 'store/popup/hook'
import { BridgeOrder } from 'types/order'

import { Button, ClassificationInfo, CoverImage } from 'components'
import { ToastError, ToastSuccess } from 'components/ToastMessage'
import { InsufficientModal } from 'components/Modal'
import { TransactionStatus } from 'components/Bridge/List'
import { getLocalizedDetail } from 'utils/localization'
import { useLocale } from 'hooks'
import { Classification } from 'types/product'

type ItemProps = {
  detail: BridgeOrder
  onReceived?: () => Promise<void>
  onLoading: (loading: boolean) => void
  onSettled: (status: TransactionStatus) => void
}

const Item = ({ detail, onReceived, onLoading, onSettled }: ItemProps) => {
  const locale = useLocale()
  const { account, library } = useWeb3React()

  const product = useMemo(() => detail?.product, [detail])

  const [receiving, setReceiving] = useState(false)

  const KYCPopup = useKYCPopup()
  const { popup: commonPopup, setContent } = useCommonPopup()

  const handleReceive = async () => {
    if (account) {
      setReceiving(true)
      onLoading(true)
      try {
        const seaport = new OpenSeaPort(library.provider, {
          networkName: isTestNet ? Network.Ropsten : Network.Ethereum
        })

        const tx = await seaport.fulfillOrder({ order: detail.order as any, accountAddress: account })
        if (tx) {
          if (onReceived) {
            await onReceived()

            setTimeout(() => onReceived(), 3000)
          }

          onSettled({
            status: 'success',
            message: tx
          })
          toastSuccess(<ToastSuccess message={tx} tx={tx} />)
        } else {
          toastError(<ToastError message='Cannot create transaction' />)
        }
      } catch (error: any) {
        const code = checkKYCError(error)
        if (code === KYC_CODE.Requested) {
          KYCPopup.Request?.show()
          return
        }
        if (code === KYC_CODE.Pending) {
          KYCPopup.Pending?.show()
          return
        }
        if (code === KYC_CODE.Rejected) {
          KYCPopup.Reject?.show()
          return
        }

        const err = error?.message ? error : new Error(error)
        if (err.message?.includes(notEnoughBalanceMessage)) {
          setContent(<InsufficientModal paymentToken={detail.order.paymentToken} />)
          commonPopup?.show()
        } else {
          onSettled({
            status: 'failure',
            message: error?.message ? error.message : error
          })
          toastError(<ToastError message={err?.message || 'Cannot create transaction'} />)
        }
      } finally {
        onLoading(false)
        setReceiving(false)
      }
    }
  }

  return (
    <div className='rounded bg-white border border-sky-light relative popup-collection-trigger'>
      <div className='p-3'>
        {product && (
          <CoverImage
            className='h-[302px]'
            imageUrl={product.assets_collections_urls?.marketplace_preview?.[0] || product.assets_urls?.[1]}
          />
        )}
        {product ? (
          <div className='p-3'>
            <p className='text-xs text-ink-lighter'>{getLocalizedDetail({ detail: product, locale })('authorName')}</p>
            <p className='font-medium py-[0.375rem] truncate'>
              {getLocalizedDetail({ detail: product, locale })('name')}
            </p>

            <div className='flex justify-between items-center'>
              <span className='text-xs'>
                <ClassificationInfo classification={product.rarity as Classification} />
              </span>
            </div>
          </div>
        ) : (
          <div>{detail.dest.token_address}</div>
        )}
        <Button className='mt-4 w-full' onClick={handleReceive} isLoading={receiving}>
          <Trans>Claim</Trans>
        </Button>
      </div>
    </div>
  )
}

export default Item
