import React, { ChangeEvent } from 'react'

import { Checkbox } from 'components'

export interface FilterClassProps {
  rarity?: string[]
  setRarity: (rarity: string[]) => void
}

const itemClasses = [
  {
    id: 'yellow',
    label: 'Unique',
    value: 'unique'
  },
  {
    id: 'purple',
    label: 'Epic',
    value: 'epic'
  },
  {
    id: 'blue',
    label: 'Rare',
    value: 'rare'
  },
  {
    id: 'common',
    label: 'Common',
    value: 'common'
  }
]

const FilterClass: React.FC<FilterClassProps> = ({ rarity, setRarity }) => {
  const handleChange = (check: boolean, value: string) => {
    if (check) {
      return setRarity([...(rarity || []), value])
    }
    return setRarity(rarity?.filter(item => item !== value) || [])
  }
  return (
    <div className='py-4'>
      <p className='mb-[1.125rem] font-medium'>Classification</p>
      <ul>
        {itemClasses.map(classification => {
          return (
            <li key={classification.id}>
              <Checkbox
                id={classification.id}
                label={classification.label}
                labelClass={`text-semantic-${classification.id}`}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e.target.checked, classification.value)}
                checked={rarity?.includes(classification.value)}
              />
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default FilterClass
