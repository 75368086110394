import React from 'react'
import { Trans } from '@lingui/macro'

import { useModal } from 'hooks'
import { useKYCPendingPopup } from 'store/popup/hook'

export interface KYCPendingProps {}

const KYCPendingModal: React.FC<KYCPendingProps> = () => {
  const [popup, setPopup] = useKYCPendingPopup()

  useModal({
    container: '.popup-kyc-pending',
    trigger: '.popup-close-kyc-pending', // hacky way to force the script can find the popup,
    setAtom: setPopup
  })

  return (
    <div className='popup-box popup-kyc-pending w-full md:w-96 h-80 p-4 md:p-10'>
      <div className='popup-close-kyc-pending' onClick={() => popup?.hide()}>
        <svg className='popup-close-button-icon icon-cross'>
          <use xlinkHref='#svg-cross'></use>
        </svg>
      </div>

      <div className='popup-box-body flex-col items-center justify-center text-center'>
        <h4 className='font-medium uppercase'>
          <Trans>KYC Pending</Trans>
        </h4>
        <p className='mt-4 font-lg'>
          <Trans>This transaction requires KYC for regulatory purposes.</Trans>
        </p>
        <p className='mt-4 font-lg'>
          <Trans>
            This process may take up to 24 hours from the time the form was submitted, we thank you for your patience.
          </Trans>
        </p>
      </div>
    </div>
  )
}

export default KYCPendingModal
