import React from 'react'
import classnames from 'classnames'
import { Trans } from '@lingui/macro'

type TabProps = {
  classNames?: string
  title: string
  active: boolean
  onChange: () => void
}

const Tab = ({ classNames, title, active, onChange }: TabProps) => {
  return (
    <div className={classnames(classNames, 'mr-8')} onClick={onChange}>
      <span
        className={classnames('font-medium hover:text-ink-basic cursor-pointer relative py-2 tab__item', {
          'text-ink-lighter': !active,
          'tab__item-active': active
        })}
      >
        <Trans id={title} />
      </span>
    </div>
  )
}

export default Tab
