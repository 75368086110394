import { NFTOrder } from './order'

export interface ProductMetadata {
  id: string
  price: number
  rating: number
  tokenAddress: string
  title: string
  authorAvatarUrl: string
  quantity: number
  balance?: number
  description: string
  category: string
  tokenId: string
  // filenames
  originalAssets?: string[]
  thumbAssets: string[]
  watermarkAssets: string[]
  // file urls
  originalUrls?: string[]
  thumbUrls: string[]
  watermarkUrls: string[]
  authorName: string
  paymentTokenAddress: string
  classification: Classification
  createdAt: number
  updatedAt: number
  created_at_iso: string
  updated_at_iso: string
}

export interface Product {
  assets: string[]
  assets_urls: string[]
  asset_nft: string
  asset_nft_url?: string
  assets_collections: {
    featured_collection: string[]
    marketplace_preview: string[]
    product_carousel: string[]
    product_full_screen: string[]
    video: string[]
  }
  assets_collections_urls: {
    featured_collection: string[]
    marketplace_preview: string[]
    product_carousel: string[]
    product_full_screen: string[]
    video: string[]
  }

  author_name: string
  author_name_jp: string
  created_at: number
  deleted_at: number
  description: string
  description_jp: string
  order_count?: number
  order_open_count?: number
  main_order_id?: string
  id: string
  name: string
  name_jp: string
  rarity: Classification
  slug: string
  updated_at: number
  order_fixed_price: NFTOrder
  order_p2p: NFTOrder
  order_auction: NFTOrder

  mangadrop?: number
  open_at?: number
  expired_at?: number
  price?: number
  tags?: Tag
  _search?: string[]
}
export interface PriceDetail {
  price: number
  decimal?: number
  symbol?: string
}

export interface ProductOrder {
  exchange: string
  maker: string
  taker: string
  quantity: string
  makerRelayerFee: string
  takerRelayerFee: string
  makerProtocolFee: string
  takerProtocolFee: string
  makerReferrerFee: string
  waitingForBestCounterOrder: boolean
  feeMethod: number
  feeRecipient: string
  side: number
  saleKind: number
  target: string
  howToCall: number
  calldata: string
  replacementPattern: string
  staticTarget: string
  staticExtradata: string
  paymentToken: string
  basePrice: string
  extra: string
  listingTime: string
  expirationTime: string
  salt: string
  metadata: {
    asset: { id: string; address: string; quantity: string }
    schema: string
  }
  hash: string
  message?: string
  messageHash?: string
  v: number | string
  r: string
  s: string
  signature?: string
}

export enum ProductType {
  Fixed = 1,
  Auction = 2
}

export interface ProductFilterQuery {
  category?: string
  search?: string
  classification?: string
  type?: ProductType
  rarity?: string[]
  price_from?: number
  price_to?: number
  _search?: string
  _limit?: number
  _cursor?: number
}

export enum Classification {
  Common = 'common',
  Rare = 'rare',
  Epic = 'epic',
  Unique = 'unique',
  Special = 'special',
  MemberShip1 = 'level_1'
}

export type AuctionRequest = Pick<ProductMetadata, 'tokenId' | 'tokenAddress' | 'authorName' | 'authorAvatarUrl'> & {
  minPrice: number
  maxPrice: number
  order: ProductOrder
}

export interface InfiniteQuery {
  limit?: number
  cursor?: number
}

export interface Pagination {
  _limit?: number
  _cursor?: number
}

export type TagKey = 'active' | 'upcoming'

export type Tag = {
  [key in TagKey]?: number
}
