import format from 'date-fns/format'

export const formatNumber = (number: number) => {
  var formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  })

  return formatter.format(number)
}

export const formatDate = (date: Date | number) => {
  return format(date, 'MMM dd, yyyy')
}

export const formatDateTime = (date: Date | number) => {
  return format(date, 'MMMM dd, yyyy (KK:mm aaa)')
}

const kycMapping: { [key: string]: string } = {
  KYC_ACCEPTED: 'Accepted',
  KYC_PENDING: 'Pending',
  KYC_REJECTED: 'Rejected',
  KYC_REQUIRED: 'Not Started'
}

export const formatKYCStatus = (code: string) => {
  return kycMapping[code] || ''
}
