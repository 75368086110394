export enum ConnectorNames {
  Injected = 'injected',
  WalletConnect = 'walletconnect',
  BSC = 'bsc'
}

export interface WalletConfig {
  title: string
  icon: React.FC
  connectorId: ConnectorNames
}
