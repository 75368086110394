import { useState, useEffect, useRef } from 'react'
import { useInView } from 'react-intersection-observer'

import { useOrderTradingHistory, useProductTradingHistory } from 'queries/history'

import { TradingHistory } from 'types/tradingHistory'
import { SHOW_LIMIT } from 'config/constantKey'

export const useInfiniteOrderTradingHistory = (orderId: string) => {
  const [cursor, setCursor] = useState<number>(0)
  const [isEnd, setEnd] = useState(false)
  const [hideRefElement, setHideRefElement] = useState(false)
  const [showHistories, setShowHistories] = useState<TradingHistory[]>([])
  const {
    data: histories,
    isLoading,
    invalidate
  } = useOrderTradingHistory({
    orderId,
    _limit: SHOW_LIMIT,
    _cursor: cursor
  })
  const nextCursor = useRef<number>()

  const { ref, inView } = useInView({
    threshold: 0.5
  })

  useEffect(() => {
    setTimeout(() => {
      setHideRefElement(isLoading) // when loading, we will hide the ref element with some delay to make smoothy
    }, 600)
  }, [isLoading])

  useEffect(() => {
    if (histories?.data) {
      const list = histories.data
      if (list.length > 0) {
        setShowHistories(old => {
          return [...old, ...list]
        })
      }

      if (histories.cursor) {
        nextCursor.current = histories.cursor
      } else {
        setEnd(true)
      }
    }
  }, [histories])

  // use ref to prevent infinite render if maybe
  useEffect(() => {
    if (inView) {
      setCursor(nextCursor.current || 0)
    }
  }, [inView])

  return { showHistories, isLoading, isEnd, ref, hideRefElement, invalidate }
}

export const useInfiniteProductTradingHistory = (productId: string) => {
  const [cursor, setCursor] = useState<number>(0)
  const [isEnd, setEnd] = useState(false)
  const [hideRefElement, setHideRefElement] = useState(false)
  const [showHistories, setShowHistories] = useState<TradingHistory[]>([])
  const {
    data: histories,
    isLoading,
    invalidate
  } = useProductTradingHistory({
    productId,
    _limit: SHOW_LIMIT,
    _cursor: cursor
  })
  const nextCursor = useRef<number>()

  const { ref, inView } = useInView({
    threshold: 0.5
  })

  useEffect(() => {
    setTimeout(() => {
      setHideRefElement(isLoading) // when loading, we will hide the ref element with some delay to make smoothy
    }, 600)
  }, [isLoading])

  useEffect(() => {
    if (histories?.data) {
      const list = histories.data
      if (list.length > 0) {
        setShowHistories(old => {
          return [...old, ...list]
        })
      }

      if (histories.cursor) {
        nextCursor.current = histories.cursor
      } else {
        setEnd(true)
      }
    }
  }, [histories])

  // use ref to prevent infinite render if maybe
  useEffect(() => {
    if (inView) {
      setCursor(nextCursor.current || 0)
    }
  }, [inView])

  return { showHistories, isLoading, isEnd, ref, hideRefElement, invalidate }
}
