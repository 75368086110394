import React, { useState } from 'react'
import { t, Trans } from '@lingui/macro'
import { useForm, Controller } from 'react-hook-form'
import { signInWithEmailAndPassword } from 'firebase/auth'

import { auth } from 'libs/firebase'
import { ACCESS_TOKEN_KEY } from 'config/constantKey'

import { Input, Button } from 'components'
import { toastSuccess } from 'utils/toast'

type FormData = {
  email: string
  password: string
}

type LoginWithEmailProps = {
  onSuccess: () => void
}

const LoginWithEmail = ({ onSuccess }: LoginWithEmailProps) => {
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const { control, formState, handleSubmit } = useForm<FormData>({
    mode: 'onChange'
  })
  const { errors } = formState

  const handleLogin = (values: FormData) => {
    setIsLoading(true)
    signInWithEmailAndPassword(auth, values.email, values.password)
      .then(async userCredential => {
        const token = await userCredential.user.getIdToken()
        if (token) {
          localStorage.setItem(ACCESS_TOKEN_KEY, token)
          onSuccess()
          toastSuccess(
            <div>
              Success!
              <p className='mt-1'>Login Successfully !</p>
            </div>
          )
        }
      })
      .catch(error => {
        const errorMessage = error.message
        setError(errorMessage)
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <div className='mb-3'>
      <form onSubmit={handleSubmit(handleLogin)}>
        <div className='mb-3'>
          <div className='mb-2'>
            <Controller
              render={({ field }) => <Input placeholder={t`Email Address`} {...field} errors={errors} />}
              control={control}
              name='email'
              rules={{
                required: t`Email is required`,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: t`Invalid email address`
                }
              }}
            />
          </div>
          <div className='mb-2'>
            <Controller
              render={({ field }) => (
                <Input placeholder={t`Password`} type='password' visibilityToggle {...field} errors={errors} />
              )}
              control={control}
              name='password'
              rules={{
                required: t`Password is required`
              }}
            />
          </div>
          {error && <div className='text-red-500 my-2'>{error}</div>}
        </div>
        <Button className='w-full' type='submit' isLoading={isLoading} disabled={isLoading}>
          <Trans>Log In</Trans>
        </Button>
      </form>
    </div>
  )
}

export default LoginWithEmail
