import React, { useState, useMemo, ChangeEvent, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import { t, Trans } from '@lingui/macro'
import debounce from 'lodash.debounce'

import { useInfiniteCollections } from 'hooks'
import { NFTOrder } from 'types/order'
import { useSellItemPopup } from 'store/popup/hook'
import { useCollectionImages, useSellItem } from 'store/myCollection/hook'

import { Collection, Input, Loader, Switch, ListWrapper } from 'components'

// type Option = {
//   value: string
//   label: string
// }

export interface CollectiblesProps {
  account?: string | null
}

const Collectibles: React.FC<CollectiblesProps> = ({ account }) => {
  // const options: Option[] = [
  //   {
  //     label: t`Rarity`,
  //     value: 'rarity'
  //   },
  //   {
  //     label: t`Time Acquired`,
  //     value: 'time-acquired'
  //   },
  //   {
  //     label: t`Mangaka`,
  //     value: 'mangaka'
  //   }
  // ]
  // const [filter, setFilter] = useState<Option | null>(options[0])
  const [search, setSearch] = useState<string>()
  const [show, setShow] = useState<boolean>(false)
  const [sellPopup] = useSellItemPopup()
  const [, setSellItem] = useSellItem()
  const [, setCollectionImages] = useCollectionImages()
  const { account: currentAccount } = useWeb3React()
  const isMyCollection = useMemo(() => account === currentAccount?.toLowerCase(), [account, currentAccount])

  const { showOrders, isLoading, isEnd, ref, hideRefElement } = useInfiniteCollections(account, {
    owner_id: account?.toLowerCase(),
    status: 1,
    ...(!!search ? { _search: search } : {})
  })

  const handleSearch = useMemo(
    () =>
      debounce((val: ChangeEvent<HTMLInputElement>) => {
        setSearch(val.target.value?.toLowerCase())
      }, 300),
    []
  )

  const handleSellItem = (item: NFTOrder) => () => {
    if (!isMyCollection) {
      return
    }

    sellPopup?.show()
    setSellItem(item)
  }

  useEffect(() => {
    setCollectionImages()
  }, [account, setCollectionImages])

  useEffect(() => {
    showOrders.map(order =>
      setCollectionImages(currentState => ({ ...currentState, [order.id]: order.product.assets_urls[1] }))
    )
  }, [showOrders, setCollectionImages])

  return (
    <>
      <div className='flex flex-wrap items-center'>
        <div className='flex-1 md:mr-[1rem] md:mb-0 mb-[1rem]'>
          <Input
            leftElement={
              <svg className='fill-current text-ink-lighter w-5 h-5'>
                <use xlinkHref='#svg-search'></use>
              </svg>
            }
            placeholder={t`Search title, mangaka or set...`}
            type='search'
            onChange={handleSearch}
          />
        </div>
        {/* <div className='md:w-[170px] w-full md:mr-[1rem] md:mb-0 mb-[1rem]'>
          <Select options={options} defaultValue={options[0]} onChange={setFilter} />
        </div> */}
        <div className='flex-1 flex items-center md:mt-[1rem] lg:mt-0'>
          <span className='mr-2'>
            <Trans>Show Owner’s Inscription:</Trans>
          </span>
          <Switch onChange={e => setShow(e.target.checked)} />
        </div>
      </div>
      <ListWrapper
        isLoading={isLoading && !showOrders.length}
        isNoData={!search && showOrders.length === 0}
        isNoResult={!!search && showOrders.length === 0}
      >
        {showOrders.map(order => (
          <Collection
            key={order.id}
            order={order}
            onSell={isMyCollection ? handleSellItem(order) : undefined}
            show={show}
          />
        ))}
      </ListWrapper>
      {!hideRefElement && !isEnd && showOrders.length > 0 && (
        <div ref={ref}>
          <Loader />
        </div>
      )}
    </>
  )
}

export default Collectibles
